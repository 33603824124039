<template>
  <section>
    <div class="row mt-2">
      <div class="col-12">
        <div class="ward-process-header">
          <h6 class="mt-2" style="font-size:16px"><b> ระยะเวลาประกันภัย {{ numberFormatToText(calulates.AmtDate) }} วัน</b></h6>
        </div>
        <div class="ward-process-size">
          <div class="ward-process-insize">
            <div class="test">
            </div>
            <table style="width: 90%; padding-top: 20px" class="mx-auto">
              <tr style="padding-top: 20px">
                <td><b>เบี้ยประกัน</b></td>
                <td style="text-align: right"><b>{{ numberFormatToText(calulates.APremium) }}</b></td>
              </tr>
              <tr>
                <td><b>+ อากรแสตมป์</b></td>
                <td style="text-align: right"><b>{{ numberFormatToText(calulates.AStamp) }}</b></td>
              </tr>
              <tr>
                <td><b>+ ภาษีมูลค่าเพิ่ม</b></td>
                <td style="text-align: right"><b>{{ numberFormatToText(calulates.AVat) }}</b></td>
              </tr>
              <tr>
                <td style="color: #ff3f42">
                  <u><b>รวมทั้งสิ้น</b> </u>
                </td>
                <td style="text-align: right; color: #ff3f42">
                  <b>{{ numberFormatToText(calulates.ATotal) }}</b>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import EventService from '../../services/EventService';

export default {
  name: "wardconfirm",
  props: ['startDate', 'endDate', 'engineSize', 'carType'],
  emits: [' update:premium'],
  data() {
    return {
      calulates: {
        APremium: "0.00",
        AStamp: "0",
        ATotal: "0",
        AVat: "0.00",
        AmtDate: "0"
      },
    }
  },
  watch:{
    startDate(val, oldVal) {
      if (val != oldVal && oldVal != undefined) {
        if (this.checkDate(val)) {
          this.calculate()
        }
      }
    },
    endDate(val, oldVal) {
      if (val != oldVal && oldVal != undefined) {
        this.calculate()
      }
    },
    engineSize(val, oldVal) {
      if (val != oldVal && oldVal != undefined) {
        this.calculate()
      }
    },
    carType(val, oldVal) {
      if (val != oldVal && oldVal != undefined) {
        this.calculate()
      }
    },
  },
  created() {
    this.getAgent()
    this.calculate()
  },
  computed: {
    ...mapState(['agent']),
    ...mapGetters(['getCalulateDate', 'checkDate', 'numberFormatToText']),
  },
  methods: {
    ...mapActions(['getAgent']),
    calculate() {
      if (this.startDate && this.endDate && this.engineSize && this.carType) {
        EventService.post('/insurance/calculate_premium', {
          "agent_id": this.agent.agent_id,
          "start_date": this.getCalulateDate(this.startDate),
          // "start_date": "2021-11-20",
          "end_date": this.getCalulateDate(this.endDate),
          "cc": this.engineSize+'',
          "car_type": this.carType
        }).then(res => {
          if (res.data.APremium) {
            this.calulates = res.data
            this.$emit('premium', res.data)
          }else {
            this.calulates = {APremium: "0.00", AStamp: "0", ATotal: "0", AVat: "0.00", AmtDate: "0"}
            this.$emit('premium', this.calulates)
          }
        }).catch(error =>{
          console.log(error);
        })
      }else {
        this.resetCalulates()
      }
    },
    resetCalulates() {
      this.calulates = {
        APremium: "0.00",
        AStamp: "0",
        ATotal: "0",
        AVat: "0.00",
        AmtDate: "0"
      }
    },
  }
};
</script>

<style lanf="scss" scoped >
.ward-process-header {
  height: 50px;
  width: 100%;
  background-color: #d6eee1;
  text-align: center;
    padding-top: 10px;
  color: #008e76;
}

.ward-process-size {
  background-color: #d6eee1;
  height: 140px;
}
.ward-process-insize {
  background-color: #ffffff;
  height: 120px;
  margin: 0px 20px;
}
td{
  font-size: 14px;
}
.test{
  height: 17px;
  width: 100px;
}
</style>
