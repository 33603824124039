<template>
  <div class="container">
    <section class="set-padding-menu">
      <div class="row">
        <div class="col-12 text-center">
          <h1 for="" class="title-text"><u>รายงานยอดขายรายตัวแทน</u></h1>
        </div>
      </div>
      <div class="row mt-4">
        <div class="col-4">
          <h3 class="title-select">แสดงรายงาน</h3>
        </div>
        <div class="col-8">
          <select name="" id="" class="form-select" v-model="selectReport">
            <option value="report_sale_day" class="form-control">รายวัน</option>
            <option value="report_sale_preiod" class="form-control">รายงานการขายงวดปัจจุบันสะสม</option>
          </select>
        </div>
      </div>
      <div class="card mt-3">
        <div class="card-header" style="background-color: #d6eee1">
          <div class="row mt-2">
            <div class="col-12">
              <h1 class="text-headcard">{{ reportTitle }}</h1>
            </div>
          </div>
        </div>
        <div class="card-body">
          <table>
            <colgroup>
              <col style="width: 40%" />
              <col style="width: 20%" />
              <col style="width: 20%" />
              <col style="width: 20%" />
            </colgroup>
            <tr class="head-table">
              <th class="th-set">รหัสตัวแทนหลัก</th>
              <th class="th-set">ช่องทาง</th>
              <th class="th-set">จำนวน<br />กรมธรรม์</th>
              <th class="th-set">จำนวน<br />เบี้ยนำส่ง</th>
            </tr>
            <reportsales v-for="(value, i) in report" :key="value" :index="i" :count="report.length"
              :reportData="value" />
            <tr style="background-color: #d6eee1" v-if="!report.length">
              <td class="th1">-</td>
              <td class="th">Line</td>
              <td class="th">-</td>
              <td class="th">-</td>
            </tr>
            <tr v-if="!report.length">
              <td class="td"></td>
              <td class="td">ePolicy</td>
              <td class="td">-</td>
              <td class="td">-</td>
            </tr>

            <tr class="set-footer-table">
              <td class="set-col-1-ft">รวมยอดการขาย</td>
              <td class="set-text-ft">
                <span class="set-textcolor1 p-0 m-0">Line</span>
                <br />
                <span class="set-textcolor2 p-0 m-0">ePolicy</span>
              </td>
              <td class="set-text-ft">
                <span class="set-textcolor1">{{ linePolicyAmt }}</span><br />
                <span class="set-textcolor2">{{ epolicyAmt }}</span>
              </td>
              <td class="set-text-ft">
                <span style="float: right" class="set-textcolor1">{{ linePolicySendMoney }}</span><br />
                <span style="float: right" class="set-textcolor2">{{ epolicySendMoney }}</span>
              </td>
            </tr>
          </table>
          <div class="row mt-3">
            <div class="col-5">
              <div class="settext-total1">เบี้ยนำส่งรวมสุทธิ</div>
            </div>
            <div class="col-7">
              <div class="settext-total2">{{ total }} บาท</div>
            </div>
          </div>
          <!-- <router-link
            class="link"
            :to="{ name: 'detailpayment' }"
            style="text-decoration: none"
          > -->
          <router-link class="link" :to="{ name: 'payment', params: {totalcash: total}}" style="text-decoration: none">
            <div class="row mt-4 mb-5 justify-content-center">
              <div class="col-8">
                <div class="d-grid gap-2">
                  <button class="btn btn-success color-btn">
                    ชำระเงิน<i style="float: right; font-size: 25px" class="fas fa-angle-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>
      <br />
    </section>

    <loading :show="loading" />

  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import reportsales from "../../components/SalesManagement/reportsales.vue";
import EventService from "../../services/EventService";
import { authLine } from "../js/setDefault";
import Loading from '../../components/alert/LoadingComponent.vue'

export default {
  name: "reportsale",
  components: {
    reportsales,
    Loading,
  },
  data() {
    return {
      report: [],
      reportTitle: "รายวัน",
      selectReport: '',
      epolicyAmt: 0,
      epolicySendMoney: 0,
      linePolicyAmt: 0,
      linePolicySendMoney: 0,
      total: 0,
      loading: false,
    };
  },
  watch: {
    selectReport(val) {
      this.loading = true

      this.report = []
      this.epolicyAmt = 0
      this.epolicySendMoney = 0
      this.linePolicyAmt = 0
      this.linePolicySendMoney = 0
      this.total = 0

      if(val == "report_sale_day") {
        this.reportTitle = "รายวัน"
      }else {
        this.reportTitle = "รายงานการขายงวดปัจจุบันสะสม"
      }
      if (val) {
        this.getReport(val).then(res => {
          this.loading = false
          this.report = res
          if (res.length) {
            const data = res[res.length-1]
            this.epolicyAmt = data.EpolicyAmt
            this.epolicySendMoney = data.EpolicySendMoney
            this.linePolicyAmt = data.LinePolicyAmt
            this.linePolicySendMoney = data.LinePolicySendMoney
            this.total = data.AllSendMoney
          }
        })
      }
    }
  },

  created() {
    authLine.start().then(() =>{
      
       this.getAgent({}).then(() => {
        console.log(this.agent);
      })
      this.selectReport = 'report_sale_day'
    }).catch((err) =>{
      // alert(err);
      console.log(err);
    });

  },


  computed: {
    ...mapState(['agent'])
  },
  methods: {
    ...mapActions(['getAgent']),
    async getReport(report) {
      return new Promise((resolve, reject) => {
        EventService.post("/management/"+report, {
          // agent_id: report == 'report_sale_day' ? '2081991403049000' : '2081818804066003',
          agent_id: this.agent.agent_id,
        }).then((res) => {
          if (res.status) {
            resolve(res.data);
          }else {
            resolve([])
          }
        })
        .catch((error) => {
            console.log(error);
            reject(error)
        });
      })
    }

  },
};
</script>

<style lanf="scss" scoped>
.title-text {
  font-size: 24px;
  font-weight: bold;
}
.title-select {
  font-size: 16px;
  color: #484fe5;
  padding-top: 5px;
  padding-left: 10px;
}

table,
td,
th {
  border: 1px solid #666666;
  text-align: left;
}

table {
  width: 100%;
}

td {
  border: 0.5px solid black;
  font-size: 12px;
  text-align: end;
  color: #2ba5eb;
  padding: 5px;
}
.head-table {
  font-size: 12px;
  font-weight: bold;
}
.head-table th {
  text-align: center;
}
.th-set {
  padding: 5px;
}
.set-col-1-ft {
  color: #484fe5;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
}
.set-col-ft {
  color: #484fe5;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
}
.set-text-ft {
  font-size: 13px;
  font-weight: bold;
}
.set-textcolor1 {
  color: #008e76;
}
.set-textcolor2 {
  color: #484fe5;
}
.text-headcard {
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}
.settext-total1{
  color:#6C6C6C;
  font-size: 14px;
}
.settext-total2{
  color:#000000;
  font-size: 16px;
  float: right;
  font-weight: bold;
}
.color-btn{
  background-color: #00BC9C;
  color: white;
  border: 0;
}
</style>
