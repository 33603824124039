<template>
    <div >
        <div class="col-12 img-ocr">
            <div class="row mt-3 justify-content-center">
                <div class="col-12 text-center">
                <img
                    class="set-img-res"
                    src="../../assets/ด้านหน้าบัตร.png"
                    alt=""
                    width="150"
                />
                </div>
            </div>
            <div class="row pt-3 justify-content-center">
                <div class="col-12">
                    <div class="d-grid gap-2">
                        <button
                            class="btn btn-block"
                            style="background-color: #bce0fd; color: #000000"
                            @click="openSelect"
                        >
                            <i class="fas fa-camera" aria-hidden="true">
                                <h6 style="display: inline-block; margin-left: 10px">
                                แนบภาพบัตรประชาชน
                                </h6>
                            </i>
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mt-3">
            <div class="col12 text-center">
              <h6
                style="
                  color: #ff0000;
                  display: inline-block;
                  margin-right: 10px;
                "
              >
                <b><u>ระบบ OCR สามารถอ่านข้อมูลจากภาพได้</u></b>
              </h6>
              <i
                class="fas fa-question-circle"
                style="color: #848484"
                aria-hidden="true"
              ></i>
            </div>
          </div>
        <input style="display: none" id="input-file-idCard" type="file" @change="selectFile" name="file"/>
        <!-- <PhotoCapture styling="max-width=100%" @input="handleDone" :name='name_id' /> -->

        <loading :show="loading"/>
        <warning :show="warning"/>
        
    </div>
</template>

<script>
import axios from 'axios'
// import PhotoCapture from '../Photo/PhotoCapture.vue'
import Loading from '../alert/LoadingComponent.vue'
import Warning from '../alert/WarningComponent.vue'

export default {
  props: [],
  emits: ["ocrFile"],
  components: {
    // PhotoCapture,
    Loading,
    Warning,
  },
  data() {
      return {
        name_id : 'camera-idcard',
        loading: false,
        warning: {
            show: false,
            msg: '',
            icon: ''
        },
      }
  },
  methods: {
   handleDone(event){
        this.loading = true
        // this.$swal.close();
        let formData = new FormData();
        formData.append("img", event);
        console.log(event,'data idcard');
        // return event;
        // formData.append("img", event.target.files[0])
        if ((Object.getPrototypeOf(event).constructor.name).toString() == 'File' ) {
            axios.post('/ocr/id_card', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        }
                    }).then(res => {
                        console.log(res.data);
                        this.loading = false
                        if (res.data.status) {
                            this.$emit('ocrFile', res.data)
                            this.$swal.close()
                        }else {
                            this.warning = {
                                show: !this.warning.show,
                                msg: "ไม่สามารถอ่านข้อมูลจากภาพได้",
                                icon: 'error'
                            }
                        }
                    }).catch(error =>{
                        console.log(error);
                        // alert(error);
                        this.warning = {
                            show: !this.warning.show,
                            msg: "ไม่สามารถอ่านข้อมูลจากภาพได้",
                            icon: 'error'
                        }
                    })
        }
        
    },
    selectFile(event){
        this.loading = true
        let formData = new FormData();
        formData.append("img", event.target.files[0])
        axios.post('/ocr/id_card', formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then(res => {
            console.log(res.data);
            this.loading = false
            if (res.data.status) {
                this.$emit('ocrFile', res.data)
            }else {
                this.warning = {
                    show: !this.warning.show,
                    msg: "ไม่สามารถอ่านข้อมูลจากภาพได้",
                    icon: 'error'
                }
            }
        }).catch(error =>{
            console.log(error);
            this.loading = false
            this.warning = {
                show: !this.warning.show,
                msg: "ไม่สามารถอ่านข้อมูลจากภาพได้",
                icon: 'error'
            }
        })
        
    },
    openSelect() {
        // document.getElementById(this.name_id).click();
        document.getElementById('input-file-idCard').click()
    },
    openModal() {
  
    }
    

  }
}
</script>

<style scoped>
    .img-ocr {
        height: auto;
        /* width: 300px; */
        border-left: 1px dashed #999999;
        border-top: 1px dashed #999999;
        border-right: 1px dashed #999999;
        margin: auto;
        border: dashed 1px hsla(0, 0%, 50%, 2);
        border-radius: 5px;
    }
</style>
