<template>
  <div class="container set-padding-menu mb-4">
    <div class="row">
      <div class="col-12">
        <div class="text-center">
          <h6 style="font-size:16px"><b>ตรวจสอบข้อมูล</b></h6>
        </div>
      </div>
    </div>
    <section><detaildata /></section>
    <section><detailscar /></section>
    <section><detailsperiod /></section>
    <!-- <section><detailwar /></section> -->

    <section>
      <div class="row mt-3 mb-4">
        <div class="col-6">
          <router-link style="text-decoration: none;" :to="{ name: 'detailsBuynew' }">
            <div class="d-grid gap-2">
              <button
                class="btn btn-lg btn-block"
                style="background-color: #ffb138"
              >
                <i class="fas fa-chevron-left"></i> ย้อนกลับ
              </button>
            </div>
          </router-link>
        </div>
        <div class="col-6">
          <router-link style="text-decoration: none;" :to="{ name: 'DetailsSucceed' }">
            <div class="d-grid gap-2" style="text-decoration: none">
              <button
                class="btn btn-lg btn-block"
                style="background-color: #00bc9c; color: #ffffff"
              >
                <i class="far fa-save"></i> บันทึกข้อมูล
              </button>
            </div>
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import detaildata from "../../components/buy-new/details-data.vue";
import detailscar from "../../components/buy-new/details-car.vue";
import detailsperiod from "../../components/buy-new/details-period.vue";
// import detailwar from "../../components/buy-new/details-ward.vue";

export default {
  name: "ConfirmData",
  components: {
    detaildata,
    detailscar,
    detailsperiod,
    // detailwar,
  },
    mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lanf="scss" scoped >
</style>
