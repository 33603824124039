<template>
  <section class="mb-2">
    <!-- <router-link class="link" :to="{name: 'statement'}"> -->
    <router-link class="link" :to="{}">
      <div class="card">
        <div class="card-body">
          <h1 class="set-header" style="margin-left: 5px">วันที่ปิดยอด {{ statementList.CloseDate }}</h1>
          <div class="row">
            <div class="col-2">
              <img src="../../assets/011-documents.png" width="55" alt="" style="margin-left: 5px"/>
            </div>
            <div class="col-9" style="margin-left: 20px; margin-top: 5px">
              <label for="" style="line-height: 1px" class="header-2" >เบี้ยนำส่งสุทธิ(บาท)</label>
                <!-- <i class="fas fa-angle-right"  style="float: right; font-size: 30px; color: #ffb246"></i>  -->
              <label for="" class="">{{ statementList.SendMoney }}</label>
            </div>
          </div>
        </div>
      </div>
    </router-link>
  </section>
</template>

<script>
export default {
  name: "liststatement",
  props: ["statementList"],
};
</script>

<style lanf="scss" scoped>
.set-header{
  font-size: 16px;
  font-weight: bold;
}
.header-2{
  font-size: 14px;
  color: #333333;
}
</style>
