<template>
  <div class="container set-padding-menu mb-4">
    <section><qrcodesuccesspay :generator-data="generatorData" v-if="success"/></section>

    <div class="row">
      <div class="col-12 text-center">
        <h6 style="font-size: 15px"><b>ยืนยันการชื้อกรมธรรม์</b></h6>
      </div>
    </div>

    <!-- รายละเอียดผู้เอาประกัน -->
    <section>
      <div class="card">
        <div class="card-header" style="background-color: #bce0fd">
          <div class="row">
            <div class="col-10">
              <i
                class="fas fa-id-card"
                style="margin-right: 10px"
                aria-hidden="false"
              ></i>
              <h6
                style="
                  display: inline-block;
                  margin-right: 20px;
                  font-size: 15px;
                  padding-top: 10px;
                "
              >
                <b>รายละเอียดผู้เอาประกัน</b>
              </h6>
            </div>
            <div class="col-2 mt-2">
            <i
                v-on:click="(showPersonal= !showPersonal)"
                style="float: right; color: white"
                id="show-card-confirm-pay"
                class="fas fa-minus set-cursor"
              />
            </div>
          </div>
        </div>
        <transition name="slide-fade">
          <div v-if="showPersonal">
            <div class="card-body card-detail-show" id="card-detail-show">
              <div class="row mb-2">
                <div class="col-4">
                  <h6><b>ผู้เอาประกัน</b></h6>
                </div>
                <div class="col-8">
                  <h6 class="set-color-text-value">{{ formData.title_name }}{{ formData.first_name }} {{ formData.sure_name }}</h6>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-4">
                  <h6><b>ประเภทลูกค้า</b></h6>
                </div>
                <div class="col-8">
                  <h6 class="set-color-text-value">บุลคลธรรมดา</h6>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-4">
                  <h6><b>ประเภทบัตร</b></h6>
                </div>
                <div class="col-8">
                  <h6 class="set-color-text-value">{{ customerCardName }}</h6>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-4">
                  <h6><b>เลขที่บัตร</b></h6>
                </div>
                <div class="col-8">
                  <h6 class="set-color-text-value">{{ formData.id_card }}</h6>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-4">
                  <h6><b>วันเดือนปีเกิด</b></h6>
                </div>
                <div class="col-8">
                  <h6 class="set-color-text-value">{{ formData.birth_date }}</h6>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-4">
                  <h6><b>เบอร์ติดต่อ</b></h6>
                </div>
                <div class="col-8">
                  <h6 class="set-color-text-value">{{ formData.telephone_no }}</h6>
                </div>
              </div>
              <!-- <div class="row mb-2">
                <div class="col-4">
                  <h6><b>รายละเอียด</b></h6>
                </div>
                <div class="col-8">
                  <h6 class="set-color-text-value">-</h6>
                </div>
              </div> -->
              <div class="row mb-2">
                <div class="col-4">
                  <h6><b>วันที่ออกกรมธรรม์</b></h6>
                </div>
                <div class="col-8">
                  <h6 class="set-color-text-value">{{ formData.policy_date }}</h6>
                </div>
              </div>
              <div class="row">
                <h6><b>ที่อยู่ปัจจุบัน</b></h6>
              </div>
              <div class="row mb-2">
                <h6 class="set-color-text-value">{{ formData.addr }} {{ subDistrictName }} {{ districtName }} {{provindeName}} {{ zipCode }}</h6>
              </div>
              <div class="row">
                <h6><b>ที่อยู่ตามทะเบียนบ้าน</b></h6>
              </div>
              <div class="row">
                <h6 v-if="!formData.n_address" class="set-color-text-value">{{ formData.addr }} {{ subDistrictName }} {{ districtName }} {{provindeName}} {{ zipCode }}</h6>
                <h6 v-else class="set-color-text-value">{{ formData.n_address }} {{ subDistrictName_2 }} {{ districtName_2 }} {{provindeName_2}} {{ zipCode_2 }}</h6>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </section>

    <!-- รายละเอียดรถประกัน -->
    <section>
      <div class="card mt-2">
        <div class="card-header" style="background-color: #fde1b6">
          <div class="row">
            <div class="col-10">
              <i
                class="fas fa-motorcycle"
                style="margin-right: 10px"
                aria-hidden="false"
              ></i>
              <h6
                style="
                  display: inline-block;
                  margin-right: 20px;
                  font-size: 15px;
                  padding-top: 10px;
                "
              >
                <b>รายละเอียดรถประกัน</b>
              </h6>
            </div>
            <div class="col-2 mt-2">
              <i
                v-on:click="(showVehicle = !showVehicle)"
                style="float: right; color: white"
                id="show-card-body-confirm-car"
                class="fas fa-minus set-cursor"
              />
            </div>
          </div>
        </div>
        <transition name="slide-fade">
          <div v-if="showVehicle">
            <div class="card-body card-detail-show" id="card-detail-show">
              <div class="row mb-2">
                <div class="col-4">
                  <h6><b>รหัสรถ</b></h6>
                </div>
                <div class="col-8">
                  <h6 class="set-color-text-value">{{ formData.p_car_type }}</h6>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-4">
                  <h6><b>ขนาดรถ (ซีซี)</b></h6>
                </div>
                <div class="col-8">
                  <h6 class="set-color-text-value">{{ formData.engine_size }}</h6>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-4">
                  <h6><b>ทะเบียน</b></h6>
                </div>
                <div class="col-8">
                  <h6 class="set-color-text-value">{{ formData.car_no }} {{ car_changwat }}</h6>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-4">
                  <h6><b>เลขตัวถัง</b></h6>
                </div>
                <div class="col-8">
                  <h6 class="set-color-text-value">{{ formData.car_tank_no }}</h6>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-4">
                  <h6><b>ยี่ห้อรถ</b></h6>
                </div>
                <div class="col-8">
                  <h6 class="set-color-text-value">{{ formData.marque }}</h6>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-4">
                  <h6><b>วันที่คุ้มครอง</b></h6>
                </div>
                <div class="col-8">
                  <h6 class="set-color-text-value">{{ formData.start_date }}</h6>
                </div>
              </div>
              <div class="row mb-2">
                <div class="col-4">
                  <h6><b>วันที่สิ้นสุด</b></h6>
                </div>
                <div class="col-8">
                  <h6 class="set-color-text-value">{{ formData.end_date }}</h6>
                </div>
              </div>
              <!-- <div class="row mb-2">
                <div class="col-4">
                  <h6><b>สถานะ</b></h6>
                </div>
                <div class="col-8">
                  <h6 class="set-color-text-value-status">{{ formData.status }}</h6>
                </div>
              </div> -->
            </div>
          </div>
        </transition>
      </div>
    </section>
    
    <!-- ความคุ้มครอง และค่าเบี้ย -->
    
    <section>
      <div class="card mt-2">
          <div class="card-header" style="background-color: #d6eee1">
            <div class="row">
              <div class="col-10">
                <i
                  class="fas fa-money-bill-alt"
                  style="margin-right: 10px"
                  aria-hidden="false"
                ></i>
                <h6
                  style="
                    display: inline-block;
                    margin-right: 20px;
                    font-size: 15px;
                    padding-top: 10px;
                  "
                >
                  <b>ความคุ้มครอง และค่าเบี้ย</b>
                </h6>
              </div>
              <div class="col-2 mt-2">
                <i
                  v-on:click="(showPremium = !showPremium)"
                  style="float: right; color: white"
                  id="show-card-body-confirm-ward"
                  class="fas fa-minus set-cursor"
                />
              </div>
            </div>
          </div>
          <transition name="slide-fade">
            <div v-if="showPremium">
              <div class="card-body">
                <div class="m-0 p-0">
                  <div class="row mt-2">
                    <div class="col-12">
                      <div class="ward-process-header">
                        <h6 class="mt-2" style="font-size:16px"><b> ระยะเวลาประกันภัย {{numberFormatToText(amtDate)}} วัน</b></h6>
                      </div>
                      <div class="ward-process-size">
                        <div class="ward-process-insize">
                          <div class="test">
                          </div>
                          <table style="width: 90%; padding-top: 20px" class="mx-auto">
                            <tr style="padding-top: 20px">
                              <td><b>เบี้ยประกัน</b></td>
                              <td style="text-align: right"><b>{{ numberFormatToText(formData.p_premium) }}</b></td>
                            </tr>
                            <tr>
                              <td><b>+ อากรแสตมป์</b></td>
                              <td style="text-align: right"><b>{{ numberFormatToText(formData.p_stamp) }}</b></td>
                            </tr>
                            <tr>
                              <td><b>+ ภาษีมูลค่าเพิ่ม</b></td>
                              <td style="text-align: right"><b>{{ numberFormatToText(formData.p_vat) }}</b></td>
                            </tr>
                            <tr>
                              <td style="color: #ff3f42">
                                <u><b>รวมทั้งสิ้น</b> </u>
                              </td>
                              <td style="text-align: right; color: #ff3f42">
                                <b>{{ numberFormatToText(formData.p_total) }}</b>
                              </td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
      </div>
    </section>

    <section v-show="!success">
      <div class="row mt-3 mb-4">
        <div class="col-6">
          <div class="d-grid gap-2">
            <button
              class="btn btn-lg btn-block"
              style="background-color: #ffb138"
              @click="back"
            >
              <i class="fas fa-chevron-left"></i> ย้อนกลับ
            </button>
          </div>
        </div>
        <div class="col-6">
            <div class="d-grid gap-2" style="text-decoration: none">
              <button
                class="btn btn-lg btn-block"
                style="background-color: #00bc9c; color: #ffffff"
                @click="btnConfirm"
              >
                <i class="far fa-save"></i> บันทึกข้อมูล
              </button>
            </div>
        </div>
      </div>
    </section>

    <section v-show="success">
      <div class="row mt-3 mb-4 justify-content-center">
        <div class="col-12">
          <router-link
            style="text-decoration: none"
            :to="{ name: 'renewHome' }"
          >
            <div class="d-grid gap-2">
              <button
                class="btn btn-lg btn-block"
                style="background-color: #bce0fd; color: #000000"
              >
                กลับหน้าหลัก
              </button>
            </div>
          </router-link>
        </div>
      </div>
    </section>

    <loading :show="loading"/>
    <requrie :show="requrie"/>
    <warning :show="warning"/>

  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import qrcodesuccesspay from "../../../components/renew-com/success-pay-qrcode.vue";
import EventService from '../../../services/EventService';
import Loading from '../../../components/alert/LoadingComponent.vue'
import Requrie from '../../../components/alert/RequrieComponent.vue'
import Warning from '../../../components/alert/WarningComponent.vue'

export default {
  name: "confirmPay",
  components:{
    qrcodesuccesspay,
    Loading,
    Requrie,
    Warning,
  },
  emits: [],
  props: [],
  data() {
    return {
      generatorData: {qr: '', barcode: '', agent_id: '', policy_no: ''},
      showPersonal: true,
      showAddress: true,
      showVehicle: true,
      showPremium: true,
      customerCardName: '',
      car_changwat: '',
      provindeName: '',
      districtName: '',
      subDistrictName: '',
      zipCode: '',
      provindeName_2: '',
      districtName_2: '',
      subDistrictName_2: '',
      zipCode_2: '',
      formData: this.$route.params,
      amtDate: this.$route.params.p_amt_date,
      success: false,
      loading: false,
      requrie: false,
      warning: {
        show: false,
        msg: '',
        icon: 'w'
      },
    }
  },
  computed:{
    ...mapState([
      'customer_card',
      'province',
      'district',
      'sub_district',
      'district_2',
      'sub_district_2',
      'agent'
      ]),
    ...mapGetters(['numberFormatToText']),
  },
  created() {
    delete this.formData.p_amt_date
    if (this.customer_card.length) {
      this.customerCardName = this.customer_card.find(data => data.CardID === this.formData.card_type).CardName
    }
    if (this.province.length) {
      this.provindeName = this.province.find(data => data.ProvinceId === this.formData.p_changwat).ChangwatName
      if (this.formData.n_changwat) {
        this.provindeName_2 = this.province.find(data => data.ProvinceId === this.formData.n_changwat).ChangwatName
      }
      this.car_changwat = this.province.find(data => data.ProvinceId === this.formData.car_changwat).ChangwatName
    }
    if (this.district.length) {
      this.districtName = this.district.find(data => data.CityId === this.formData.p_amphur).AmphurName
      if (this.formData.n_amphur && this.district_2.length) {
        this.districtName_2 = this.district_2.find(data => data.CityId === this.formData.n_amphur).AmphurName
      }
    }
    if (this.sub_district.length) {
      this.subDistrictName = this.sub_district.find(data => data.DistrictId === this.formData.p_tumbol).TumbolName
      this.zipCode = this.sub_district.find(data => data.DistrictId === this.formData.p_tumbol).Zipcode
      if (this.formData.n_tumbol && this.sub_district_2.length) {
        this.subDistrictName_2 = this.sub_district_2.find(data => data.DistrictId === this.formData.n_tumbol).TumbolName
        this.zipCode_2 = this.sub_district_2.find(data => data.DistrictId === this.formData.n_tumbol).Zipcode
      }
    }
  },
  methods: {
    back() {
      this.$router.push({ name: 'detailsMember', params: this.formData})
    },
    btnConfirm() {
      this.loading = true
      EventService.post('/insurance/issue_policy', this.formData).then( async(res) => {
        this.loading = false
        if (res) {
          // console.log(res);
          if (res.data.PolicyNo) {
            let policy = res.data.PolicyNo
            // let policy = "8188064200003431"
            this.getInsurance(this.agent.agent_id, policy).then(res => {
              this.generatorData = res
              this.success = true
              window.scrollTo(0, 0);
            })
          }else {
            this.warning = {
              show: !this.warning.show,
              msg: res.data.Msg,
              icon: 'warning'
            }
          }
        }
      }).catch(error =>{
        console.log(error);
        // alert(JSON.stringify(error));
        this.warning = {
          show: !this.warning.show,
          msg: 'ไม่สามารถทำรายการซื้อกรมธรรม์ได้',
          icon: 'error'
        }
      })
    },
    getInsurance(agent_id, policy_no) {
      return new Promise((resolve) => {
        this.loading = true
        EventService.post('/insurance/get_insurance_detail', {
          "agent_id": agent_id,
          "policy_no": policy_no,
        }).then(res => {
          this.loading = false
          // console.log(res);
          if (res.status) {
            resolve({qr: res.data.QRCode, barcode: res.data.BarCode, agent_id: res.data.AgentID, policy_no: res.data.PolicyNo})
          }
        }).catch(error =>{
          console.log(error);
        })
      })
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lanf="scss" >
.set-cursor {
  cursor: pointer;
}
.slide-fade-enter-active {
  transition: all 0.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-10px);
  opacity: 0;
}
.set-color-text-value {
  color: #484fe5;
  font-weight: bold;
  font-size: 14px;
}
.ward-process-header {
  height: 50px;
  width: 100%;
  background-color: #d6eee1;
  text-align: center;
    padding-top: 10px;
  color: #008e76;
}

.ward-process-size {
  background-color: #d6eee1;
  height: 140px;
}
.ward-process-insize {
  background-color: #ffffff;
  height: 120px;
  margin: 0px 20px;
}
td{
  font-size: 14px;
}
.test{
  height: 17px;
  width: 100px;
}
</style>