<template>
  <div class="container set-padding-menu">
    <div class="row mb-3">
      <div class="col-12 text-center">
        <img
          aria-hidden="true"
          src="../../assets/Group_3530.png"
          width="200"
          alt=""
          style="margin-right: 10px"
        />
      </div>
    </div>
    <section><qrcodesuccessbuynew /></section>
    <section><detaildata /></section>
    <section><detailscar /></section>
    <section><detailsperiod /></section>
    <section>
      <div class="row mt-3 mb-4 justify-content-center">
        <div class="col-12">
          <router-link style="text-decoration: none" :to="{ name: 'renewHome' }">
            <div class="d-grid gap-2">
              <button
                class="btn btn-lg btn-block"
                style="background-color: #bce0fd; color: #000000"
              >
                กลับหน้าหลัก
              </button>
            </div>
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import qrcodesuccessbuynew from "../../components/buy-new/qrcode-success.vue";
import detaildata from "../../components/buy-new/details-data.vue";
import detailscar from "../../components/buy-new/details-car.vue";
import detailsperiod from "../../components/buy-new/details-period.vue";

export default {
  name: "DetailsSucceed",
  components: {
    qrcodesuccessbuynew,
    detaildata,
    detailscar,
    detailsperiod,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lanf="scss" scoped >
</style>
