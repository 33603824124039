import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

import axios from 'axios'
import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
import Vue3SimpleHtml2pdf from "vue3-simple-html2pdf";

axios.defaults.baseURL = window.location.origin+'/api'


createApp(App)
  .use(store)
  .use(router)
  .use(VueSweetalert2)
  .use(Vue3SimpleHtml2pdf)
  .mount("#app");