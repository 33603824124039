<template>
    <section>
        <div class=" container pt-0">
            <div class="row">
                <div class="col-12 pt-0">
                    <h1 class="text-center">{{labelDetail}}</h1>
                    <!-- <label class="text-left">เงื่อนไขการเติมเงินเข้าระบบ</label> -->
                </div>
            </div>
            <!-- <div class=" row pt-3 p-1">
                <p class="col-form-label col-form-label-sm"> 1. ชำระผ่าน เคาน์เตอร์เซอร์วิส ที่เซเว่นอีเลฟเว่น / ทรูมันนี่ เอ็กซ์เพรส / CP Fresh Mart / ยอดเงินถูกเติมเข้าระบบทันที พร้อมรับ SMS ยืนยันการชำระเงิน </p>
                <p class=" col-form-label col-form-label-sm"> 2. ชำระผ่าน ธ.ไทยพาณิชย์ / ตัวแทนธ.ไทยพาณิชย์ (Tesco Lotus) (COMP CODE: 0612) ยอดเงินถูกเติมเข้าระบบทันที พร้อมรับ SMS ยืนยันการชำระเงิน</p>
                <p class=" col-form-label col-form-label-sm"> 3. ชำระผ่าน ธ.กรุงไทย (COMP CODE:6078) ธ.กสิกรไทย (COMP CODE:33553) ยอดเงินถูกเติมเข้าระบบ วันละ 3 รอบ เวลา 10.00น. /14.00น. /17.00น. </p>
                <p class=" col-form-label col-form-label-sm pl-2"> - ชำระหลังเวลาทำการ (17.00น.) ยอดเงินถูกเติมเข้าระบบในวันทำการถัดไป เวลา 10.00น.</p>
                <p class=" col-form-label col-form-label-sm pl-2"> - ชำระในวันหยุดและวันหยุดนักขัตฤกษ์ ยอดเงินถูกเติมเข้าระบบในวันเปิดทำการวันแรก เวลา 10.00 น. </p>
                <p class=" col-form-label col-form-label-sm "> 4. ชำระผ่าน เอไอเอส ช็อป, ร้านเทเลวิซ และจุดรับชำระที่มีสัญลักษณ์เอ็มเปย์สเตชั่นทั่วประเทศ ยอดเงินถูกเติมเข้าระบบในวันทำการถัดไป เวลา 10.00น.</p>
            </div> -->
            <div class="row pt-1">
                <img class="col py-1" src="../../assets/ลำดับ1.jpg" />
                <img class="col py-1" src="../../assets/ลำดับ2.jpg" />
                <img class="col py-1" src="../../assets/ลำดับ3.jpg" />
                <img class="col py-1" src="../../assets/ลำดับ5.jpg" />
            </div>
        </div>
    </section>

</template>

<script>
import {authLine} from "../js/setDefault";
export default {
    name: "storyList",
     data() 
        {  
        return {
        labelDetail: "เรื่องดีๆ กับ RVP"
    }
    },
    created(){
      authLine.start().then(() =>{
      if (authLine.getAgentStatus() == 1) {
          
          console.log(authLine.getAgentStatus());
      } else {
        this.$swal.fire({
                          icon: 'error',
                          text: 'กรุณาติดต่อเจ้าหน้าที่',
                          // showCloseButton: true,
                          allowOutsideClick: false,
                          customClass: {
                            container: 'p-4',
                            confirmButton: 'btn btn-primary px-5 py-1',
                          },
                          buttonsStyling: false
                        }).then((result) => {
                          if (result.isConfirmed) {
                              if (authLine.getAgentStatus() == 2) {
                                    authLine.closeWindow(); 
                              } else {
                                    authLine.changeRichMenu();
                              }
                          }
                        });
      }
    }).catch((err) =>{
      alert(err);
    });
    },
    mounted()
    {
      
   },
}
</script>
<style lanf="scss" scoped >

h5 {
   font-size:10px;
}
.container div{
    padding-top: 30%;
}

.pl-2{
    padding-left: 2em;
    padding-right: 2em;
}

.text-justify{
    text-align: justify;
}
</style>