<template>
  <div class="container set-padding-menu mb-4">
    <div class="card mt-3" v-show="searchShow">
      <div
        class="card-header"
        style="background-color: #484fe5; color: #ffffff"
      >
        <h6 style="display: inline-block; margin-right: 20px">
          <b>ผลการค้นหา</b>
        </h6>
      </div>
      
      <!-- loop data -->
      <contentSearch v-for="value in searchByPolicyNo" :key="value.PolicyNo" :data="value" />

    </div>
    <div class="card mt-3" v-show="!searchShow">
      <div
        class="card-header"
        style="background-color: #484fe5; color: #ffffff"
      >
        <h6 style="display: inline-block; margin-right: 20px">
          <b>ผลการค้นหา</b>
        </h6>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12 text-center">
            <h5>ไม่พบข้อมูล</h5>
            <h6>เลขกรมธรรม์ : {{ policyNo }}</h6>
          </div>
        </div>
      </div>
    </div>
    <loading :show="loading"/>
    <warning :show="warning"/>
  </div>
</template>

<script>
import contentSearch from "../../../components/renew-com/content-Search.vue";
import EventService from '../../../services/EventService';
import { mapActions, mapState } from 'vuex';
import Loading from '../../../components/alert/LoadingComponent.vue'
import Warning from '../../../components/alert/WarningComponent.vue'

export default {
  name: "PolicyNumber",
  components: { contentSearch,Loading,Warning },
  data() {
    return {
      searchByPolicyNo: [],
      // policyNo: this.$route.params.policy,
      qrCode: this.$route.params.policy,
      policyNo: '',
      searchShow: false,
      loading: false,
      warning: {
        show: false,
        msg: '',
        icon: ''
      },
    };
  },
  
  created() {
    this.getAgent()
    this.searchData()
  },
   computed: {
    ...mapState(['agent'])
  },
  methods: {
    ...mapActions(['getAgent']),
    searchData() {
      const policy_no = this.qrCode.split(',')

      if (policy_no.length) {
          this.policyNo = policy_no[0]
          this.loading = true

          EventService.post('/search/by_policy_no', {
            "agent_id":  this.agent.agent_id,
            "user_token_id":this.agent.token,
            "policy_no": this.policyNo
          }).then(res => {
            console.log(res);
            this.loading = false

            if (res.status) {
              this.searchByPolicyNo = res.data
              this.searchShow = true
            }

          }).catch(error =>{
            console.log(error);
            this.warning = {
              show: !this.warning,
              msg: 'กรุณาตรวจสอบข้อมูล',
              icon: 'warning'
           }
          })
        }else {
          this.warning = {
            show: !this.warning,
            msg: 'กรุณาตรวจสอบข้อมูล',
            icon: 'warning'
          }
        }
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lanf="scss" scoped >
h6 {
  font-size: 14px;
}

.ref-title-men {
  text-align: center;
}
.img-ocr {
  height: auto;
  /* width: 300px; */
  border-left: 1px dashed #999999;
  border-top: 1px dashed #999999;
  border-right: 1px dashed #999999;
  margin: auto;
  border: dashed 4px hsla(0, 0%, 50%, 2);
}
</style>
