<template>
  <div class="card mt-2" v-if="Rlistpayment.Name != 'SCB'">
    <!-- part Qrcode -->
    <router-link :to="{ name: 'qrbarpaymentSacn', params: { cash: cash, payment: payment, type: Rlistpayment.Name}}">
      <div class="card-body">
        <div class="row">
          <div class="col-3">
            <img :src="image" style="width: 100%" alt="" />
          </div>
          <div class="col-6">
            <div class="text-pay">{{ Rlistpayment.Name }}</div>
          </div>
          <div class="col-3">
              <div class="set-arrow">
                <img src="../../assets/ico_arrow02.png" class=""  alt=""  width="30"  style="float: right"  />
              </div>
            <!--  -->
          </div>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  name: "paymentlist",
  props: ["Rlistpayment", "cash", "payment"],
  data() {
    return {
      image: this.Rlistpayment.PicPath,
    };
  },
};
</script>

<style lanf="scss" scoped>
.text-pay {
  font-size: 16px;
  font-weight: bold;
  padding-top: 6px;
}

@media all and (device-width: 768px) and (device-height: 1024px) and (orientation: portrait) {
  .text-pay {
    padding-top: 30px;
  }
  .set-arrow {
    padding-top: 30px;
  }
}
</style>
