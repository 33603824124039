<template>
  <div class="card mt-2">
    <div class="card-header" style="background-color: #fde1b6">
      <div class="row">
        <div class="col-10">
          <i
            class="fas fa-motorcycle"
            style="margin-right: 10px"
            aria-hidden="true"
          ></i>
          <h6
            style="
              display: inline-block;
              margin-right: 20px;
              font-size: 15px;
              padding-top: 10px;
            "
          >
            <b>รายละเอียดรถประกัน</b>
          </h6>
        </div>
        <div class="col-2 mt-2">
          <i
            v-on:click="(show = !show), seticon(), (count = count + 1)"
            style="float: right; color: white"
            id="show-card-bodycar"
            class="fas fa-minus set-cursor"
          ></i>
        </div>
      </div>
    </div>
    <transition name="slide-fade">
      <div v-if="show">
        <div class="card-body card-detail-show" id="card-detail-show">
          <div class="row mb-2">
            <div class="col-4">
              <h6><b>รหัสรถ</b></h6>
            </div>
            <div class="col-8">
              <h6 class="set-color-text-value">1.30C บุคคล</h6>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-4">
              <h6><b>ขนาดรถ (ซีซี)</b></h6>
            </div>
            <div class="col-8">
              <h6 class="set-color-text-value">150</h6>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-4">
              <h6><b>ทะเบียน</b></h6>
            </div>
            <div class="col-8">
              <h6 class="set-color-text-value">1สล1191 สงขลา</h6>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-4">
              <h6><b>เลขตัวถัง</b></h6>
            </div>
            <div class="col-8">
              <h6 class="set-color-text-value">12312ASDASD123</h6>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-4">
              <h6><b>ยี่ห้อรถ</b></h6>
            </div>
            <div class="col-8">
              <h6 class="set-color-text-value">Vespa</h6>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-4">
              <h6><b>วันที่คุ้มครอง</b></h6>
            </div>
            <div class="col-8">
              <h6 class="set-color-text-value">04/02/2564</h6>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-4">
              <h6><b>วันที่สิ้นสุด</b></h6>
            </div>
            <div class="col-8">
              <h6 class="set-color-text-value">04/02/2565</h6>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-4">
              <h6><b>สถานะ</b></h6>
            </div>
            <div class="col-8">
              <h6 class="set-color-text-value-status">ปกติ (A)</h6>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
export default {
  name: "detailscar",
  data() {
    return {
      show: true,
      count: 0,
    };
  },
  methods: {
    seticon() {
      if (this.count % 2 == 0) {
        document.getElementById("show-card-bodycar").className =
          "fas fa-plus set-cursor";
      } else {
        document.getElementById("show-card-bodycar").className =
          "fas fa-minus set-cursor";
      }
      console.log(this.count);
    },
  },

};
</script>

<style lanf="scss" scoped >
.set-cursor {
  cursor: pointer;
}
.slide-fade-enter-active {
  transition: all 0.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-10px);
  opacity: 0;
}
.set-color-text-value {
  color: #484fe5;
  font-weight: bold;
}
.set-color-text-value-status {
  color: #80466e;
  font-weight: bold;
}
</style>
