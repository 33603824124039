<template>
    <div class="container set-padding-menu mb-4" v-show="!showConfirm">
      <div class="row">
        <div class="col-6">
          <div class="text-right">
            <h6><b>รายละเอียดผู้เอาประกัน</b></h6>
          </div>
        </div>
        <div class="col-6">
          <h6 style="float: right; color: #484fe5"><b>{{ title }}</b></h6>
        </div>
      </div>

      <!-- ข้อมูลส่วนตัว -->
      <section>
        <div class="card">
          <div class="card-header" style="background-color: #bce0fd">
            <div class="row">
              <div class="col-10">
                <i
                  class="fas fa-id-card"
                  style="margin-right: 10px"
                  aria-hidden="true"
                ></i>
                <h6 style="display: inline-block; margin-right: 20px">
                  <b>ประเภทลูกค้าบุคคลธรรมดา</b>
                </h6>
              </div>
              <div class="col-2 mt-2">
                <i
                  v-on:click="(showPersonal = !showPersonal)"
                  style="float: right; color: white"
                  id="show-card-body-detail"
                  class="fas fa-minus set-cursor"
                />
              </div>
            </div>
          </div>
          <transition name="slide-fade">
            <div v-if="showPersonal">
              <div class="card-body card-detail-show" id="card-detail-show">
                <!-- ocr -->
                <div v-show="newInsurance" class="mb-3">
                  <id-card-component @ocrFile="setPersonal"/> 
                </div>

                <div class="row mb-3">
                  <div class="col-6" >
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        value="01"
                        v-model="formData.nation"
                      />
                      <label class="form-check-label" for="inlineRadio1"
                        ><b>สัญชาติไทย</b>
                      </label>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        value="03"
                        v-model="formData.nation"
                      />
                      <label class="form-check-label" for="inlineRadio2"
                        ><b>ไม่ทราบสัญชาติ</b>
                      </label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-5">
                    <div class="form-check form-check-inline">
                      <input
                        class="form-check-input"
                        type="radio"
                        value="02"
                        v-model="formData.nation"
                      />
                      <label class="form-check-label" for="inlineRadio3"
                        ><b>สัญชาติอื่นๆ</b>
                      </label>
                    </div>
                  </div>
                  <div class="col-7">
                    <select name="" id="nationalityAll" :class="{'form-select': true, 'errorText': errorStatus('person_detail')}" v-model="formData.person_detail" :disabled="disableNationality">
                      <option v-for="value in nationality" :key="value.Abbreviation_UN" :value="value.Abbreviation_UN">{{ value.Nationality }}</option>
                    </select>
                  </div>
                </div>
                <div class="row">
                  <div class="col-12">
                    <div class="form-group">
                      <label for=""><b>ประเภทบัตร</b> <b class="text-require"> *</b></label>
                      <select name="" id="" :class="{'form-select': true, 'errorText': errorStatus('card_type')}" v-model="formData.card_type">
                        <option v-for="value in customer_card" :key="value.CardID" :value="value.CardID">{{ value.CardName }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <div class="form-group">
                      <label for=""><b>เลขที่บัตร</b> <b class="text-require"> *</b></label>
                      <input
                        type="text"
                        :class="{'form-control': true, 'errorText': errorStatus('id_card')}"
                        placeholder="กรอกเลขที่บัตร"
                        v-model="formData.id_card"
                      />
                    </div>
                  </div>
                </div>

                <change-birth-date-component :date="formData.birth_date" @change-brithdays="changeBirthDay" class="mt-2">
                    วัน เดือน ปี เกิด (อายุ 17 ปีบริบูรณ์ขึนไป)
                </change-birth-date-component>

                <div class="row mt-2">
                  <div class="col-4">
                    <div class="form-group">
                      <label for=""><b>คำนำหน้า</b>  <b class="text-require"> *</b></label>
                      <select name="" id="" :class="{'form-select': true, 'errorText': errorStatus('title_name')}" v-model="formData.title_name">
                        <option v-for="value in prefix" :key="value.TitleName" :value="value.TitleName">{{ value.TiTleDesc }}</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-8">
                    <div class="form-group">
                      <label for=""><b>ชื่อ</b> <b class="text-require"> *</b></label>
                      <input type="text" :class="{'form-control': true, 'errorText': errorStatus('first_name')}" v-model="formData.first_name" />
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <div class="form-group">
                      <label for=""><b>นามสกุล</b> <b class="text-require"> *</b> </label>
                      <input
                        type="text"
                        :class="{'form-control': true, 'errorText': errorStatus('sure_name')}"
                        v-model="formData.sure_name"
                      />
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <div class="form-group">
                      <label for=""><b>หมายเลขโทรศัพท์มือถือ</b> <b class="text-require"> *</b> </label>
                      <input type="tel" :class="{'form-control': true, 'errorText': errorStatus('telephone_no')}" pattern="[0-9]*" v-model="formData.telephone_no" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </section>

      <!-- ที่อยู่ -->
      <section>
        <div class="card mt-2">
          <div class="card-header" style="background-color: #bce0fd">
            <div class="row">
              <div class="col-10">
                <i
                  class="fas fa-home"
                  style="margin-right: 10px"
                  aria-hidden="true"
                ></i>
                <h6 style="display: inline-block; margin-right: 20px;font-size: 15px;padding-top: 10px;">
                  <b>ที่อยู่อาศัย</b>
                </h6>
              </div>
              <div class="col-2 mt-2">
                <i
                  v-on:click="(showAddress = !showAddress)"
                  style="float: right; color: white"
                  id="show-card-body-address"
                  class="fas fa-minus set-cursor"
                />
              </div>
            </div>
          </div>
          <transition name="slide-fade">
            <div v-if="showAddress">
              <div class="card-body">
                <div class="row">
                  <div class="form-group">
                    <label for=""><b>ที่อยู่</b> <b class="text-require"> *</b></label>
                    <textarea
                      v-model="formData.addr"
                      cols="30"
                      rows="3"
                      :class="{'form-control': true, 'errorText': errorStatus('addr')}"
                    ></textarea>
                  </div>
                  
                </div>
                <div class="row mt-2">
                  <div class="form-group">
                    <label for=""><b>จังหวัด</b> <b class="text-require"> *</b></label>
                    <select name="" :class="{'form-select': true, 'errorText': errorStatus('p_changwat')}" id="" v-model="formData.p_changwat" @change="selectDistrict($event.target.value)">
                      <option v-for="data in province" :key="data.ProvinceId" :value="data.ProvinceId">{{ data.ChangwatName }}</option>
                    </select>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="form-group">
                    <label for=""><b>อำเภอ</b> <b class="text-require"> *</b></label>
                    <select name="" :class="{'form-select': true, 'errorText': errorStatus('p_amphur')}" id="" v-model="formData.p_amphur" @change="selectSubDistrict($event.target.value)">
                        <option v-for="data in district" :key="data.CityId" :value="data.CityId">{{ data.AmphurName }}</option>
                    </select>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="form-group">
                    <label for=""><b>ตำบล</b> <b class="text-require"> *</b></label>
                    <select name="" :class="{'form-select': true, 'errorText': errorStatus('p_tumbol')}" id="" v-model="formData.p_tumbol">
                      <option v-for="data in sub_district" :key="data.DistrictId" :value="data.DistrictId">{{ data.TumbolName }}</option>
                    </select>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="form-group">
                    <label for=""><b>รหัสไปรษณีย์</b> <b class="text-require"> *</b></label>
                    <input type="number" :class="{'form-control': true, 'errorText': errorStatus('p_zipcode')}" pattern="[0-9]*" v-model.number="formData.p_zipcode" />
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col-12">
                    <div class="form-group">
                      <label for=""><b>ประเทศ</b> <b class="text-require"> *</b></label>
                      <select name="" :class="{'form-select': true, 'errorText': errorStatus('country')}" id="" v-model="formData.country">
                        <option v-for="data in country" :key="data.Country" :value="data.Country">{{ data.CounCountryInThaitry }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row mt-2">
                  <div class="col">
                    <div class="form-check">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        v-model="houseAddress"
                      />
                      <label
                        class="form-check-label"
                        for="chkAddress"
                        style="font-size: 12px"
                      >
                        ที่อยู่ตามทะเบียนบ้าน (หากไม่เหมือนที่อยู่ปัจจุบันกรุณาระบุ)
                      </label>
                    </div>
                  </div>
                  <div class="dvAddress" id="dvAddress" v-show="houseAddress">
                    <div class="col-12 mt-2">
                      <div class="set" style="border-top: 1px solid #ced4da"></div>
                      <h6 class="mt-2"><b>ที่อยู่ตามทะเบียนบ้าน</b></h6>
                    </div>
                    <div class="row">
                      <div class="form-group">
                        <label for=""><b>ที่อยู่</b> <b class="text-require"> *</b></label>
                        <textarea
                          v-model="formData.n_address"
                          cols="30"
                          rows="3"
                          :class="{'form-control': true, 'errorText': errorStatus('n_address')}"
                        ></textarea>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="form-group">
                        <label for=""><b>จังหวัด</b> <b class="text-require"> *</b></label>
                        <select name="" :class="{'form-select': true, 'errorText': errorStatus('n_changwat')}" id="" v-model="formData.n_changwat" @change="selectDistrict2($event.target.value)">
                          <option v-for="data in province" :key="data.ProvinceId" :value="data.ProvinceId">{{ data.ChangwatName }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="form-group">
                        <label for=""><b>อำเภอ</b> <b class="text-require"> *</b></label>
                        <select name="" :class="{'form-select': true, 'errorText': errorStatus('n_amphur')}" id="" v-model="formData.n_amphur"  @change="selectSubDistrict2($event.target.value)">
                          <option v-for="data in district_2" :key="data.CityId" :value="data.CityId">{{ data.AmphurName }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="form-group">
                        <label for=""><b>ตำบล</b> <b class="text-require"> *</b></label>
                        <select name="" :class="{'form-select': true, 'errorText': errorStatus('n_tumbol')}" id="" v-model="formData.n_tumbol">
                          <option v-for="data in sub_district_2" :key="data.DistrictId" :value="data.DistrictId">{{ data.TumbolName }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="form-group">
                        <label for=""><b>รหัสไปรษณีย์</b> <b class="text-require"> *</b></label>
                        <input type="number" :class="{'form-control': true, 'errorText': errorStatus('n_zipcode')}" pattern="[0-9]*" v-model.number="formData.n_zipcode" />
                      </div>
                    </div>
                    <div class="row mt-2">
                      <div class="col-12">
                        <div class="form-group">
                          <label for=""><b>ประเทศ</b> <b class="text-require"> *</b></label>
                          <select name="" :class="{'form-select': true, 'errorText': errorStatus('n_country')}" v-model="formData.n_country">
                            <option v-for="data in country" :key="data.Country" :value="data.Country">{{ data.CounCountryInThaitry }}</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </section>

      <!-- รถเอาประกัน -->
      <section>
        <div class="card mt-2">
          <div class="card-header" style="background-color: #fde1b6">
            <div class="row">
              <div class="col-10">
                <i
                  class="fas fa-motorcycle"
                  style="margin-right: 10px"
                  aria-hidden="true"
                ></i>
                <h6 style="display: inline-block; margin-right: 20px">
                  <b>รถเอาประกัน</b></h6>
              </div>
              <div class="col-2 mt-2">
              <i
                  v-on:click="(showVehicle = !showVehicle)"
                  style="float: right; color: white"
                  id="show-card-body-car"
                  class="fas fa-minus set-cursor"
                />
              </div>
            </div>
          </div>

          <transition name="slide-fade">
            <div v-if="showVehicle">
              <div class="card-body">
                <div v-show="newInsurance" class="mb-3">
                  <!-- ocr -->
                  <thailand-car-book @ocrFile="setVehicle" :img="require('@/assets/Information_GreemBook11.png')"/>
                </div>

                <div class="row">
                  <div class="form-group">
                    <label for=""><b>รหัสรถ</b> <b class="text-require"> *</b></label>
                    <div class="form-group">
                      <select name="" id="" :class="{'form-select': true, 'errorText': errorStatus('p_car_type')}" v-model="formData.p_car_type">
                        <option v-for="value in car_type" :key="value.CartTypeID" :value="value.CartTypeID">{{ value.CartTypeNewName }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="form-group">
                    <label for=""><b>ขนาดรถ (ซีซี)</b> <b class="text-require"> *</b></label>
                    <div class="form-group">
                        <input type="number" :class="{'form-control': true, 'errorText': errorStatus('engine_size')}" pattern="[0-9]*" v-model.number="formData.engine_size" @blur="checkValEngineSize">
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="form-group">
                    <label for=""><b>ยี่ห้อรถ</b> <b class="text-require"> *</b></label>
                    <div class="form-group">
                      <select name="" id="" :class="{'form-select': true, 'errorText': errorStatus('marque')}" v-model="formData.marque">
                        <option v-for="value in car_band" :key="value.CarID" :value="value.CarName">{{ value.CarName }}</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="form-group">
                    <label for=""><b>เลขตัวถัง</b> <b class="text-require"> *</b></label>
                    <div class="form-group">
                      <input
                        type="text"
                        :class="{'form-control': true, 'errorText': errorStatus('car_tank_no')}"
                        v-model="formData.car_tank_no"
                      />
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="form-group">
                    <label for=""><b>ทะเบียน</b> <b class="text-require"> *</b></label>
                    <div class="row">
                      <div class="col-12 pb-2">
                        <select name="" id="" :class="{'form-select': true, 'errorText': errorStatus('marque')}" v-model="masterLicenseType">
                          <option v-for="value in license_type" :key="value.TypeID" :value="value.LicenseType">{{ value.LicenseType }}</option>
                        </select>
                      </div>
                      <div class="col-6">
                        <input type="text" :class="{'form-control': true, 'errorText': errorStatus('car_no')}" v-model="carNo2" :readonly="statusCarNo2" placeholder="หมวดตัวอักษร"/>
                      </div>
                      <div class="col-6">
                        <input type="tel" :class="{'form-control': true, 'errorText': errorStatus('car_no')}" pattern="[0-9]*" v-model="carNo1" :readonly="statusCarNo1" :placeholder="placeholderCarNo1"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mt-3">
                  <div class="form-group">
                    <label for=""><b>จังหวัด</b> <b class="text-require"> *</b></label>
                    <div class="form-group">
                      <select name="" id="" :class="{'form-select': true, 'errorText': errorStatus('car_changwat')}" v-model="formData.car_changwat">
                        <option v-for="value in province" :key="value.ProvinceId" :value="value.ProvinceId">{{ value.ChangwatName }}</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </transition>
        </div>
      </section>

      <!-- ความคุ้มครอง และค่าเบี้ย -->
      <section>
        <div class="card mt-2">
          <div class="card-header" style="background-color: #d6eee1">
            <div class="row">
              <div class="col-10">
                <i
                  class="fas fa-money-bill-alt"
                  style="margin-right: 10px"
                  aria-hidden="true"
                ></i>
                <h6 style="display: inline-block; margin-right: 20px">
                  <b>ความคุ้มครอง และค่าเบี้ย</b>
                </h6>
              </div>
              <div class="col-2 mt-2">
                <i
                  v-on:click="(showPremium = !showPremium)"
                  style="float: right; color: white"
                  id="show-card-body-warddate"
                  class="fas fa-minus set-cursor"
                />
              </div>
            </div>
          </div>
          <transition name="slide-fade">
            <div v-if="showPremium">
              <div class="card-body">
                <change-date-component :date="formData.start_date" valDate="start_date" @change-date="changeStartDate">
                    วันที่เริ่มต้นคุ้มครอง
                </change-date-component>

                <change-date-component class=" mt-3" :date="formData.end_date" valDate="end_date" @change-date="changeEndDate">
                    วันที่สิ้นสุดความคุ้มครอง
                </change-date-component>

                <div class="row">
                  <div class="col-12">

                    <wardprocess 
                      :start-date="formData.start_date" 
                      :end-date="formData.end_date" 
                      :engine-size="formData.engine_size" 
                      :car-type="formData.p_car_type"
                      @premium="setPremium"
                    />

                  </div>
                </div>

              </div>
            </div>
          </transition>
        </div>
      </section>
      
      <section>
        <div class="row mt-3">
          <div class="d-grid gap-2">
            <button
              :class="[btnConfirm ? 'btn btn-secondary btn-lg' : 'btn btn-primary btn-lg']"
              type="button"
              @click="confirm"
              :disabled="btnConfirm"
            >
              ถัดไป
            </button>
          </div>
        </div>
      </section>

      <loading :show="loading"/>
      <requrie :show="requrie"/>
      <warning :show="warning"/>

    </div>
</template>

<script>
import ChangeDateComponent from "../../../components/date/ChangeDateComponent.vue";
import ChangeBirthDateComponent from "../../../components/date/ChangeBirthDateComponent.vue";
import EventService from '../../../services/EventService';
import wardprocess from '../../../components/renew-com/ward-date-process.vue'
import ThailandCarBook from "../../../components/ocr/ThailandCarBookComponent.vue"
import IdCardComponent from '../../../components/ocr/IdCardComponent.vue';

import { authLine } from "../../js/setDefault";

import { mapActions, mapGetters, mapState } from 'vuex'

import Loading from '../../../components/alert/LoadingComponent.vue'
import Requrie from '../../../components/alert/RequrieComponent.vue'
import Warning from '../../../components/alert/WarningComponent.vue'

export default {
  name: "detailsMember",
  components: {
    ChangeDateComponent,
    ChangeBirthDateComponent,
    wardprocess,
    ThailandCarBook,
    IdCardComponent,
    Loading,
    Requrie,
    Warning,
  },
  data() {
    return {
      title: '[ ออกกรมธรรม์ใหม่ ]',
      featuresOpen: false,
      showPersonal: true,
      showAddress: true,
      showVehicle: true,
      showPremium: true,
      insuranceDetail: this.$route.params,
      carNo1: '',
      carNo2: '',
      
      birthDate: '',
      radioNation : '',
      masterLicenseType: 'มีทะเบียน',
      showConfirm: false,
      disableNationality: true,
      houseAddress: false,
      newInsurance: true,
      formData: this.resetFormData(),
      errorInput: [],
      loading: false,
      requrie: false,
      warning: {
        show: false,
        msg: '',
        icon: ''
      },
      btnConfirm: true,
      statusCarNo1: false,
      statusCarNo2: false,
      placeholderCarNo1: 'หมวดตัวเลข'
    };
  },
  computed: {
    ...mapState([
      'agent',
      'url',
      'province',
      'district',
      'district_2',
      'sub_district',
      'sub_district_2',
      'country',
      'customer_card',
      'prefix',
      'person_type',
      'nationality',
      'nationality_type',
      'car_type',
      'car_band',
      'license_type',
      'policy_no'
    ]),
    ...mapGetters([
      'getDate',
      'getCarNo',
      'getDateYMD',
      'findIdProvince',
      'findIdDistrict',
      'findIdSubDistrict',
      'setFormatDateMMM',
      'findCarBrand',
      'dateNow',
      'numberFormatToText'
    ]),

  },
  watch: {
      policy_no() {
        this.formData = this.resetFormData(this.agent.agent_id, this.agent.ip)
        this.formData.policy_date = this.dateNow()
        // console.log(this.formData);
        this.newInsurance = true
        this.title = '[ ออกกรมธรรม์ใหม่ ]'
        this.masterLicenseType = 'มีทะเบียน'
      },
    'formData.nation'(val) {
      this.getCustomerCard({nation: val}).then(() => {
        // this.formData.card_type = val != "02" ? '' : this.formData.card_type
        
      })
      if (val == '01') {
          this.formData.person_detail = ''
          this.disableNationality = true
        }else if (val == '03') {
          this.formData.person_detail = ''
          this.disableNationality = true
        }else {
          this.disableNationality = false
      }
    },
    'formData.card_type'(val) {
      if (this.customer_card.length && val) {
        if (this.customer_card.find(data => data.CardID == val)) {
          this.formData.card_detail = this.customer_card.find(data => data.CardID == val).CardName
        }else {
          this.formData.card_detail = ''
        }
      }else {
        console.log(val, 'formData.card_type');
      }
    },
    'formData.p_tumbol'(val) {
      // get zipCode
      if(this.sub_district.length && val) {
        let post_code = this.sub_district.find(data => data.DistrictId == val)
        this.formData.p_zipcode = post_code.Zipcode
      }
    },
    'formData.id_card'(val, oldVal) {
      let val_text = val+''
      if (val_text.length > 13) {
        this.formData.id_card = oldVal
      }
    },
    'formData.telephone_no'(val, oldVal) {
      console.log(val);
      let val_text = val+''
      if (val_text.length > 10) {
        this.formData.telephone_no = oldVal
      }
    },
    'formData.n_telephone_no'(val, oldVal) {
      let val_text = val+''
      if (val_text.length > 10) {
        this.formData.telephone_no = oldVal
      }
    },
    'formData.p_zipcode'(val, oldVal) {
      let val_text = val+''
      if (val_text.length > 5) {
        this.formData.p_zipcode = oldVal
      }
    },
    'formData.n_zipcode'(val, oldVal) {
      let val_text = val+''
      if (val_text.length > 5) {
        this.formData.n_zipcode = oldVal
      }
    },
    'formData.n_tumbol'(val) {
      // get zipCode
      if(this.sub_district_2.length && val) {
        let post_code = this.sub_district_2.find(data => data.DistrictId == val)
        this.formData.n_zipcode = post_code.Zipcode
      }
    },
    'formData.birth_date'(val, oldVal) {
      if (!val) {
        this.formData.birth_date = oldVal
      }
    },
    'formData.p_total'(val) {
      if (val == 0) {
        this.btnConfirm = true
      }
    },
    masterLicenseType(val) {
      this.carNo1 = ''
      this.placeholderCarNo1 = ''

      switch (val) {
        case 'ตราโล่':
          this.statusCarNo2 = true
          this.statusCarNo1 = false
          this.carNo2 = 'ตราโล่'
          this.placeholderCarNo1 = 'หมวดตัวเลข'
         console.log(this.$route.params, 'ตราโล่');
          break;
        case "ป้ายแดง":
          this.statusCarNo1 = true
          this.statusCarNo2 = true
          this.carNo2 = 'ป้ายแดง'
          break;
        case 'ไม่มีทะเบียน':
          this.statusCarNo1 = true
          this.statusCarNo2 = true
          this.carNo2 = 'ไม่มีทะเบียน'
          break;
        default:
          this.statusCarNo1 = false
          this.statusCarNo2 = false
          this.carNo2 = ''
          this.placeholderCarNo1 = 'หมวดตัวเลข'
          break;
      }
    },
    
    houseAddress(val) {
      if (val && this.formData.n_changwat && !this.$route.params.n_changwat) {
        this.getDistrict2({province_id: this.formData.n_changwat})
        this.getSubDistrict2({province_id: this.formData.n_changwat, city_id: this.formData.n_amphur})
      }
      if(val == false) {
        this.formData.n_address = ''
        this.formData.n_changwat = ''
        this.formData.n_amphur = ''
        this.formData.n_tumbol = ''
        this.formData.n_zipcode = ''
        this.formData.n_country = ''
      }else {
        this.formData.n_country = "Thailand"
      }
    },
    carNo1(val) {
      this.formData.car_no = val ? this.carNo2 + ' ' + val : this.carNo2
      // alert(val)
    },
    carNo2(val) {
      this.formData.car_no = this.carNo1 ? val + ' ' + this.carNo1 : val
      // alert(val)
    },
  },
  mounted() {
    window.scrollTo(0, 0);
  },
  
  created() {
    
    // authLine.start();
    // alert(authLine.getTextLine());
    this.showAlertwarning();

    authLine.start().then(() =>{
      // alert(res);
      // let a = true
       if (authLine.getAgentStatus() == 1) {
         this.getAgent({}).then(() => {
          // alert(res);
        })

        if (!this.province.length || !this.prefix.length || !this.car_type.length) {
          this.masterData().then(() => {
          })
        }

        if (!this.$route.query.policy && !this.$route.params.agent_id) {
          this.setPolicyNo('')
          this.getCustomerCard({nation: this.formData.nation ?  this.formData.nation : "03"}).then(() => {

          })
        }
        if (this.$route.params.agent_id) {
          console.log(this.$route.params);
          this.formData =  this.$route.params
          let car_no = this.$route.params.car_no
          
          this.formData.policy_date = this.dateNow()
          if (this.$route.params.n_changwat) {
            this.houseAddress = true
          }

          switch (car_no.split(' ')[0]) {
            case 'ตราโล่':
            case 'ป้ายแดง':
            case 'ไม่มีทะเบียน':
              this.masterlicensetype(car_no.split(' ')[0]).then(() => {
                this.carno1(car_no.split(' ')[1]).then(() => {
                  this.carno2(car_no.split(' ')[0]).then(() => {
                  })
                })
              })
              // this.masterLicenseType = car_no.split(' ')[0]
              // this.carNo1 = car_no.split(' ')[1]
              // this.carNo2 = car_no.split(' ')[0]
              break
            default:
              this.masterlicensetype('มีทะเบียน').then(() => {
                this.carno1(car_no.split(' ')[1]).then(() => {
                  this.carno2(car_no.split(' ')[0]).then(() => {
                  })
                })
              })
              // this.masterLicenseType = 'มีทะเบียน'
              // this.carNo1 = car_no.split(' ')[1]
              // this.carNo2 = car_no.split(' ')[0]
              break
          }

          this.getPolicyNo().then(res => {
            if (res ) {
              this.title = '[ ต่ออายุ ]'
            }else {
              this.title = '[ ออกกรมธรรม์ใหม่ ]'
            }
          })
        }else {
          this.getPolicyNo().then(res => {
              if (res ) {
                this.title = '[ ต่ออายุ ]'
                this.getInsuranceDetail()
              }
          })
        }

        this.formData.agent_id = this.agent.agent_id
        this.formData.ip = this.agent.ip
        this.formData.policy_date = this.dateNow()

        
        if (this.policy_no) {
          this.newInsurance = false
        }
      } else {
        this.$swal.fire({
                          icon: 'error',
                          text: 'กรุณาติดต่อเจ้าหน้าที่',
                          // showCloseButton: true,
                          allowOutsideClick: false,
                          customClass: {
                            container: 'p-4',
                            confirmButton: 'btn btn-primary px-5 py-1',
                          },
                          buttonsStyling: false
                        }).then((result) => {
                          if (result.isConfirmed) {
                              if (authLine.getAgentStatus() == 2) {
                                    authLine.closeWindow(); 
                              } else {
                                    authLine.changeRichMenu();
                              }
                          }
                        });
      }
      
        
        // set date
        console.log('createed');
    }).catch((err) =>{
      // alert(err);
      console.log(err);
    });
    
  },
  methods: {
    ...mapActions(['getAgent', 'getAddress', 'getDistrict', 'getSubDistrict', 'getDistrict2', 'getSubDistrict2', 'getPersonal', 'getVehicle', 'getPolicyNo', 'setPolicyNo', 'getCustomerCard']),
    async masterData() {
          await this.getAddress();
          await this.getPersonal();
          await this.getVehicle();
    },
    masterlicensetype(val) {
      return new Promise((resolve) => {
        this.masterLicenseType = val
        resolve(val)
      })
    },
    carno1(val) {
      return new Promise((resolve) => {
        this.carNo1 = val
        resolve(val)
      })
    },
    carno2(val) {
      return new Promise((resolve) => {
        this.carNo2 = val
        resolve(val)
      })
    },
    async getInsuranceDetail () {
      if (this.policy_no) {
        this.loading = true
        await EventService.post('/insurance/get_insurance_detail', {
          "agent_id": this.agent.agent_id,
          "policy_no": this.policy_no,
        }).then(res => {
          if (res.status) {
            this.getFormData(res.data)
          }
          this.loading = false
        }).catch(error =>{
          console.log(error);
        })
      }
    },
    checkValEngineSize() {
      if(this.checkVal()) {
        return
      }else {
        this.formData.engine_size = ''
      }
    },
    checkVal() {
      if (this.car_type.length) {
        const carTypeId = this.car_type.find(data => data.CartTypeID === this.formData.p_car_type)
        const carRange = carTypeId.CarRange.split('-')
        if (carRange.length == 2) {
          if (this.formData.engine_size >= carRange[0] * 1 && this.formData.engine_size <= carRange[1] * 1){
            return true
          }else {
            return false
          }
        }
      }
      return false
    },
    async getFormData(insuranceDetail) {
      console.log(insuranceDetail);
      this.carNo1 = insuranceDetail.CarNo1
      this.carNo2 = insuranceDetail.CarNo2
      
      let start_date = this.getDate(insuranceDetail.RENEWSTARTDATE)
      let end_date = this.getDate(insuranceDetail.RENEWENDDATE)

      await this.getCustomerCard({nation: insuranceDetail.NationType}).then(() => {
        
      })

      this.formData = {
          agent_id:  this.agent.agent_id,
          // insuranceDetail.PersonType
          person_type: '01',
          person_detail: insuranceDetail.NationalityOTH,
          nation: insuranceDetail.NationType,
          title_name: insuranceDetail.TitleName,
          first_name: insuranceDetail.FirstName,
          sure_name: insuranceDetail.SurName,
          addr: insuranceDetail.Addr,
          telephone_no: insuranceDetail.TelePhoneNo,
          p_tumbol: insuranceDetail.Ptumbol,
          p_amphur: insuranceDetail.Pamphur,
          p_changwat: insuranceDetail.Pchangwat,
          country: insuranceDetail.CountryName,
          p_zipcode: insuranceDetail.PZipCode,
          p_premium: insuranceDetail.PPremium,
          p_stamp: insuranceDetail.PStamp,
          p_vat: insuranceDetail.PVat,
          p_total: insuranceDetail.PTotal,
          car_tank_no: insuranceDetail.CarTankNo,
          car_no: insuranceDetail.CarNo2+' '+insuranceDetail.CarNo1,
          car_changwat: insuranceDetail.CarChangwat,
          start_date: start_date.day+'/'+start_date.month+'/'+start_date.year,
          end_date:  end_date.day+'/'+end_date.month+'/'+end_date.year,
          policy_date: this.dateNow(),
          engine_size: insuranceDetail.EngineSize,
          p_car_type: insuranceDetail.PCarType,
          marque: insuranceDetail.CarName,
          id_card: insuranceDetail.IDCard,
          ip: this.agent.ip,
          card_type: insuranceDetail.CardType,
          card_detail: insuranceDetail.CardDetail,
          birth_date: insuranceDetail.BirthDate ? insuranceDetail.BirthDate : this.formData.birth_date,
          n_address: insuranceDetail.NAddr,
          n_tumbol: insuranceDetail.NTumbol,
          n_amphur: insuranceDetail.NAmphur,  
          n_changwat: insuranceDetail.NChangwat,
          n_zipcode: insuranceDetail.NZipCode,
          n_country: insuranceDetail.NCountry,
          n_telephone_no: insuranceDetail.NTelephoneNo,
          popupid: insuranceDetail.popupid,
          p_amt_date: "0"
      }

      this.birthDate = insuranceDetail.BirthDate
      
      if (insuranceDetail.CarChangwat && this.province.length) {
        this.formData.car_changwat = this.province.find(data => data.ChangwatShortName == insuranceDetail.CarChangwat).ProvinceId
      }

      await this.getDistrict({province_id: insuranceDetail.Pchangwat}).then(() => {
        // console.log(res, 'getDistrict');
      })
      this.getSubDistrict({province_id: insuranceDetail.Pchangwat, city_id: insuranceDetail.Pamphur})

      if (insuranceDetail.Nchangwat) {
        this.houseAddress = true
        await this.getDistrict_2({province_id: insuranceDetail.Nchangwat}).then(() => {
          // console.log(res, 'getDistrict');
        })
        this.getSubDistrict_2({province_id: insuranceDetail.Nchangwat, city_id: insuranceDetail.Namphur})
      }

    },
    // ocr personal
    async setPersonal(val) {
      if (val.status) {
        this.formData.card_type = '01'
        this.formData.id_card = val.data.id_number.replace(/ /g, "")
        this.formData.title_name = val.data.title_th
        this.formData.first_name = val.data.first_name_th
        this.formData.sure_name = val.data.last_name_th
        this.formData.birth_date = val.data.date_of_birth_en
        this.formData.addr = val.data.address_th

        this.formData.birth_date = this.setFormatDateMMM(val.data.date_of_birth_en)

        this.formData.p_changwat = await this.findIdProvince(val.data.province.split('.')[1]).then(res => {return res.ProvinceId})
        const province_id = await this.findIdProvince(val.data.province.split('.')[1]).then(res => {return res.ProvinceId})

        const district_id =  await this.getDistrict({province_id: province_id}).then(() =>{
          return this.findIdDistrict(val.data.district.split('.')[1]).CityId
        })
        this.formData.p_amphur = district_id

        const sub_district_id = await this.getSubDistrict({province_id: province_id, city_id: district_id}).then(() =>{
          return this.findIdSubDistrict(val.data.sub_district.split('.')[1]).DistrictId
        })
        this.formData.p_tumbol = sub_district_id
      }

    },
    // ocr vehicle
    async setVehicle(val) {
      this.formData.engine_size = val.data.vehicle_displacement
      if (this.findCarBrand(val.data.vehicle_brand)) {
        this.formData.marque = this.findCarBrand(val.data.vehicle_engine_brand).CarEngName
      }
      this.formData.car_tank_no = val.data.vehicle_engine_number
      let car_no = val.data.vehicle_license_number.split(' ')
      this.carNo1 = car_no[1]
      this.carNo2 = car_no[0]
      this.formData.car_changwat = await this.findIdProvince(val.data.vehicle_license_province).then(res => {return res.ChangwatShortName})
    },
    selectDistrict(province_id) {
      this.getDistrict({province_id: province_id})
      this.formData.p_amphur = '';
      this.formData.p_tumbol = '';
      this.formData.p_zipcode = '';
    },
    selectDistrict2(province_id) {
      this.getDistrict2({province_id: province_id})
      this.formData.n_amphur = '';
      this.formData.n_tumbol = '';
      this.formData.n_zipcode = '';
    },
    selectSubDistrict(sub_district_id) {
      this.getSubDistrict({province_id: this.formData.p_changwat, city_id: sub_district_id})
      this.formData.p_tumbol = '';
      this.formData.p_zipcode = ''
    },
    selectSubDistrict2(sub_district_id) {
      this.getSubDistrict2({province_id: this.formData.n_changwat, city_id: sub_district_id})
      this.formData.n_tumbol = '';
      this.formData.n_zipcode = ''
    },
    changeBirthDay(date) {
      this.formData.birth_date = date
    },
    changeStartDate(date) {
      this.formData.start_date = date
    },
    changeEndDate(date) {
      this.formData.end_date = date
    },
    setPremium(res) {
      this.formData.p_premium = res.APremium
      this.formData.p_stamp = res.AStamp
      this.formData.p_total = res.ATotal
      this.formData.p_vat = res.AVat
      this.formData.p_amt_date = res.AmtDate

      if (res.ATotal > 0) {
        this.btnConfirm = false
      }else {
        this.btnConfirm = true
      }
    },
    async confirm() {
      // this.$router.push({ name: 'confirmPay', params: this.formData})
      if (this.formData.p_total > 0) {
        await this.validateFormData().then(async () => {
          await this.checkValidate().then(() => {
              // console.log(res);
          })
        }).catch(error => {
          console.log(error);
          this.alertValidate()
        })
      }else {
        this.warning = {
          show: !this.warning.show,
          msg: "ไม่สามารทำรายการได้",
          icon: 'warning'
        }
      }
      
    },
    validateFormData() {
      this.errorInput = []
      return new Promise((resolve, reject) => {
        try {
          for (const key in this.formData) {
            if (Object.hasOwnProperty.call(this.formData, key)) {
              // const element = this.formData[key];
              if ((!this.formData[key])) {
                if (key == 'person_detail' && (this.formData['nation'] == '01' || this.formData['nation'] == '03')) {
                  console.log();
                } else if (!this.houseAddress && (key == 'n_address' || key == 'n_amphur'
                || key == 'n_changwat' || key == 'n_country' 
                || key == 'n_telephone_no' || key == 'n_tumbol'|| key == 'n_zipcode')){
                  console.log();
                } else if (key == 'popupid') {
                  console.log();
                } else if (key == 'n_telephone_no') {
                  console.log();
                } else {
                  this.errorInput.push(key)
                  this.alertValidate()
                  reject(key)
                }
              }
            }
          }
          if (this.errorInput.length) {
            // console.log(this.errorInput);
            // alert(this.errorInput.toString());
            // this.alertValidate()
            reject(false) 
          }
          resolve(true)
          
        } catch (error) {
          reject(error)
        }
      })
    },
    checkValidate() {
      return new Promise((resolve) => {

        // this.loading = true
        let startDate = this.getDateYMD(this.formData.start_date)
        let endDate = this.getDateYMD(this.formData.end_date)
        
        EventService.post('/insurance/validate', {
          "agent_id": this.agent.agent_id,
          "start_date": startDate,
          "end_date": endDate,
          "cartankno": this.formData.car_tank_no,
          // "cartankno": "MLHJF7231J5114576",
          "id_card": this.formData.id_card+''
        }).then(res => {
          if (res.status) {
            this.formData.popupid = res.data.PopupID
            // this.loading = false

            if (res.data.Popup == 'Y') {
              if (res.data.PopupType == "confirm") {
                this.$swal.fire({
                  text: res.data.PopupMessage,
                  icon: 'warning',
                  showCancelButton: true,
                  customClass: {
                    container: 'p-4',
                  },
                }).then(async(result) => {
                  if (result.isConfirmed) {
                    await this.convertVal().then(() => {
                      // console.log(this.formData);
                      this.$router.push({ name: 'confirmPay', params: this.formData})
                    }) 
                  }
                })
              } else if (res.data.PopupType == "alert") {
                this.$swal.fire({
                  text: res.data.PopupMessage,
                  icon: 'warning',
                  customClass: {
                    container: 'p-4',
                  },
                })
              } else {
                this.warning = {
                  show: !this.warning.show,
                  msg: res.data.Msg,
                  icon: 'warning'
                }
              }
            }else if(res.data.Popup == 'N') {
              this.loading = false
              this.$router.push({ name: 'confirmPay', params: this.formData})
            }
          }
        }).catch(error =>{
          this.loading = false
          this.$swal.fire({
            title: 'error',
            text: error.message,
        })
          console.log(error);
        })
        resolve()
      })
    },
    convertVal () {
      return new Promise ((resovle) => {
        this.formData.id_card = this.formData.id_card+''
        this.formData.telephone_no = this.formData.telephone_no+''
        this.formData.n_telephone_no = this.formData.n_telephone_no+''
        
        resovle()
      })
    },
    alertValidate() {
      this.requrie = !this.requrie
    },
    errorStatus(key) {
      if (this.errorInput.find(data => data == key)) {
        return true
      }
    },
    resetFormData(agent = "", ip = "1.1.1.1") {
      this.carNo1 = ''
      this.carNo2 = ''
      return  {
        agent_id: agent, 
        nation: "01",
        person_detail: "",
        person_type: "01",
        title_name: "",
        first_name: "",
        sure_name: "",
        addr: "",
        telephone_no: "",
        p_tumbol: "",
        p_amphur: "",
        p_changwat: "",
        country: "Thailand",
        p_zipcode: "",
        p_premium: "",
        p_stamp: "",
        p_vat: "",
        p_total: "",
        car_tank_no: "",
        car_no: "",
        car_changwat: "",
        start_date: "",
        end_date: "",
        policy_date: "",
        engine_size: "",
        p_car_type: "",
        marque: "",
        id_card: "",
        ip: ip,
        card_type: "",
        card_detail: "",
        birth_date: "",
        n_address: "",
        n_tumbol: "",
        n_amphur: "",  
        n_changwat: "",
        n_zipcode: "",
        n_country: "",
        n_telephone_no: "",
        popupid: "",
        p_amt_date: "0"
      }
    },
    showAlertwarning(){
      this.$swal.fire({
       title: 'ขออภัย !!',
       text: 'ยังไม่เปิดใช้งานในเมนูนี้',
       icon: 'warning',
      showCancelButton: false,
      allowOutsideClick: false
    }).then((result) =>{
      if(result.isConfirmed){
        authLine.closeWindow();
      }
    })
    }
  }
};
</script>

<style lanf="scss"  >
  body {
    background-color: #f5f6f6 !important;
  }
  .text-require {
    color: red;
  }
  .errorText {
    border-color: red;

  }
  .slide-fade-enter-active {
    transition: all 0.2s ease;
  }
  .slide-fade-leave-active {
    transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
  }
  .slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active below version 2.1.8 */ {
    transform: translateY(-10px);
    opacity: 0;
  }
</style>
