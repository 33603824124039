<template>
  <tr style="background-color: #d6eee1" v-if="index < count-1">
    <td class="th1">{{ reportData.AgentID }}</td>
    <td class="th">Line</td>
    <td class="th">{{ reportData.LinePolicyAmt }}</td>
    <td class="th">{{ reportData.LinePolicySendMoney }}</td>
  </tr>
  <tr v-if="index < count-1">
    <td class="td"></td>
    <td class="td">ePolicy</td>
    <td class="td">{{ reportData.EpolicyAmt }}</td>
    <td class="td">{{ reportData.EpolicySendMoney }}</td>
  </tr>
</template>

<script>
export default {
  name: "payment",
  props: ["reportData", 'index', 'count'],
  data() {
    return {};
  },
};
</script>

<style lanf="scss" scoped>
.table {
  border: 0.5px solid black;
  font-size: 12px;
}
.th {
  border: 0.5px solid black;
  font-size: 12px;
  text-align: end;
  color: #00bc9c;
  padding: 5px;
}
.th1 {
  border: 0.5px solid black;
  font-size: 12px;
  text-align: end;
  color: black;
  padding: 5px;
}
.td {
  border: 0.5px solid black;
  font-size: 12px;
  text-align: end;
  color: #2ba5eb;
  padding: 5px;
}
.te {
  border-collapse: collapse;
  font-size: 12px;
  text-align: end;
  color: #484fe5;
}
.center {
  display: flex;
}
</style>
