<template>
    <vue-barcode v-if="value" :value="value" tag="img" :options="{ width: 1, height: 80, fontSize: 15, textAlign: 'center' }"></vue-barcode>
</template>

<script>
import VueBarcode from '@chenfengyuan/vue-barcode';
export default {
    props:['barcode'],
    data() {
        return {
            value: this.barcode,
        }
    },
    created() {
        console.log(this.barcode, 'barcode');
    },
    components: {
        VueBarcode
    }
}
</script>

<style lang="">
    
</style>