var check = {
    // checkDecimal : function(val) {
    //     return val.replace(/[^0-9^\.]+/g,'').replace('.','$#$').replace(/\./g,'').replace('$#$','.').replace(/^0+/, '');
    // } ,
    checkNumber : function (val) {
        return val.replace(/[^0-9]/g, '');
    }

};

export { check };