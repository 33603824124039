<template>
  <div class="container set-padding-menu mb-4">
    <div class="row mb-3">
      <div class="col-12 text-center">
        <img src="../../../assets/Group_3530.png" width="200" alt="" />
      </div>
    </div>

    <section><qrcodesuccesspay /></section>
    <section><confirmpaydetail /></section>
    <section><confirmcardetail /></section>
    <section><confirmwarddetail /></section>

    <section>
      <div class="row mt-3 mb-4 justify-content-center">
        <div class="col-12">
          <router-link
            style="text-decoration: none"
            :to="{ name: 'renewHome' }"
          >
            <div class="d-grid gap-2">
              <button
                class="btn btn-lg btn-block"
                style="background-color: #bce0fd; color: #000000"
              >
                กลับหน้าหลัก
              </button>
            </div>
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import qrcodesuccesspay from "../../../components/renew-com/success-pay-qrcode.vue";
import confirmpaydetail from "../../../components/renew-com/confirm-pay-detail.vue";
import confirmcardetail from "../../../components/renew-com/confirm-car-detail.vue";
import confirmwarddetail from "../../../components/renew-com/confirm-ward-detail.vue";

export default {
  name: "successPay",
  components: {
    confirmpaydetail,
    confirmcardetail,
    confirmwarddetail,
    qrcodesuccesspay,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lanf="scss"  >
</style>
