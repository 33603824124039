<template>
  <div class="container">
    <section class="pt-5">
      <div class="card mt-4">
        <div class="card-body">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <h1 for="" class="h1">รายงานการปิดงวด ณ.วันที่ {{ formatDate(Rcloseperiod.CloseDate) }}</h1>
              </div>
            </div>

            <div class="row">
              <div class="col-7">
                <h2 for="" class="h2">จำนวนกรมธรรม์</h2>
                <h3 for="" class="h3">
                  {{ Rcloseperiod.PolicyCount }} กรมธรรม์
                </h3>
              </div>
              <div class="col-5">
                <h2 for="" class="h2">เบี้ยรวม</h2>
                <h3 for="" class="h3">
                  {{ Rcloseperiod.ptotal }}
                </h3>
              </div>
              <div class="col-7">
                <h2 for="" class="h2">เบี้ยนำส่งสุทธิ</h2>
                <h3 for="" class="h3">
                  {{ Rcloseperiod.SendMoney }}
                </h3>
              </div>
              <div class="col-5">
                <h2 for="" class="h2">ค่าคอมสุทธิ</h2>
                <h3 for="" class="h3">
                  {{ Rcloseperiod.SumnetCommission }}
                </h3>
              </div>
              <div class="col-7">
                <h2 for="" class="h2">ค่ากระดาษ</h2>
                <h3 for="" class="h3">
                  {{ Rcloseperiod.charge001 }}
                </h3>
              </div>
              <div class="col-5">
                <h2 for="" class="h2">ค่าเช่า PVR</h2>
                <h3 for="" class="h3">
                  {{ Rcloseperiod.charge002 }}
                </h3>
              </div>
              <div class="col-7">
                <h2 for="" class="h2">ค่าแพ็คเกจ</h2>
                <h3 for="" class="h3">
                  {{ Rcloseperiod.NetFeeAmt }}
                </h3>
              </div>
              <div class="col-5">
                <h2 for="" class="h2">ส่วนเกินแพ็คเกจ</h2>
                <h3 for="" class="h3">
                  {{ Rcloseperiod.NetTrnFeeAmt }}
                </h3>
              </div>
              <div class="col-7">
                <h2 for="" class="h2">ค่าชดเชยกระดาษ</h2>
                <h3 for="" class="h3">
                  {{ Rcloseperiod.charge005 }}
                </h3>
              </div>
              <div class="col-5">
                <h2 for="" class="h2">อื่นๆ</h2>
                <h3 for="" class="h3">
                  {{ Rcloseperiod.charge006 }}
                </h3>
              </div>
            </div>

            <div class="row mt-3">
              <div class="col-7" v-if="formConvertNumber(Rcloseperiod.patPremium) >= '0'">
                <!-- <h4 for="" class="h4">เบี้ยนำส่งรวมสุทธิ (บาท)</h4> -->
                <h4 for="" class="h4">รวมยอดเงินที่ต้องชำระเพิ่ม</h4>
              </div>
              <div class="col-7" v-else>
                <h4 for="" class="h4">รวมยอดเงินที่ชำระเกิน</h4>
              </div>
            </div>
            <div class="row">
              <div class="col-7">
                <h5 for="" class="h5">THB {{ Rcloseperiod.patPremium }}</h5>
              </div>
              <div class="col-5">
                <router-link class="link" :to="{
                  name: 'payment',
                  params: { totalcash: Rcloseperiod.patPremium },
                }">
                  <button class="btn" style="">ชำระเงิน</button>
                </router-link>
              </div>
            </div>
            <h1 class="dashed mt-3"></h1>
            <div class="row mt-2">
              <div class="col-6">
                <label for="" class="h3">รายการชำระล่าสุด</label><br />
              </div>
            </div>
            <reportlastpay v-for="(value,index) in Rcloseperiod.LastPayment" :key="index" :reportStament="value"
              :index="index" :show-list="showList" />
            <div class="row mt-1">
              <div class="col-12">
                <!-- <button class="show-reportall" @click="showListStament">ดูรายการทั้งหมด</button> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="container-fluid mt-3">
        <label class="h5" style="color: #ffb246; font-size: 1.5rem">|</label>
        <label class="h5" style="margin-left: 5px">รายการใบแจ้งยอด</label>
      </div>

      <liststatement v-for="(value, i) in statementList" :key="i" :statementList="value" />
    </section>

    <loading :show="loading" />


  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import EventService from "../../services/EventService";
import reportlastpay from "../../components/SalesManagement/reportlastpay.vue";
import liststatement from "../../components/SalesManagement/list-statement.vue";
import Loading from '../../components/alert/LoadingComponent.vue'


export default {
  name: "reportperiods",
  components: { reportlastpay, liststatement, Loading },
  data() {
    return {
      Rcloseperiod: {},
      statementList: {},
      showList: 1,
      loading: false,
    };
  },
  
  computed: {
    ...mapState(['agent']),
    ...mapGetters(['setFormatDateCloseDate'])
  },
  created() {

    this.getAgent().then(() => {
      console.log(this.agent);
    })
    this.loading = true
    EventService.post("/management/get_period_balance", {
      agent_id: this.agent.agent_id,
    })
      .then((res) => {
        console.log(res.data);
        this.Rcloseperiod = res.data;
        EventService.post("/management/get_statement", {
          agent_id: this.agent.agent_id,
        })
        .then((res) => {
          this.loading = false
          console.log(res.data);
          this.statementList = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
      })
      .catch((error) => {
        console.log(error);
      });

    
  },
  methods: {
    ...mapActions(['getAgent']),
    formatDate(date) {
      if (date) {
        const dt = date.split(' ')
        if (dt.length) {
          return this.setFormatDateCloseDate(dt[0]) 
        }else {
          return ''
        }
        
      }
    },
    formConvertNumber(num) {
      return num.replace(/,/g, '') * 1;
    }
    ,
    showListStament() {
      this.showList = this.Rcloseperiod.LastPayment.length
      console.log(this.Rcloseperiod.LastPayment.length);
    }
  }
};
</script>

<style lanf="scss" scoped >
.h1 {
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  color: #2ba5eb;
}
.h2 {
  font-size: 12px;
  font-weight: bold;
  color: #858585b9;
  text-decoration: none;
  margin-bottom: .2rem;
}
.h3 {
  font-size: 14px;
  font-weight: bold;
  color: black;
}
.h4 {
  font-size: 12px;
  font-weight: bold;
  text-decoration: underline;
  color: #2ba5eb;
}
.h5 {
  font-size: 18px;
  font-weight: bold;
  color: black;
  text-decoration: none;
}
.h6 {
  font-size: 10px;
  font-weight: bold;
  color: #5a5a5a;
  margin: 3px;
}
.h7 {
  font-size: 14px;
  font-weight: bold;
  color: #2ba5eb;
}
.h8 {
  font-size: 10px;
  font-weight: bold;
  color: #5a5a5a;
}
.dashed {
  border-bottom: dashed 2px #858585b9;
}
.show-reportall {
  width: 95px;
  height: 30px;
  color: #6b6b6b;
  background-color: #bce0fd;
  border-radius: 15px 15px 15px 15px;
  font-size: 10px;
  font-weight: bold;
  border: 1px solid #bce0fd;
}
.btn {
  float: right;
  background-color: #095391;
  margin-top: -15px;
  color: #fff;
}
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
</style>
