<template>
  <div class="container set-padding-menu">
    <section>
      <div class="row mt-4 mb-5">
        <div class="ref-title-men">
          <h2><b> ค้นข้อมูลจาก </b></h2>
        </div>
      </div>
    </section>
    <div class="row justify-content-center text-center mt-3">
      <div class="col-6">
        <router-link :to="{ name: 'carRegistration' }">
          <img
            class="set-img-menu ms-md-5"
            src="../../../assets/btn_03_chassis.png"
            alt=""
            width="165"
          />
        </router-link>
      </div>
      <div class="col-6">
        <router-link :to="{ name: 'carBodynumber' }">
          <img
            class="set-img-menu me-md-5"
            src="../../../assets/btn_03_carnumber.png"
            alt=""
            width="165"
          />
        </router-link>
      </div>
      <div class="col-6 mt-3 mt-md-5">
        <router-link :to="{ name: 'IdentityNumber' }">
          <img
            class="set-img-menu ms-md-5"
            src="../../../assets/btn_03_idcardnumber.png"
            alt=""
            width="165"
        /></router-link>
      </div>
      <div class="col-6 mt-3 mt-md-5">
        <router-link :to="{ name: 'PolicyNumber' }">
          <img
            class="set-img-menu me-md-5"
            src="../../../assets/btn_03_policynumber.png"
            alt=""
            width="165"
          />
        </router-link>
      </div>
    </div>

    <!-- <section><coolieref /></section> -->
  </div>
</template>

<script>
// import coolieref from "../../../components/global-com/Cookie.vue";
export default {
  name: "refHome",
  components: {
    // coolieref,
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lanf="scss" scoped >
.ref-title-men {
  text-align: center;
}
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .set-img-menu {
    width: 250px;
    height: 250px;
    text-align: center;
  }
}
</style>
