<template>
    <vue-qrcode :value="value" :tag="mode" :options="{ width: width }"></vue-qrcode>
</template>

<script>
import VueQrcode from '@chenfengyuan/vue-qrcode';

export default {
    props:['qr'],
    data() {
        return {
            width: "100px",
            height: "250px",
            value: this.qr,
            mode: "img",
        }
    },
    components: {
        VueQrcode,
    }
}
</script>

<style lang="">
    
</style>