<template>
  <section>
    <div class="row mt-2">
      <div class="col-12">
        <div class="ward-process-header">
          <h6 class="mt-2" style="font-size:16px"><b> ระยะเวลาประกันภัย 365 วัน</b></h6>
        </div>
        <div class="ward-process-size">
          <div class="ward-process-insize">
            <div class="test">
            </div>
            <table style="width: 90%; padding-top: 20px" class="mx-auto">
              <tr style="padding-top: 20px">
                <td><b>เบี้ยประกัน</b></td>
                <td style="text-align: right ;color:#484FE5"><b>600.00</b></td>
              </tr>
              <tr>
                <td><b>+ อากรแสตมป์</b></td>
                <td style="text-align: right ;color:#484FE5"><b>3.00</b></td>
              </tr>
              <tr>
                <td><b>+ ภาษีมูลค่าเพิ่ม</b></td>
                <td style="text-align: right ;color:#484FE5"><b>42.21</b></td>
              </tr>
              <tr>
                <td style="color: #ff3f42">
                  <u><b>รวมทั้งสิ้น</b> </u>
                </td>
                <td style="text-align: right; color: #ff3f42">
                  <b>645.21</b>
                </td>
              </tr>
            </table>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "wardconfirm",
};
</script>

<style lanf="scss" scoped >
.ward-process-header {
  height: 50px;
  width: 100%;
  background-color: #d6eee1;
  text-align: center;
    padding-top: 10px;
  color: #008e76;
}

.ward-process-size {
  background-color: #d6eee1;
  height: 140px;
}
.ward-process-insize {
  background-color: #ffffff;
  height: 120px;
  margin: 0px 20px;
}
td{
  font-size: 14px;
}
.test{
  height: 17px;
  width: 100px;
}
</style>
