<template>
    <div v-if="showWarning"></div>
</template>

<script>

export default {
    props:['show'],
    data() {
        return {
            showWarning: false,
        }
    },
    watch: {
        show(val) {
            console.log(val);
            this.warning(val.icon, val.msg)
        }
    },
    created() {
    },
    mounted() {
    },
    components: {
    },
    methods: {
        warning(icon, text){
            this.$swal.fire({
                text: text,
                icon: icon,
                showCloseButton: true,
                customClass: {
                    container: 'p-4',
                    confirmButton: 'btn btn-primary px-5 py-1',
                },
                buttonsStyling: false
            })
        },
    }
}
</script>

<style lang="scss" scope>

</style>