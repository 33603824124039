<template>
  <div class="container">
    <section class="pt-5">
      <div class="card mt-4">
        <div class="card-body">
          <div class="row mt-2">
            <div class="col-4 col-sm-2">
              <img class="img-profile" src="../../assets/img-profile.png" alt="" />
            </div>
            <div class="col-8 col-sm-8">
              <h1 for="" class="h1">{{ datadetail.Name }}</h1>
              <h2 for="" class="h2">รหัสตัวแทน : {{ datadetail.AgentID }}</h2>
              <h2 for="" class="h2">
                สังกัด : {{ datadetail.BranchName }} (
                {{ datadetail.BranchID }} )
              </h2>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-12">
              <h2 for="" class="h2">
                สถานะตัวแทน : {{ datadetail.StatusDesc }} ({{
                datadetail.Status
                }})
              </h2>
              <h2 for="" class="h2">
                ระบบการขาย : {{ datadetail.AgentSellTypeDesc }} -
                {{ datadetail.PrintTypeDesc }}
              </h2>
              <h2 for="" class="h2">ประเภทธุรกิจ : {{ datadetail.AgentTypeName }}</h2>
            </div>
          </div>
          <h1 class="dashed"></h1>
          <div class="row mt-3">
            <div class="col-6">
              <h2 for="" class="h2">วงเงินคงเหลือ</h2>
            </div>
            <div class="col-6">
              <label for="" class="h2" style="float: right">วงเงินทั้งหมด</label>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <h3 for="" class="h3">{{ datadetail.Creditline }}</h3>
              <br />
            </div>
            <div class="col-6">
              <h3 for="" class="h3" style="float: right">{{ datadetail.ActualCreditline }}</h3>
            </div>
          </div>
          <div class="col-12">
            <div class="progress">
              <div class="progress-bar" role="progressbar" :style="{width: actualCreditlinePercent}" aria-valuenow="10"
                aria-valuemin="0" :aria-valuemax="datadetail.Creditline"></div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-6">
              <h2 for="" class="h2">ยอดเงินชำระล่าสุด (บาท)</h2>
            </div>
            <div class="col-6">
              <h2 for="" class="h2" style="float: right">
                วันที่ - เวลาที่ชำระล่าสุด
              </h2>
            </div>
          </div>

          <div class="row">
            <div class="col-4">
              <h4 for="" class="h4">{{ datadetail.LastPayAmount }}</h4>
            </div>
            <div class="col-8">
              <h4 for="" class="h4" style="float: right">
                {{ datadetail.LastPayDate2 }}
              </h4>
            </div>
          </div>

          <div class="row mt-3">
            <div class="col-6">
              <h2 for="" class="h2">ช่องทางการชำระล่าสุด</h2>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
              <h4 for="" class="h4">{{ datadetail.LastPayDate }}</h4>
            </div>
          </div>
          <router-link class="link" :to="{ name: 'reportperiod' }" style="text-decoration: none">
            <div class="row mt-3 justify-content-center" v-if="checkAgentId(agent.agent_id) == '000'">
              <div class="col-8">
                <div class="d-grid gap-2">
                  <button class="btn btn-success">
                    รายงานการปิดยอด
                    <i style="float: right; padding-top: 5px" class="fas fa-angle-right"></i>
                  </button>
                </div>
              </div>
            </div>
          </router-link>
        </div>
      </div>

      <loading :show="loading" />

    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import EventService from "../../services/EventService";
import Loading from '../../components/alert/LoadingComponent.vue'


export default {
  name: "detailpayments",
  data() {
    return {
      datadetail: {},
      actualCreditlinePercent: "0%",
      loading: false,
    };
  },
  components: {
    // detailpayment,
    Loading
  },

  computed: {
    ...mapState(['agent']),
    ...mapGetters(['checkAgentId'])
  },
  created() {
    
    this.getAgent()
    this.loading = true
    EventService.post("/management/get_credit_balance", {
      agent_id: this.agent.agent_id,
    })
      .then((res) => {
        this.loading = false
        console.log(res.data);
        this.datadetail = res.data;
        this.actualCreditlinePercent = (this.datadetail.ActualCreditline*1 / this.datadetail.Creditline * 100)+"%"
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    ...mapActions(['getAgent'])
  }
};
</script>

<style lanf="scss" scoped >
.h1 {
  font-size: 16px;
  font-weight: bold;
}
.h2 {
  font-size: 11px;
  font-weight: bold;
  color: #5a5a5a;
  line-height: 0.5rem;
}
.h3 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: -20px;
}
.h4 {
  font-size: 14px;
  font-weight: bold;
}
.dashed {
  border-bottom: dashed 2px #858585b9;
}
.btn {
  background-color: #00bc9c;
  border-color: #00bc9c;
}
.progress-bar {
  background-color: #00bc9c;
}
.img-profile{
  width: 80px;
  height: 80px;
}
</style>
