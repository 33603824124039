<template>
    <div class="row">
        <div class="form-group">
            <label for=""><b><slot></slot></b> <b class="text-require"> *</b></label>
            <div class="row" style="--bs-gutter-x: 0.2rem;">
                <div class="col-3">
                    <select name="" id="" class="form-select" v-model="s_day" @change="change()">
                    <option v-for="value in day" :key="value" :value="value">{{ value }}</option>
                    </select>
                </div>
                <div class="col-5">
                    <select name="" id="" class="form-select" v-model="s_month" @change="change()">
                    <option v-for="value in month" :key="value.id" :value="value.id">{{ value.name }}</option>
                    </select>
                </div>
                <div class="col-4">
                    <select name="" id="" class="form-select" v-model="s_year" @change="change()">
                    <option v-for="i in loop" :key="i" :value="year +1 -i">{{ year +1 -i }}</option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    props: ['date'],
    emits: ['update:change-brithdays'],
    data() {
        return {
            day: '',
            month: '',
            year: '',

            s_day:'',
            s_month:'',
            s_year: '',

            convertDate: '',

            loop: 70,
        }
    },
    computed:{
        ...mapGetters([
            'getDay',
            'getMonth',
            'getYearOfbirth',
            'getDateOfBirth'
        ]),
    },
    watch: {
        convertDate(val) {
            this.s_day = val.day;
            this.s_month = val.month;
            this.s_year = val.year;
        },
        date(val, oldVal) {
            if (val != oldVal && val) {
                this.convertDate = this.getDateOfBirth(val)
            }
        },
    },
    created() {
        this.convertDate = this.getDateOfBirth(this.date)
        this.month = this.getMonth();
        this.year = this.getYearOfbirth();
        this.s_year = this.getYearOfbirth();
        this.day = this.getDay(this.s_month+'+'+(this.s_year - 543))
    },
    mounted() {
        this.convertDate = this.getDateOfBirth(this.date)
        this.$emit('change-brithdays', (this.s_day+'/'+this.s_month+'/'+this.s_year))
    },
    methods: {
        change() {
            this.day = this.getDay(this.s_month+'+'+(this.s_year - 543))
            this.$emit('change-brithdays', (this.s_day+'/'+this.s_month+'/'+this.s_year))
        }
    }
}
</script>