<template>
  <section>
    <div class="card mb-4">
      <div
        class="card-body"
        style="border: 3px solid #484fe5; border-radius: 5px"
      >
        <div class="qr-header">
          <div class="row justify-content-center">
            <div class="text-center">
              <img
                src="../../assets/n20171123155516_131196.png"
                alt=""
                width="70"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 pb-3 pt-1">
              <div class="text-center mt-2">
                <h6 class="set-text-header">
                  <b>ใช้สำหรับเป็นหลักฐานในการออกกรมธรรม์</b>
                </h6>
              </div>
            </div>
          </div>

          <div class="row mt-2">
            <div class="col text-center">
              <barcode-generator-component :barcode="barcode" v-if="barcode"></barcode-generator-component>
            </div>
            <div class="col text-center">
              <qr-generator-component :qr="qr" v-if="qr"></qr-generator-component>
            </div>
          </div>

          <div class="row mt-2">
            <div class="text-center">
              <h6 style="margin-bottom: 0">
                <b style="padding-right: 5px">กรมธรรม์เลขที่</b>
                <b><span style="color: #484fe5">{{ policy_no }}</span></b>
              </h6>
            </div>
          </div>
          <div class="row mt-1">
            <div class="text-center">
              <h6>
                <b style="padding-right: 5px">ออกโดยรหัสตัวแทน</b>
                <b><span>{{ agent_id }}</span></b>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="row mt-3 mb-3">
      <div class="col-12 text-center">
        <h6 style="color: #e74d4f">
          <b
            >หากตัวแทนชำระค่าเบี้ยแล้ว<br />สามารถ พิมพ์ตารางกรมธรรม์ได้จากระบบ
            ePolicy</b
          >
        </h6>
      </div>
    </div>
  </section>
  
</template>
<script>
import BarcodeGeneratorComponent from '../barcode-qrcode/BarcodeGeneratorComponent.vue';
import QrGeneratorComponent from '../barcode-qrcode/QrGeneratorComponent.vue';
export default {
  name: "qrcodesuccesspay",
  props:['generatorData'],
  data() {
    return {
      qr: this.generatorData.qr,
      barcode: this.generatorData.barcode,
      agent_id: this.generatorData.agent_id,
      policy_no: this.generatorData.policy_no,
    }
  },
  created() {
    console.log(this.generatorData.qr);
  },
  components: {
    BarcodeGeneratorComponent,
    QrGeneratorComponent
  }
};
</script>

<style lanf="scss" scoped >
.set-text-header {
  color: #484fe5;
  font-size: 14px;
}
</style>
