<template>
    <div v-if="showLoading"></div>
</template>

<script>

export default {
    props:['show'],
    data() {
        return {
            showLoading: false
        }
    },
    watch: {
        show(val) {
            if (val) {
                this.loading()
            }else {
                this.$swal.close()
            }
        }
    },
    created() {
        if (this.show) {
            this.loading()
        }
    },
    mounted() {
    },
    components: {
    },
    methods: {
        loading(){
            this.$swal.fire({
                didOpen: () => {
                    this.$swal.showLoading()
                },
                margin: 0,
                backdrop: false,
                background: 'rgba(0,0,0,.4)',
                grow: 'fullscreen',
                customClass: {
                    container: 'p-0'
                },
                showClass: {
                    popup: 'animate__animated animate__fadeInDown'
                },
                hideClass: {
                    popup: 'animate__animated animate__fadeOutUp'
                }
            })
        },
    }
}
</script>

<style lang="scss" scope>

</style>