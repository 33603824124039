<template>
<div class="container bg-white">
  <div class="pt-5">
      <div class="row mt-3 pt-1 border-top">
          <div class="col-3 d-flex align-items-center">
              <img
                src="../../assets/n20171123155516_131196.png"
                alt=""
                width="90"
              />
          </div>
          <div class="col">
              <p class="fw-bold m-0" style="font-size: 10px;">บริษัท กลางคุ้มครองผู้ประสบภัยจากรถ จำกัด (สำนักงานใหญ่)</p>
              <p class="m-0" style="font-size: 10px; line-height: 1">
                เลขที่ 26 ซอยสุขุมวิท 64/2 ถนนสุขุมวิท แขวงพระโขนงใต้ เขตพระโขนง
                กรุงเทพมหานคร 10260 โทรศัพท์ 0-2100-9191 โทรสาร 0-2396-2093-94
                www.rvp.co.th เลขประจำตัวผู้เสียภาษี 0105541011867
              </p>
          </div>
          <div class="col-auto d-flex align-items-center">
             <p class="text-center border border-dark" style="font-size: 8px; padding: 2px">
              ใบแจ้งยอด<br>บัญชี<br>การรับประกัน<br>ภัย
            </p>
          </div>

          <div class="col-12">
            <div class="text-end">
              <vue-barcode v-if="barcode" :value="barcode" tag="img" :options="{ width: 1.5, height: 30, fontSize: 10, textAlign: 'center' }"></vue-barcode>
            </div>
          </div>
      </div>

      <div class="row justify-content-end">
        
      </div>

      <div class="row">
        <div class="col-4">
          barcode
        </div>
        <div class="col">
          <table class="table table-bordered text-center">
            <tr class="">
              <td class="t-agent fw-bold border border-dark">รหัสตัวแททน</td>
              <td class="t-agent fw-bold border border-dark">วันที่ปิดยอด</td>
              <td class="t-agent fw-bold border border-dark">วันที่ออกใบแจ้งยอดๆ</td>
            </tr>
            <tr class="">
              <td class="border border-dark">{{ }}1</td>
              <td class="border border-dark">{{ }}1</td>
              <td class="border border-dark">{{ }}1</td>
            </tr>
             <tr class="">
              <td class="t-agent fw-bold border border-dark">รวมยอดเงินที่ต้องชำระเพิ่ม</td>
              <td class="t-agent fw-bold border border-dark">วันที่กำหนดชำระเงิน</td>
              <td class="t-agent fw-bold border border-dark">ประจำงวดที่</td>
            </tr>
            <tr class="">
              <td class="border border-dark">{{ }}1</td>
              <td class="border border-dark">{{ }}1</td>
              <td class="border border-dark">{{ }}1</td>
            </tr>
          </table>
        </div>
      </div>

    <div class="pb-3">
      <table class="w-100">
        <thead class="text-center fw-bold" style="background-color: #ccc">
          <td class="border-1">
            วันที่ทำรายการ <br>
            Transaction <br>
            Date
          </td>
          <td class="border-1">
            วันที่บันทึก<br>
            Posting Date
          </td>
          <td class="border-1">
            รหัสตัวแทน <br>
            Agent Code
          </td>
          <td class="border-1">
            รายการ <br>
            Decription
          </td>
          <td class="border-1">
            จำนวนเงิน<br>
            Amount (Baht)
          </td>
        </thead>
        <tbody class="text-center">
          <tr class="fw-bold">
            <td class="pt-2" colspan="3">ใบแจ้งยอดบัญชีการรับประกันภัย ณ</td>
            <td></td>
            <td class="text-end">{{ }}</td>
          </tr>
          <tr>
            <td>1</td>
            <td>2</td>
            <td>3</td>
            <td>4</td>
            <td class="text-end">5</td>
          </tr>
        </tbody>
        <tfoot class="fw-bold text-center">
          <tr class="border-top border-bottom border-dark">
            <td class="pt-1 pb-1" colspan="3"></td>
            <td class="pt-1 pb-1">รวมยอดเงินที่ต้องชำระเพิ่ม</td>
            <td class="text-end pt-1 pb-1">11</td>
          </tr>
          
        </tfoot>
      </table>
    </div>
    <!-- <div>
      ddd
    </div> -->
      

  </div>
</div>
</template>

<script>
import VueBarcode from '@chenfengyuan/vue-barcode';

export default {
  name: "statements",
  components: {
    VueBarcode
  },
  data() {
    return {
      barcode: "1111111111"
    }
  },
};
</script>

<style lanf="scss" scoped >
td {
   font-size:10px;
}
.t-agent {
  font-size:10px;
}
</style>
