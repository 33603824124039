import { createRouter, createWebHistory } from "vue-router";
// Renew
import Home from "../views/renew_Home.vue";
import detailsMember from "../views/Renew/BarcodeorQrcode/details-member.vue";
import confirmPay from "../views/Renew/BarcodeorQrcode/confirm-pay.vue";
import successPay from "../views/Renew/BarcodeorQrcode/success-pay.vue";
import refSearch from "../views/Renew/ref/ref-home.vue";

import carRegistration from "../views/Renew/ref/carRegistration.vue";
import carBodynumber from "../views/Renew/ref/carBodynumber.vue";
import IdentityNumber from "../views/Renew/ref/IdentityNumber.vue";
import PolicyNumber from "../views/Renew/ref/PolicyNumber.vue";
import QrCode from "../views/Renew/ref/QrCode.vue";

// buynew
// import detailsBuynew from "../views/Buynew/details-new.vue";
import ConfirmData from "../views/Buynew/details-confirm-data.vue";
import DetailsSucceed from "../views/Buynew/details-succeed.vue";

//login
import pagelogin from "../views/login/login.vue";

//report
import reportsales from "../views/SalesManagement/reportsales.vue";
import payment from "../views/SalesManagement/payment.vue";
import detailpayment from "../views/SalesManagement/detailpayment.vue";
import qrbarpaymentSacn from "../views/SalesManagement/QR-BARpayment-scan.vue";
import reportListMenu from "../views/SalesManagement/reportListMenu.vue";
import reportperiod from "../views/SalesManagement/reportperiod.vue";
import statement from "../views/SalesManagement/statement.vue";

//QRcode scanner
import qrcodescaner from "../views/Renew/BarcodeorQrcode/QR-scanner.vue";

//BARcode scanner
import barcodescaner from "../views/Renew/BarcodeorQrcode/BAR-scanner.vue";

// Servat

import servayhead from "../views/servay/servat-head.vue";

import TakePhoto from "../views/Renew/BarcodeorQrcode/SingleImg.vue";
import storyTo from "../views/Story/story.vue";

const routes = [
  // Router Renew
  {
    path: "/",
    name: "renewHome",
    component: Home,
  },
  {
    path: "/insurance",
    name: "detailsMember",
    component: detailsMember,
  },
  {
    path: "/confirm/insurance",
    name: "confirmPay",
    component: confirmPay,
  },
  {
    path: "/renew/successPay",
    name: "successPay",
    component: successPay,
  },
  {
    path: "/renew/refSearch",
    name: "refSearch",
    component: refSearch,
  },
  {
    path: "/renew/refSearch/carRegistration",
    name: "carRegistration",
    component: carRegistration,
  },
  {
    path: "/renew/refSearch/carBodynumber",
    name: "carBodynumber",
    component: carBodynumber,
  },
  {
    path: "/renew/refSearch/IdentityNumber",
    name: "IdentityNumber",
    component: IdentityNumber,
  },
  {
    path: "/renew/refSearch/PolicyNumber",
    name: "PolicyNumber",
    component: PolicyNumber,
  },
  {
    path: "/search-policy-no",
    name: "searchByPolicyNo",
    component: QrCode,
  },

  {
    path: "/renew/qrcodescaner",
    name: "qrcodescaner",
    component: qrcodescaner,
  },

  {
    path: "/renew/barcodescaner",
    name: "barcodescaner",
    component: barcodescaner,
  },

  // Router Buynew
  {
    path: "/buynew/detailsBuynew",
    name: "detailsBuynew",
    component: detailsMember,
  },
  {
    path: "/buynew/ConfirmData",
    name: "ConfirmData",
    component: ConfirmData,
  },
  {
    path: "/buynew/DetailsSucceed",
    name: "DetailsSucceed",
    component: DetailsSucceed,
  },
  //Router login
  {
    path: "/login",
    name: "pagelogin",
    component: pagelogin,
    meta: {
      hideNavbar: true,
    },
  },
  //Router report
  {
    path: "/report/reportsales",
    name: "reportsales",
    component: reportsales,
  },
  {
    path: "/report/payment:totalcash",
    name: "payment",
    component: payment,
  },

  {
    path: "/report/qrbarpaymentSacn/:cash/:payment/:type",
    name: "qrbarpaymentSacn",
    component: qrbarpaymentSacn,
  },
  {
    path: "/report/detailpayment",
    name: "detailpayment",
    component: detailpayment,
  },
  {
    path: "/report/reportperiod",
    name: "reportperiod",
    component: reportperiod,
  },
  {
    path: "/report/statement",
    name: "statement",
    component: statement,
  },
  {
    path: "/servay",
    name: "servayhead",
    component: servayhead,
  },

  {
    path: "/renew/takephoto",
    name: "takephoto",
    component: TakePhoto,
  },

  {
    path: "/report/list/menu",
    name: "reportListMenu",
    component: reportListMenu,
  },
  {
    path: "/Story",
    name: "storyList",
    component: storyTo,
  },

  // reportListMenu
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
