<template>
  <section>
    <div class="container set-padding-menu">
      <div class="home-title">
        <div class="row justify-center">
          <div class="col-xs-6 text-center">
            <h3 style="font-weight: 900">เลือกช่องทางการต่ออายุ</h3>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="home-menu-item-1 mt-3">
      <router-link class="link" :to="{ name: 'qrcodescaner' }">
      <div class="row justify-content-center" @click="scanBarcode">
        <div class="col-10 text-center">
          <img
            style="padding-top: 5px"
            src="../assets/btn_01_barcode.png"
            alt=""
            width="270"
          />
        </div>
      </div>
      </router-link>
    </div> -->

    <div class="home-menu-item-2 mt-3">
      <!-- <router-link class="link" :to="{ name: 'qrcodescaner' }"> -->
        <!-- @click="scanQr" -->
      <div class="row justify-content-center" @click="scanQr" >
        <div class="col-10 text-center">
          <img
            style="padding-top: 5px"
            src="../assets/btn_01_qrcode.png"
            alt=""
            width="270"
          />
        </div>
      </div>
      <!-- </router-link> -->
    </div>

    <div class="home-menu-item-3 mt-3">
      <router-link class="link" :to="{ name: 'refSearch' }">
        <div class="row justify-content-center">
          <div class="col-10 text-center">
            <img
              style="padding-top: 5px"
              src="../assets/btn_01_ref.png"
              alt=""
              width="270"
            />
          </div>
        </div>
      </router-link>
    </div>

    <!-- <warning :show="warning"/> -->
  </section>
</template>


<script>
import { mapActions, mapState } from "vuex";
import { authLine } from "./js/setDefault";


export default {
  name: "Home",
  components: {
    
  },
  data() {
    return {
      a: 1,
      statusShowQR : false,
      name_id : 'scanQr',

    };
  },
  computed: {
    ...mapState(["agent"]),
    // get only
    aDouble() {
      return this.a * 2;
    },
    // both get and set
    aPlus: {
      get() {
        return this.a + 1;
      },
      set(v) {
        this.a = v - 1;
      },
    },
  },
  created() {
 
    authLine.start().then(() =>{
      // alert(res);

      // authLine.getAgentStatus()
      if (authLine.getAgentStatus() == 1) {
          this.getData()
          this.$watch("a", () => {
            console.log("Article changed!");
          });

          this.$watch("comments", () => {
            console.log("Comments changed!");
          });
          // authLine.scanQr();

          const q = decodeURIComponent(window.location.search);
          const p = new URLSearchParams(q);
          if (p.get("param") !== null) {
            // alert(p.get("param"));
            console.log(p.get("param"));
          }
      } else {
        this.$swal.fire({
                          icon: 'error',
                          text: 'กรุณาติดต่อเจ้าหน้าที่',
                          // showCloseButton: true,
                          allowOutsideClick: false,
                          customClass: {
                            container: 'p-4',
                            confirmButton: 'btn btn-primary px-5 py-1',
                          },
                          buttonsStyling: false
                        }).then((result) => {
                          if (result.isConfirmed) {
                              if (authLine.getAgentStatus() == 2) {
                                    authLine.closeWindow(); 
                              } else {
                                    authLine.changeRichMenu();
                              }
                          }
                        });
      }

    
    }).catch((err) =>{
      // alert(err);
      console.log(err);
    });
   
    
    

  },
  mounted() {
    this.showAlertwarning();
  },
  methods: {
    ...mapActions(['getAgent']),
    async getData() {
      await this.getAgent({}).then(res => {
        console.log(res);
      })
    },
    changeArticleText() {
      this.article.text = "Vue 3 is awesome";
    },
    addComment() {
      this.comments.push("New comment");
    },

    // These methods will trigger a watcher because we replaced object/array completely
    changeWholeArticle() {
      this.article = { text: "Vue 3 is awesome" };
    },
    clearComments() {
      this.comments = [];
    },
    scanQr() {
      authLine.scanQr().then((data) =>{
      this.$router.push({ name: 'searchByPolicyNo', params: {policy: data.result}})
    }).catch((err) =>{
      alert(err);
    });

    },
    showAlertwarning(){
      this.$swal.fire({
       title: 'ขออภัย !!',
       text: 'ยังไม่เปิดใช้งานในเมนูนี้',
       icon: 'warning',
      showCancelButton: false,
      allowOutsideClick: false
    }).then((result) =>{
      if(result.isConfirmed){
        authLine.closeWindow();
      }
    })
    }
  },
};
</script>

<style lang="scss" scoped>
.home-title {
  padding-top: 30px;
  font-weight: 900;
}

.home-title h3 {
  font-size: 30px;
}
.home-menu-item-1 {
  width: 300px;
  height: 76px;
  background-color: #7c62d3;
  margin: auto;
  border-radius: 10px;
}
.home-menu-item-1 p {
  color: white;
  font-size: 30px;
}
.home-menu-item-1 i {
  color: white;
  font-size: 45px;
}
.home-menu-item-2 {
  width: 300px;
  height: 76px;
  background-color: #2ba5eb;
  margin: auto;
  color: #ffffff;
  border-radius: 10px;
  font-size: 30px;
}
.home-menu-item-3 {
  width: 300px;
  height: 76px;
  background-color: #00bc9c;
  margin: auto;
  color: #ffffff;
  border-radius: 10px;
  font-size: 25px;
}

.table {
  display: table;
  width: 100%;
}
.tr {
  display: table-row;
}
.d1 {
  display: table-cell;
  width: 90%;
  text-align: right;
}
.d3 {
  display: table-cell;
  text-align: right;
  width: 25%;
  padding-right: 10px;
}
</style>
