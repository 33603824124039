<template>
    <div class="row">
        <div class="form-group">
            <label for=""><b><slot></slot></b> <b class="text-require"> *</b></label>
            <div class="row" style="--bs-gutter-x: 0.2rem;">
                <div class="col-3">
                    <select name="" id="" class="form-select" v-model="s_day">
                    <option v-for="value in day" :key="value" :value="value">{{ value }}</option>
                    </select>
                </div>
                <div class="col-5">
                    <select name="" id="" class="form-select" v-model="s_month">
                    <option v-for="value in month" :key="value.id" :value="value.id">{{ value.name }}</option>
                    </select>
                </div>
                <div class="col-4">
                    <select name="" id="" class="form-select" v-model="s_year">
                    <option v-for="i in loop" :key="i" :value="year -1 +i">{{ year -1 +i }}</option>
                    </select>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    props: ['date', 'valDate'],
    emits: ['update:ChangeDate'],
    data() {
        return {
            day: '',
            month: '',
            year: '',

            s_day:'',
            s_month:'',
            s_year:'',

            convertDate: '',

            loop: 5,
        }
    },
    computed:{
        ...mapGetters([
            'getDay',
            'getMonth',
            'getYear',
            'getDate'
        ]),
    },
    watch: {
        s_day(val, oldVal) {
            if (val != oldVal) {
                this.$emit('ChangeDate', val+'/'+this.s_month+'/'+this.s_year)
            }
        },
        s_month(val, oldVal) {
            if (val != oldVal) {
                this.day = this.getDay(val+'+'+(this.s_year - 543))
                this.$emit('ChangeDate', this.s_day+'/'+val+'/'+this.s_year)
            }

        },
         s_year(val, oldVal) {
            if (val != oldVal) {
                this.$emit('ChangeDate', this.s_day+'/'+this.s_month+'/'+val)
            }

        },
        convertDate(val) {
            this.s_day = val.day;
            this.s_month = val.month;
            this.s_year = val.year;
        },
        date(val) {
            if (val) {
                this.convertDate = this.getDate(val,  this.valDate)
            }else {
                this.convertDate = this.getDate(val,  this.valDate)
            }
        }
    },
    created() {
        this.convertDate = this.getDate(this.date, this.valDate)
        this.month = this.getMonth();
        this.year = this.getYear();
        this.day = this.getDay(this.s_month+'+'+(this.s_year - 543))
    },
    mounted() {
        console.log('mounted change date');
    },
    methods: {
        change() {
            this.$emit('ChangeDate', (this.s_day+'/'+this.s_month+'/'+this.s_year))
        }
    }
}
</script>