<template>
  <div class="container">
    <section>
      <div class="container set-padding-menu">
        <div class="home-title">
          <div class="row justify-center">
            <img src="../../assets/n20171123155516_131196.png" alt=""
              style="width: 80%;margin: auto;padding-bottom: 3rem;" />
          </div>
        </div>
      </div>

      <div class="row py-4">
        <div class="text-center">
          <router-link :to="{ name: 'reportsales' }" style="padding-top: 1.5rem;padding-bottom: 1.5rem;font-size: 1rem;"
            class="btn btn-primary btn-lg active col-12 colorMenulist01" role="button" aria-pressed="true">
            รายงานยอดขายรายตัวแทน</router-link>
        </div>
      </div>
      <div class="row">
        <div class="text-center col-12">
          <router-link :to="{ name: 'detailpayment'}"
            style="padding-top: 1.75rem;padding-bottom: 1.75rem;font-size: 1rem;width: 48%;"
            class="colorMenulist02 btn btn-primary btn-lg active " role="button" aria-pressed="true">วงเงินเครดิต
          </router-link>
          <router-link :to="{ name: 'reportperiod' }" v-if="checkAgentId(agent.agent_id) == '000'"
            style="padding-top: 1.75rem;padding-bottom: 1.75rem;font-size: 1rem;width: 48%;margin-left:4%;"
            class=" colorMenulist03 btn btn-primary btn-lg active " role="button" aria-pressed="true">สรุปปิดยอดขาย
          </router-link>
        </div>
      </div>
    </section>
  </div>
</template>

<script>

// import statement from '../../components/SalesManagement/statement.vue';
import { authLine } from '../../views/js/setDefault'
import { mapGetters, mapState, mapActions } from 'vuex'
 
export default {
  name: "reportListMenu",
  computed: {
    ...mapState(['agent']),
    ...mapGetters(['checkAgentId'])
  },
  created() {
    this.getAgent();
     authLine.start().then(() =>{
       if (authLine.getAgentStatus() == 1) {
          console.log(authLine.getAgentStatus());
      } else {
        this.$swal.fire({
                          icon: 'error',
                          text: 'กรุณาติดต่อเจ้าหน้าที่',
                          // showCloseButton: true,
                          allowOutsideClick: false,
                          customClass: {
                            container: 'p-4',
                            confirmButton: 'btn btn-primary px-5 py-1',
                          },
                          buttonsStyling: false
                        }).then((result) => {
                          if (result.isConfirmed) {
                              if (authLine.getAgentStatus() == 2) {
                                    authLine.closeWindow(); 
                              } else {
                                    authLine.changeRichMenu();
                              }
                          }
                        });
      }

    
    }).catch((err) =>{
      alert(err);
    });
  },
  methods: {
    ...mapActions(['getAgent'])
  }
};
</script>

<style lanf="scss" scoped >
    
    .colorMenulist01{
        background-color: #6531AD;
    }

    .colorMenulist02{
        background-color: #5550C9;
    }

    .colorMenulist03{
        background-color: #7756D0;
    }
</style>