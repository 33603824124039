<template>

  <section class="single-video">
 
    <div :class="{fullscreen }" ref="wrapper">
      <PhotoCapture styling="max-width=100%" @input="handleDone" />
<button @click="showModal" type="button">Open Modals</button>
    </div>
  </section>
</template>

<script>
import PhotoCapture from "../../../components/Photo/PhotoCapture.vue";
// import { ref } from "vue";
// import ImgList from "../../../components/Photo/ImgList";
// import ImgGallery from "../../../components/Photo/ImgGallery";
export default {
  components: {
    PhotoCapture,
    // ImgList,
    // ImgGallery
  },
  // props: ["modalActive"],


  data() {
    return {
      imgs: [],
      styling:{
        'max-width':'60%'
      },
      modalOpen : false
    };
  },
  created() {
    this.imgs = localStorage.getItem("thumbnails");
    if (!this.imgs) this.imgs = '';
    document.getElementsByTagName("header")[0].remove()
  },
  methods: {
    handleDone(imgBase64) {
      console.log(imgBase64,'handleDone');
      // this.imgs.push(imgBase64);
      // localStorage.setItem("thumbnails", imgBase64);
    },
    clear() {
      // localStorage.clear();
      localStorage.removeItem("thumbnails");
      this.imgs = [];
    },
    showModal(){
      document.getElementById('camera-id').click();
    }

  }
};
</script>

<style lang="scss" scoped>
.photo-capture {
  max-width: 60% !important;
}
// .fullscreen {
//   position: fixed;
//   top: 0;
//   bottom: 0;
//   left: 0;
//   right: 0;
//   z-index: 100;
//   background-color: black;
//   display: flex;
//   flex-flow: column nowrap;
//   justify-content: center;
// }
.single-video {
  // background-color: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  // flex-wrap: wrap;
  height:100vh;
  .galleries-container {
    display: flex;
    // flex-direction:column;
    justify-content: center;
    & > * {
      margin: 0 10px;
      box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
      border:1px solid rgba(65,65,65,0.3);
      height:480px;
    }
    .img-list {
      padding:10px;
      span {
        display: flex;
        align-items: center;
        justify-content: space-around;
        width: 100%;
        font-size: 1.5rem;
      }
    }
  }
}
</style>
