<template>
    <div v-if="showRequrie"></div>
</template>

<script>

export default {
    props:['show'],
    data() {
        return {
            showRequrie: false
        }
    },
    watch: {
        show(val) {
            console.log(val);
            this.requrie()
        }
    },
    created() {
    },
    mounted() {
    },
    components: {
    },
    methods: {
        requrie(){
            this.$swal.fire({
                text: 'กรุณาระบุข้อมูล',
                icon: 'warning',
                showCloseButton: true,
                customClass: {
                    container: 'p-4',
                    confirmButton: 'btn btn-primary px-5 py-1',
                },
                buttonsStyling: false
            })
        },
    }
}
</script>

<style lang="scss" scope>

</style>