<template>
  <div class="container set-padding-menu mb-4">
    <qrcodescanner />
  </div>
</template>

<script>
import Qrcodescanner from "../../../components/renew-com/QRcodescanner.vue";

export default {
  name: "qrcodescaner",
  components: { qrcodescanner: Qrcodescanner },
  data() {},
};
</script>