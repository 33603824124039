<template>
  <section>
    <div class="card mb-4">
      <div
        class="card-body"
        style="border: 3px solid #484fe5; border-radius: 5px"
      >
        <div class="qr-header">
          <div class="row justify-content-center">
            <div class="text-center">
              <img
                src="../../assets/n20171123155516_131196.png"
                alt=""
                width="70"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-12 pb-3 pt-1">
              <div class="text-center pt-2">
                <h6 class="set-text-header">
                  <b>ใช้สำหรับเป็นหลักฐานในการออกกรมธรรม์</b>
                </h6>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="text-center">
              <img
                src="https://via.placeholder.com/275x70

C/O https://placeholder.com/"
                alt=""
              />
            </div>
          </div>
          <div class="row mt-3">
            <div class="text-center">
              <img
                src="https://via.placeholder.com/175x175

C/O https://placeholder.com/"
                alt=""
              />
            </div>
          </div>
          <div class="row mt-4">
            <div class="text-center">
              <h6 style="margin-bottom: 0">
                <b>กรมธรรม์เลขที่</b>
                <b><span style="color: #484fe5"> 12341231234123457</span></b>
              </h6>
            </div>
          </div>
          <div class="row">
            <div class="text-center">
              <h6>
                <b>ออกโดยรหัสตัวแทน</b> <b><span>12341231234123457</span></b>
              </h6>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section>
    <div class="row mt-3 mb-3">
      <div class="col-12 text-center">
        <h6 style="color: red">
          <b
            >หากตัวแทนชำระค่าเบี้ยแล้ว<br />สามารถ พิมพ์ตารางกรมธรรม์ได้จากระบบ
            ePolicy</b
          >
        </h6>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "qrcodesuccessbuynew",
  data() {
    return {
      show: true,
    };
  },
};
</script>

<style lanf="scss" scoped >
.set-text-header {
  color: #484fe5;
  font-size: 14px;
}
</style>
