<template>
  <section class="pt-5">
    <vue3-simple-html2pdf ref="vue3SimpleHtml2pdf" :options="pdfOptions" :filename="exportFilename">
      <div>
        <div class="container mt-4">
          <div class="row mb-2">
            <div class="col-4 col-sm-2">
              <img src="../../assets/n20171123155516_131196.png" alt="" width="120" height="50" />
            </div>
            <div class="col-8 col-sm-8">
              <h1 class="h1" style="line-height: 1.6; margin-bottom: 0.5rem">
                {{ report.RVPName }} {{ report.RVPAddress }} {{ report.RVPTel }}
                เลขประจำตัวผู้เสียภาษี 0105541011867
              </h1>
            </div>
          </div>
          <h1 for="" class="h5"></h1>
          <div class="row mt-1">
            <div class="col-12">
              <h1 for="" class="h1">
                {{ report.AgentName }} {{ report.AgentAddress }}
              </h1>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-6">
              <h1 for="" class="h1">รหัสตัวแทน :</h1>
              <h1 for="" class="detail">{{ report.AgentID }}</h1>
              <h1 for="" class="h1">วันที่ปิดยอด :</h1>
              <h1 for="" class="detail">{{ report.CloseDate }}</h1>
              <h1 for="" class="h1">วันที่กำหนดชำระเงิน :</h1>
              <h1 for="" class="detail">{{ report.PaidDate }}</h1>
            </div>
            <div class="col-6">
              <h1 for="" class="h1">รวมยอดเงินที่ต้องชำระเพิ่ม :</h1>
              <h1 for="" class="detail">{{ numberFormatToText(cast) }}</h1>
              <h1 for="" class="h1">วันที่ออกใบแจ้งยอดฯ :</h1>
              <h1 for="" class="detail">{{ report.BillDate }}</h1>
              <h1 for="" class="h1">ประจำงวดที่ :</h1>
              <h1 for="" class="detail">{{ report.PeriodDate }}</h1>
            </div>
          </div>
          <h4 for="" class="h4">
            <div class="row mt-3 mb-1">
              <div class="col-6 pl-2">
                <h2 for="" class="h2">จำนวนเงินที่ต้องชำระ</h2>
              </div>
              <div class="col-6">
                <h2 for="" class="h2" style="float: right">{{ numberFormatToText(payment) }} บาท</h2>
              </div>
            </div>
          </h4>
        </div>

        <!-- gen QR code -->
        <div v-if="type == 'QRcode'" class="text-center">
          <qr-generator-component :qr="qr" v-if="qr"></qr-generator-component>
          <div class="container">
            <div class="row mt-3">
              <div class="col-12">
                <h6 for="" class="h6">
                  สแกน QR Code และชำระเงินด้วยแอพพลิเคชั่น Mobile Banking ของท่าน
                  ธนาคารที่รองรับ คือ - ธนาคารไทยพาณิชย์ (SCB)
                </h6>
              </div>
            </div>
          </div>
        </div>
        <!-- gen BAR code -->
        <div v-if="type == 'Barcode'" class="text-center">
          <barcode-generator-component :barcode="barcode" v-if="barcode"></barcode-generator-component>
          <div class="container">
            <div class="row mt-3">
              <div class="col-12">
                <h6 for="" class="h6">
                  ช่องทางการชำระค่าเบี้ยประกันภัย
                </h6>
              </div>
            </div>
          </div>
        </div>

      </div>
    </vue3-simple-html2pdf>

    <div class="container">
      <!-- <div class="row mt-3 justify-content-end">
        <div class="col-5">
          <button class="set-bt-download" style="float: right" @click="downloadPDF">
            <img
              class="icon"
              src="../../assets/ico_download.png"
              width="20"
              alt=""
            />Download
          </button>
        </div>
      </div> -->
      <div style="padding: 1rem 1rem;" v-if="type == 'Barcode'">
        <div class="  row mt-2">
          <div class="col-3 text-center p-0">
            <img src="../../assets/scb.png" class=" img-fluid icon" width="60" />
          </div>
          <div class=" col-9">
            <label style="font-weight:bold;" class="font-size-12"> COMP CODE :</label><label
              class="font-size-12">0612</label>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-3 text-center p-0">
            <img src="../../assets/ktb.png" class="img-fluid icon" width="40" />
          </div>
          <div class=" col-9 text-horizontal-vertical">
            <label style="font-weight:bold;" class="font-size-12"> COMP CODE :</label><label class="font-size-12">6078
              (10
              บาทวงเงิน 100,000บาท)</label>
          </div>
        </div>
        <div class="  row mt-2">
          <div class="col-3 text-center p-0">
            <img src="../../assets/kasikorn.png" class="img-fluid icon" width="40" />
          </div>
          <div class=" col-9 text-horizontal-vertical">
            <label style="font-weight:bold;" class="font-size-12"> COMP CODE :</label><label class="font-size-12">33553
              (10 บาทวงเงิน 100,000บาท)</label>
          </div>
        </div>
        <div class="  row mt-2">
          <div class="col-3 text-center p-0">
            <img src="../../assets/truemoney.png" class="img-fluid icon" height="20" />
          </div>
          <div class=" col-9">
            <label class="font-size-12">(15/20 บาท วงเงิน 30,000บาท) เฉพาะเงินสดเท่านั้น</label>
          </div>
        </div>
        <div class="  row mt-2">
          <div class="col-3 text-center p-0">
            <img src="../../assets/counterservice.png" class="img-fluid icon" width="60" />
          </div>
          <div class=" col-9">
            <label class="font-size-12">(15/20 บาท วงเงิน 49,000 บาท) เฉพาะเงินสดเท่านั้น</label>
          </div>
        </div>
        <div class="  row mt-2">
          <div class="col-3 text-center p-0">
            <img src="../../assets/mpay.png" class="img-fluid icon" width="40" />
          </div>
          <div class=" col-9 text-horizontal-vertical">
            <label class="font-size-12">(15 บาท วงเงิน 49,000 บาท) เฉพาะเงินสดเท่านั้น</label>
          </div>
        </div>
      </div>
    </div>

    <loading :show="loading" />

  </section>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import EventService from "../../services/EventService";
import QrGeneratorComponent from '../../components/barcode-qrcode/QrGeneratorComponent.vue';
import BarcodeGeneratorComponent from '../../components/barcode-qrcode/BarcodeGeneratorComponent.vue';
import Loading from '../../components/alert/LoadingComponent.vue'

export default {
  name: "qrbarpaymentSacn",
  components: {QrGeneratorComponent, BarcodeGeneratorComponent, Loading},
  data() {
    return {
      report: {},
      cast: this.$route.params.cash,
      payment: this.$route.params.payment,
      type: this.$route.params.type,
      qr: "",
      barcode: "",

      pdfOptions: {
        margin: 15,
        image: {
          type: 'jpeg',
          quality: 1,
        },
        html2canvas: { scale: 3 },
        jsPDF: {
          unit: 'mm',
          format: 'a4',
          orientation: 'p',
        },
      },
      exportFilename: 'my-custom-file.pdf',
      loading: false,
    };
  },
  computed:{
    ...mapState(['agent']),
    ...mapGetters(['numberFormatToText'])
  },
  created() {
    this.getAgent().then(res => {
      console.log(res);
    })
   
    if (this.payment.replace(/,/g, '') * 1) {
      this.payment = this.payment.replace(/,/g, '');
    }
    
    // Barcode
    if (this.type == "Barcode") {
      this.loading = true
      EventService.post("/management/get_bill_payment", {
        //agent_id: "2081991403049000",
        agent_id: this.agent.agent_id,
        money: this.payment
      })
        .then((res) => {
          this.loading = false
          console.log(res.data);
          this.barcode = res.data.GenCodeText
          this.report = res.data;
          this.exportFilename = 'barcode.pdf'
        })
        .catch((error) => {
          console.log(error);
        });
      // QRcode
    } else if (this.type == "QRcode") {
      this.loading = true
      EventService.post("/management/get_qr_payment", {
        agent_id: this.agent.agent_id,
        money: this.payment
      })
        .then((res) => {
          this.loading = false
          console.log(res.data);
          this.qr = res.data.GenCode
          this.report = res.data;
          this.exportFilename = 'qr-code.pdf'
        })
        .catch((error) => {
          console.log(error);
        });
    } else if (this.type == "SCB") {
      console.log("SCB");
    }
  },
  methods: {
    ...mapActions(['getAgent']),
    // async downloadPDF() {
    //   if ((this.qr && this.type == "QRcode") || (this.barcode && this.type == "Barcode")) {
    //     this.$refs.vue3SimpleHtml2pdf.download()
    //   }
    // }
  }
};
</script>

<style lanf="scss" scoped >
.h1 {
  font-size: 8px;
  color: #5a5a5a;
  margin-bottom: 0rem;
}
.h2 {
  font-size: 15px;
  font-weight: bold;
}
.h3 {
  font-size: 15px;
  font-weight: bold;
}
.h4 {
  border-top: solid 0.5px #858585b9;
  border-bottom: solid 0.5px #858585b9;
}
.h5 {
  border-bottom: dashed 2px #858585b9;
  margin-bottom: 0.9rem;
}
.h6 {
  font-size: 12px;
  color: #e12124;
  text-align: left;
}

.set-bt-download {
  border: 1px solid #cccccc;
  border-radius: 16px 16px 16px 16px;
  font-size: 12px;
  width: 110px;
  height: 30px;
  background-color: #fff;
}
.icon {
  margin-top: 3px;
  margin-bottom: 5px;
  margin-right: 5px;
}
.detail {
  font-size: 8px;
  color: #5a5a5a;
  margin-top: 0rem;
}

.text-horizontal-vertical{
  margin-top: 5px;
}

.font-size-12{
  font-size: 10px;
}
</style>

