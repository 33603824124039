import axios from 'axios'
import { Promise } from 'core-js';

const apiClient = axios.create({
    baseURL: window.location.origin+'/api',
    // baseURL: 'https://iagent.rvp.co.th/api', // prod
    //baseURL: 'https://ts2iagent.rvp.co.th/api', // qas
    // baseURL: 'http://localhost:5000/api', //localhost
    //   withCredentials: false,
    //   headers: {
    //     Accept: 'application/json',
    //     'Content-Type': 'application/json'
    //   }
})

export default {
    get(url) {
        return new Promise((resolve, reject) => {
            apiClient.get(url).then(res => {
                resolve(res.data)
            }).catch(function (error) {
                reject(error)
                console.log(error);
            })
        })
    },
    post(url, data) {
        return new Promise((resolve, reject) => {
            apiClient.post(url, data)
                .then(res => {
                    resolve(res.data)
                })
                .catch(function (error) {
                    reject(error)
                    console.log(error);
                })
        })
    }
}