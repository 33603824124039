<template>
  <div class="container set-padding-menu">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="text-center">
            <h4 style="font-size:15px"><b>ค้นหาเพื่อออกกรมธรรม์</b></h4>
          </div>
        </div>
        <div class="row">
          <div class="text-center">
            <i
              class="fas fa-exclamation-triangle"
              style="margin-right:3px; color: #da3432"
              aria-hidden="true"
            ></i>
            <h6
              style="
                display: inline-block;
                margin-right: 20px;
                line-height: normal;
                margin: 0;
              "
            >
              กรอกข้อมูลเพื่อค้นหา เพื่อดึงกรมธรรม์เดิมมาแสดงผล
            </h6>
            <h6
              style="
                display: inline-block;
                margin-right: 20px;
                paddint-top: -10px;
              "
            >
              ลดขั้นตอนการกรอกข้อมูลใหม่
            </h6>
          </div>
        </div>
        <div class="row justify-content-center ps-2 pe-2 mb-3">
          <div class="col-12" style="border-top: 1px solid #445be6;"></div>
        </div>
        <!-- <div class="row mt-3 justify-content-center">
          <div class="col-12">
            <h6 style="font-size:15px"><b>เพิ่มรูปภาพ (เล่มทะเบียนรถ หรือ บัตรประชาชน)</b></h6>
          </div>
        </div> -->
        <div class="row-mt-3 justify-content-center">

          <thailand-car-book @ocrFile="setData"/>

          <div class="row mt-3 justify-content-center">
            <div class="col-12">
              <div class="form-group">
                <label for="">เลขตัวถัง</label>
                <input
                  type="text"
                  class="form-control"
                  placeholder="กรอกเลขตัวถัง"
                  v-model="vehicleEngineNumber"
                />
              </div>
            </div>
          </div>
          <div class="row justify-content-center mt-3">
            <div class="col-6">
              <div class="d-grid gap-2">
                <button
                  class="btn btn-block btn-primary"
                  style="background-color: #484fe5 !important"
                  @click="searchData"
                >
                  <i class="fas fa-search" aria-hidden="true"> </i>
                  ค้นหา
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-3" v-show="searchShow">
      <div
        class="card-header"
        style="background-color: #484fe5; color: #ffffff"
      >
        <h6 style="display: inline-block; margin-right: 20px">
          <b>ผลการค้นหา</b>
        </h6>
      </div>
      
      <!-- loop data -->
      <contentSearch v-for="value in searchByChassisNo" :key="value.PolicyNo" :data="value" />

      <div class="card-body" v-if="!searchByChassisNo.length">
        <div class="row">
          <div class="col-12 text-center">
            <h5>ไม่พบข้อมูล</h5>
            <h6>เลขตัวถัง : {{ vehicleEngineNumber }}</h6>
          </div>
        </div>
      </div>

    </div>

    <loading :show="loading"/>
    <requrie :show="requrie"/>

  </div>
</template>

<script>
import contentSearch from "../../../components/renew-com/content-Search.vue";
import ThailandCarBook from "../../../components/ocr/ThailandCarBookComponent.vue"
import EventService from '../../../services/EventService';
import { mapActions, mapState } from 'vuex';
import Loading from '../../../components/alert/LoadingComponent.vue'
import Requrie from '../../../components/alert/RequrieComponent.vue'

export default {
  name: "carRegistration",
  components: {
    contentSearch,
    ThailandCarBook,
    Loading,
    Requrie,
  },
  data() {
    return {
      searchByChassisNo: [],
      searchShow: false,
      vehicleEngineNumber: '',
      loading: false,
      requrie: false,
    }
  },
  watch: {
    vehicleEngineNumber() {
      if (!this.searchByChassisNo.length) {
        this.searchShow = false
      }
    }
  },
  computed: {
    ...mapState(['agent'])
  },
  created() {
    this.getAgent()
  },
  methods: {
    ...mapActions(['getAgent']),
    setData(val) {
      if (val.status) {
        this.vehicleEngineNumber = val.data.vehicle_chassis_number
      }
    },
    searchData() {
      if (this.vehicleEngineNumber) {
        this.loading = true
        this.searchShow = true
        this.searchByChassisNo = []
        
        EventService.post('/search/by_chassis_no', {
          "agent_id": this.agent.agent_id,
          "user_token_id": this.agent.token,
          "chassis_no": this.vehicleEngineNumber
          // "chassis_no": "MLHKF1289D5103682"
        }).then(res => {
          if (res.status) {
            this.searchByChassisNo = res.data
          }
          this.loading = false

        }).catch(error =>{
          console.log(error);
          this.loading = false
        })
      }else {
        this.requrie = !this.requrie
      }
      
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lanf="scss" scoped >
h6 {
  font-size: 14px;
}
.ref-title-men {
  text-align: center;
}
.img-ocr {
  height: auto;
  /* width: 300px; */
  border-left: 1px dashed #999999;
  border-top: 1px dashed #999999;
  border-right: 1px dashed #999999;
  margin: auto;
  border: dashed 1px hsla(0, 0%, 50%, 2);
  border-radius: 5px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .set-img-res{
    width: 250px;
    height: 150px;
  }
}


</style>
