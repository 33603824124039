<template>
  <div class="card mt-2">
    <div class="card-header" style="background-color: #d6eee1">
      <div class="row">
        <div class="col-10">
          <i
            class="fas fa-money-bill-alt"
            style="margin-right: 10px"
            aria-hidden="true"
          ></i>
          <h6
            style="
              display: inline-block;
              margin-right: 20px;
              font-size: 15px;
              padding-top: 10px;
            "
          >
            <b>ความคุ้มครอง และค่าเบี้ย</b>
          </h6>
        </div>
        <div class="col-2 mt-2">
          <i
            v-on:click="(show = !show), seticon(), (count = count + 1)"
            style="float: right; color: white"
            id="show-card-body-confirm-ward"
            class="fas fa-minus set-cursor"
          />
        </div>
      </div>
    </div>
    <transition name="slide-fade">
      <div v-if="show">
        <div class="card-body">
          <div class="m-0 p-0">
            <wardprocess />
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import wardprocess from "./ward-date-process.vue";

export default {
  name: "confirmwarddetail",
  data() {
    return {
      show: true,
      count: 0,
    };
  },
  components: {
    wardprocess,
  },
  methods: {
    seticon() {
      if (this.count % 2 == 0) {
        document.getElementById("show-card-body-confirm-ward").className =
          "fas fa-plus set-cursor";
      } else {
        document.getElementById("show-card-body-confirm-ward").className =
          "fas fa-minus set-cursor";
      }
      console.log(this.count);
    },
  },
};
</script>


<style lanf="scss" scoped >
.set-cursor {
  cursor: pointer;
}
.slide-fade-enter-active {
  transition: all 0.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-10px);
  opacity: 0;
}
.set-color-text-value {
  color: #484fe5;
  font-weight: bold;
}
.set-color-text-value-status {
  color: #80466e;
  font-weight: bold;
}
</style>
