<template>
  <div class="card">
    <div class="card-header" style="background-color: #bce0fd">
      <div class="row">
        <div class="col-10">
          <i
            class="fas fa-id-card"
            style="margin-right: 10px"
            aria-hidden="true"
          ></i>
          <h6
            style="
              display: inline-block;
              margin-right: 20px;
              font-size: 15px;
              padding-top: 10px;
            "
          >
            <b>รายละเอียดผู้เอาประกัน</b>
          </h6>
        </div>
        <div class="col-2 mt-2">
         <i
            v-on:click="(show = !show), seticon(), (count = count + 1)"
            style="float: right; color: white"
            id="show-card-confirm-pay"
            class="fas fa-minus set-cursor"
          />
        </div>
      </div>
    </div>
    <transition name="slide-fade">
      <div v-if="show">
        <div class="card-body card-detail-show" id="card-detail-show">
          <div class="row mb-2">
            <div class="col-4">
              <h6><b>ผู้เอาประกัน</b></h6>
            </div>
            <div class="col-8">
              <h6 class="set-color-text-value">นางสาวทดสอบ ระบบสำหรับตัวแทน</h6>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-4">
              <h6><b>ประเภทลูกค้า</b></h6>
            </div>
            <div class="col-8">
              <h6 class="set-color-text-value">บุลคลธรรมดา</h6>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-4">
              <h6><b>ประเภทบัตร</b></h6>
            </div>
            <div class="col-8">
              <h6 class="set-color-text-value">บัตรประจําตัวประชาชน</h6>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-4">
              <h6><b>เลขที่บัตร</b></h6>
            </div>
            <div class="col-8">
              <h6 class="set-color-text-value">11005763821XXX</h6>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-4">
              <h6><b>วันเดือนปีเกิด</b></h6>
            </div>
            <div class="col-8">
              <h6 class="set-color-text-value">05/04/2564</h6>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-4">
              <h6><b>เบอร์ติดต่อ</b></h6>
            </div>
            <div class="col-8">
              <h6 class="set-color-text-value">0922345678</h6>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-4">
              <h6><b>รายละเอียด</b></h6>
            </div>
            <div class="col-8">
              <h6 class="set-color-text-value">-</h6>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-4">
              <h6><b>วันที่ออกกรมธรรม์</b></h6>
            </div>
            <div class="col-8">
              <h6 class="set-color-text-value">04/02/2564</h6>
            </div>
          </div>
          <div class="row">
            <h6><b>ที่อยู่ปัจจุบัน</b></h6>
          </div>
          <div class="row mb-2">
            <h6 class="set-color-text-value">
              26 ซอยสุขุมวิท 64/2 ถนน สุขุมวิท แขวงพระโขนงใต้ เขตพระโขนง
              กรุงเทพฯ 10260
            </h6>
          </div>
          <div class="row">
            <h6><b>ที่อยู่ตามทะเบียนบ้าน</b></h6>
          </div>
          <div class="row">
            <h6 class="set-color-text-value">
              26 ซอยสุขุมวิท 64/2 ถนน สุขุมวิท แขวงพระโขนงใต้ เขตพระโขนง
              กรุงเทพฯ 10260
            </h6>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "confirmpaydetail",
  data() {
    return {
      show: true,
      count:0,
    };
  },
  methods: {
    seticon() {
      if (this.count % 2 == 0) {
        document.getElementById("show-card-confirm-pay").className =
          "fas fa-plus set-cursor";
      } else {
        document.getElementById("show-card-confirm-pay").className =
          "fas fa-minus set-cursor";
      }
      console.log(this.count);
    },
  },
};
</script>


<style lanf="scss" scoped >
.set-cursor {
  cursor: pointer;
}
.slide-fade-enter-active {
  transition: all 0.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateY(-10px);
  opacity: 0;
}
.set-color-text-value {
  color: #484fe5;
  font-weight: bold;
  font-size: 14px;
}
</style>
