<template>
  <div class="container set-padding-menu">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="text-center">
            <h4 style="font-size: 15px"><b>ค้นหาเพื่อออกกรมธรรม์</b></h4>
          </div>
        </div>
        <div class="row">
          <div class="text-center">
            <i
              class="fas fa-exclamation-triangle"
              style="margin-right: 3px; color: #da3432"
              aria-hidden="true"
            ></i>
            <h6
              style="
                display: inline-block;
                margin-right: 20px;
                line-height: normal;
                margin: 0;
              "
            >
              กรอกข้อมูลเพื่อค้นหา เพื่อดึงกรมธรรม์เดิมมาแสดงผล
            </h6>
            <h6
              style="
                display: inline-block;
                margin-right: 20px;
                paddint-top: -10px;
              "
            >
              ลดขั้นตอนการกรอกข้อมูลใหม่
            </h6>
          </div>
        </div>
        <div class="row justify-content-center mb-3">
          <div class="col-11" style="border-top: 1px solid #445be6"></div>
        </div>
        <!-- <div class="row mt-3 justify-content-center">
          <div class="col-12">
            <h6><b>เพิ่มรูปภาพ (เล่มทะเบียนรถ หรือ บัตรประชาชน)</b></h6>
          </div>
        </div> -->
        <div class="row-mt-3 justify-content-center">
          <!-- button add file -->
          <thailand-car-book @ocrFile="setData"/>
          
          <div class="row mt-3">
            <div class="col-12">
              <div class="form-group">
                <label for="">ทะเบียนรถ</label>
                <div class="row">
                  <div class="col-6">
                    <input
                      type="text"
                      class="form-control"
                      placeholder="หมวดตัวอักษร"
                      v-model="vehicle_license_text"
                      @click="onTop"
                    />
                  </div>
                  <div class="col-6">
                    <input
                      type="number"
                      class="form-control"
                      placeholder="หมวดตัวเลข"
                      pattern="[0-9]*"
                      v-model.number="vehicle_license_number"
                      @click="onTop"
                    />
                  </div>
                </div>
              </div>
            </div>

            <div class="col-12 mt-2">
              <div class="form-group">
                <label for="">จังหวัด</label>
                <select name="" id="" class="form-select" v-model="vehicle_license_province" @click="onTop">
                  <option v-for="value in province" :key="value.ProvinceId" :value="value.ChangwatName">{{ value.ChangwatName }}</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row justify-content-center mt-3">
            <div class="col-6">
              <div class="d-grid gap-2">
                <button
                  class="btn btn-block btn-primary"
                  style="background-color: #484fe5"
                  @click="searchData"
                >
                  <i class="fas fa-search" aria-hidden="true"> </i>
                  ค้นหา
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-3" v-show="searchShow">
      <div
        class="card-header"
        style="background-color: #484fe5; color: #ffffff"
      >
        <h6 style="display: inline-block; margin-right: 20px">
          <b>ผลการค้นหา</b>
        </h6>
      </div>
      
      <!-- loop data -->
      <contentSearch v-for="value in searchByPlateNo" :key="value.PolicyNo" :data="value" />

      <div class="card-body" v-if="!searchByPlateNo.length">
        <div class="row">
          <div class="col-12 text-center">
            <h5>ไม่พบข้อมูล</h5>
            <h6>ทะเบียนรถ : {{ vehicle_license_text }} {{ vehicle_license_number }}</h6>
            <h6>จังหวัด : {{ vehicle_license_province }}</h6>
          </div>
        </div>
      </div>

    </div>

    <loading :show="loading"/>
    <requrie :show="requrie"/>

  </div>
</template>

<script>
import contentSearch from "../../../components/renew-com/content-Search.vue";
import ThailandCarBook from "../../../components/ocr/ThailandCarBookComponent.vue"
import { mapActions, mapGetters, mapState } from 'vuex'
import EventService from '../../../services/EventService';
import Loading from '../../../components/alert/LoadingComponent.vue'
import Requrie from '../../../components/alert/RequrieComponent.vue'

export default {
  name: "carBodynumber",
  components: {
    contentSearch,
    ThailandCarBook,
    Loading,
    Requrie,
  },
  data() {
    return {
      vehicle_license_number: "",
      vehicle_license_text: "",
      vehicle_license_province: "",
      searchByPlateNo: [],
      searchShow: false,
      loading: false,
      requrie: false,
    };
  },
  watch: {
    vehicle_license_number() {
      if (!this.searchByPlateNo.length) {
        this.searchShow = false
      }
    },
    vehicle_license_text() {
      if (!this.searchByPlateNo.length) {
        this.searchShow = false
      }
    },
    vehicle_license_province() {
      if (!this.searchByPlateNo.length) {
        this.searchShow = false
      }
    }
  },
  computed: {
    ...mapState([
      'url',
      'province',
      'agent'
    ]),
    ...mapGetters([
      'getProvinceId'
    ])
  },
  created() {
    this.getAddress()
    this.getAgent()
  },
  methods: {
    ...mapActions(["getAddress", 'getAgent']),
    onTop() {
     window.scrollTo(0, 0);
    },
    setData(value) {
      if (value.status) {
        let array = value.data.vehicle_license_number.split(" ")
        this.vehicle_license_number = array[0]
        this.vehicle_license_text = array[1]
        this.vehicle_license_province = value.data.vehicle_license_province
      }
    },
    searchData() {
      let vehicle_license_numberText = this.vehicle_license_number+''
      if (vehicle_license_numberText.length && this.vehicle_license_text.length && this.vehicle_license_province.length) {
        this.loading = true
        this.searchByPlateNo = []
        this.searchShow = true

        EventService.post('/search/by_plate_no', {
          "agent_id": this.agent.agent_id,
          "user_token_id": this.agent.token,
          "license_a": vehicle_license_numberText,
          "license_b": this.vehicle_license_text,
          "car_changwat": this.getProvinceId(this.vehicle_license_province)
          // "license_a": "1กข",
          // "license_b": "4079",
          // "car_changwat": "14"
        }).then(res => {
          // console.log(res.data);
          if (res.status) {
            this.searchByPlateNo = res.data
          }
          this.loading = false
        }).catch(error =>{
          console.log(error);
          this.loading = false
        })
      }else {
        this.requrie = !this.requrie
      }
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lanf="scss" scoped >
h6 {
  font-size: 14px;
}
.ref-title-men {
  text-align: center;
}
.img-ocr {
  height: auto;
  /* width: 300px; */
  border-left: 1px dashed #999999;
  border-top: 1px dashed #999999;
  border-right: 1px dashed #999999;
  margin: auto;
  border: dashed 1px hsla(0, 0%, 50%, 2);
  border-radius: 5px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .set-img-res {
    width: 250px;
    height: 150px;
  }
}
</style>
