<template>
    <div class="card-body">
      <div class="row">
        <div class="col-12">
          <h6>เลขกรมธรรม์ : {{ data.PolicyNo }}</h6>
        </div>
      </div>
      <div class="row">
        <div class="col-12"><h6>ทะเบียนรถ : {{ data.CarNo }}</h6></div>
      </div>
      <div class="row">
        <div class="col-12"><h6>เลขตัวถัง : {{ data.CarTankNo }}</h6></div>
      </div>
      <div class="row">
        <div class="col-12"><h6>ยี่ห้อ : {{ data.CarBrand }}</h6></div>
      </div>
      <div class="row">
        <div class="col-12">
          <h6>ผู้เอาประกัน : {{ data.TitleName}} {{ data.FirstName }} {{ data.SurName }}</h6>
        </div>
      </div>
      <div class="row">
        <div class="col-6"><h6>วันเริ่มต้น : {{ data.StartDate }}</h6></div>
        <div class="col-6"><h6>วันสิ้นสุด : {{ data.EndDate }}</h6></div>
      </div>
      <div class="row">
        <div class="col-12" style="color: #fc0000">
          <h6>เหลือความคุ้มครอง : {{ data.ActiveDay }}</h6>
        </div>
      </div>
      <div class="row justify-content-end">
        <div class="col-5">
          <button v-if="data.ActiveDay < 0" class="set-btn-search" @click="getInsurance">ออกกรมธรรม์ </button>
          <button v-if="data.ActiveDay > 0 && data.ActiveDay < 90" class="set-btn-search" @click="getInsurance">ต่ออายุกรมธรรม์</button>
          <!-- <button v-if="data.ActiveDay > 0" class="set-btn-search" @click="getInsurance">ต่ออายุกรมธรรม์</button> -->

        </div>
      </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
// import EventService from '../../services/EventService';


export default {
  name: "contentSearch",
  components: {},
  props: ["data"],
  data() {
    return {
    };
  },
  created() {
    console.log(this.data);
  },
  methods: {
    ...mapActions(['setPolicyNo']),
   async getInsurance() {
      this.setPolicyNo(this.data.PolicyNo)
      // this.setPolicyNo("8188057268458332")
      // this.setPolicyNo("")
      this.$router.push({ name: 'detailsMember', query: {policy: this.data.PolicyNo}})
      // this.$router.push({ name: 'detailsMember', query: {policy: '8188057268458332'}})
    }
  }
};
</script>

<style lanf="scss" scoped >
h6 {
  font-size: 14px;
}

.ref-title-men {
  text-align: center;
}
.img-ocr {
  height: auto;
  /* width: 300px; */
  border-left: 1px dashed #999999;
  border-top: 1px dashed #999999;
  border-right: 1px dashed #999999;
  margin: auto;
  border: dashed 4px hsla(0, 0%, 50%, 2);
}
.set-btn-search {
  height: 35px;
  width: 120px;
  background-color: #bce0fd;
  border: 1px solid #0060ab;
  border-radius: 3px 3px 3px 3px;
  font-size: 14px;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .set-btn-search {
    float: right;
  }
}
</style>