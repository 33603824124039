<template>
  <div class="container set-padding-menu mb-4">
    <div class="card" v-show="!success">
      <div class="card-header" style="background-color: #470c9a; border-radius: 3px">
        <div class="text-center">
          <h6 style="
              display: inline-block;
              font-size: 15px;
              padding-top: 10px;
              color: #eef0ef;
            ">
            <b>แบบสำรวจความพึงพอใจสำหรับตัวแทน</b>
          </h6>
        </div>
      </div>
      <div class="card-body" v-if="code == 'OK'">
        <div class="row">
          <div class="col-12 text-center mb-2">
            <div class="title-card">
              โปรดทำเครื่องหมาย
              <input class="form-check-input" type="radio" name="exampleRadios" id="exampleRadios1" value="option1"
                checked>
              หน้าหัวข้อที่ตรงตามความคิดเห็นของท่านมากที่สุด
            </div>
          </div>
          <div class="col-12 mb-3 pb-2 border-bottom" v-for="(val, i) in satis" :key="val.Propostion">
            {{val.Propostion}}
            <div class="row" v-if="i < 3">
              <div class="col p-1 text-center" v-for="questionRange in questionRanges" :key="questionRange.id">
                <label class="form-check-label d-flex flex-column"
                  :for="'radios'+i+questionRange.id">{{questionRange.text}}</label>
                <input v-if="i==0" class="form-check-input" type="radio" v-model="no1" :id="'radios'+i+questionRange.id"
                  :value="questionRange.id">
                <input v-if="i==1" class="form-check-input" type="radio" v-model="no2" :id="'radios'+i+questionRange.id"
                  :value="questionRange.id">
                <input v-if="i==2" class="form-check-input" type="radio" v-model="no3" :id="'radios'+i+questionRange.id"
                  :value="questionRange.id">
              </div>
            </div>
            <div class="row" v-else-if="i == 3">
              <div class="col p-1 text-center" v-for="questionMeet in questionMeets" :key="questionMeet.id">
                <label class="form-check-label d-flex flex-column"
                  :for="'radiosMeet'+i+questionMeet.id">{{questionMeet.text}}</label>
                <input class="form-check-input" type="radio" :id="'radiosMeet'+i+questionMeet.id"
                  :value="questionMeet.id" v-model="no4">
              </div>
            </div>
            <div class="row" v-else>
              <div class="col">
                <textarea class="form-control" id="exampleFormControlTextarea1" rows="3" v-model="no5"></textarea>
              </div>
            </div>
          </div>
        </div>
        <div class="d-grid gap-2">
          <button class="btn btn-primary set-btn" type="" @click="confirm">บันทึกข้อมูล</button>
        </div>
      </div>
      <div class="card-body verticalcss" v-else>
        <h6 class="form-check-label text-center text-danger">{{msg}}</h6>
      </div>
    </div>

    <div class="card" v-show="success">
      <div class="row">
        <div class="text-center mt-4">
          <img src="../../assets/n20171123155516_131196.png" alt="" width="120" height="50" />
        </div>
        <div class="text-center mt-3 mb-1">
          <h6 style="font-size: 12px; color: #6b4e8c">
            <b>ขอขอบคุณท่านที่ไว้วางใจ<br />บริษัท
              กลางคุ้มครองผู้ประสบภัยจากรถจำกัด</b>
          </h6>
        </div>
        <div class="text-center mb-3">
          <h6 style="font-size: 12px">
            และขอความกรุณาท่าน
            ร่วมแสดงความเห็นอันเป็นประโยชน์<br />เพื่อนำผลที่ได้มาพัฒนาการบริการประชาชนที่ดียิ่งขึ้นต่อไป
          </h6>
        </div>
      </div>
    </div>

    <loading :show="loading" />
    <requrie :show="requrie" />
    <warning :show="warning" />
  </div>
</template>

<script>
import { mapActions,mapState } from 'vuex';
import EventService from "../../services/EventService";
import { authLine } from "../js/setDefault";
import Loading from '../../components/alert/LoadingComponent.vue'
import Requrie from '../../components/alert/RequrieComponent.vue'
import Warning from '../../components/alert/WarningComponent.vue'
export default {
  name: "servayhead",
  mounted() {
    window.scrollTo(0, 0);
  },
  components:{
    Loading,
    Requrie,
    Warning
  },
  data() {
    return {
      satis: [],
      answer: [],
      success: false,
      no1: '',
      no2: '',
      no3: '',
      no4: '',
      no5: '',
      questionRanges : [
        {
          id: 1,
          text: 'ดีมาก'
        },
        {
          id: 2,
          text: 'ดี'
        },
        {
          id: 3,
          text: 'ปานกลาง'
        },
        {
          id: 4,
          text: 'พอใช้'
        },
        {
          id: 5,
          text: 'ปรับปรุง'
        },
      ],
      questionMeets: [
        {
          id: 1,
          text: 'เคยไป'
        },
        {
          id: 0,
          text: 'ไม่เคยไป'
        }
      ],
      loading: false,
      requrie: false,
      warning: {
        show: false,
        msg: '',
        icon: 'w'
      },
      code: '',
      msg: ''
    };
  },
  created() {

     //this.showAlertwarning();
     authLine.start().then(() =>{
      if (authLine.getAgentStatus() == 1) {
        console.log(authLine.getAgentStatus());
        
      } else {
        this.$swal.fire({
                          icon: 'error',
                          text: 'กรุณาติดต่อเจ้าหน้าที่',
                          // showCloseButton: true,
                          allowOutsideClick: false,
                          customClass: {
                            container: 'p-4',
                            confirmButton: 'btn btn-primary px-5 py-1',
                          },
                          buttonsStyling: false
                        }).then((result) => {
                          if (result.isConfirmed) {
                              if (authLine.getAgentStatus() == 2) {
                                    authLine.closeWindow(); 
                              } else {
                                    authLine.changeRichMenu();
                              }
                          }
                        });
      }
    }).catch((err) =>{
      alert(err);
    });

        this.getAgent().then(() => {
              console.log(this.agent);
        });

    EventService.post("/management/satis_faction_question", {
      agent_id: this.agent.agent_id,
    })
      .then((res) => {
        console.log(res);
        this.code = res.status;
        this.msg = res.message;
        this.satis = res.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  computed: {
    ...mapState(['agent']),

  },
  methods: {
    ...mapActions(['getAgent']),
    confirm() {
      if (this.no1 && this.no2 && this.no3 && this.no5 && (this.no4 || this.no4 == 0)) {
        EventService.post("/management/satis_faction_answer", {
          agent_id: this.agent.agent_id,
          no1: this.no1,
          no2: this.no2,
          no3: this.no3,
          no4: this.no4,
          no5: this.no5,
          ip: this.agent.ip,
          line_id: authLine.getToken()
        })
        .then((res) => {
          console.log(res);
          if (res.status) {
            this.success = true
          }else {
            
            this.success = true
            this.warning = {
              show: !this.warning.show,
              msg: res.message,
              icon: 'error'
            }
          }
          // this.answer = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
      }else {
        this.requrie = !this.requrie
      }
      
    },
    showAlertwarning(){
      this.$swal.fire({
       title: 'ขออภัย !!',
       text: 'ยังไม่เปิดให้สำรวจแบบสอบถาม',
       icon: 'warning',
      showCancelButton: false,
      allowOutsideClick: false
    }).then((result) =>{
      if(result.isConfirmed){
        authLine.closeWindow();
      }
    })
    }

  }
};
</script>

<style lanf="scss" scoped >

.set-propostion {
  font-size: 12px;
}
.set-radio {
  font-size: 15px;
}
.set-font-textradio {
  font-size: 12px;
  font-weight: bold;
}
.verse1-set {
  height: auto;
  width: 100%;
  border-bottom: 1px dashed rgba(0, 0, 0, 0.25);
}
.verse1-set-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  line-height: 20px;
}
.verse1-set-grid-item {
  margin-bottom: 10px;
}
.form-check-input:checked {
  border-color: #00bb9c;
  background-color: #00bb9c;
}
.set-serse4 {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.25);
}
.set-m-b {
  margin-bottom: 10px;
}
.set-btn {
  background-color: #00bb9c;
  border-color: #00bb9c;
}
.title-card{
  font-size: 10px;
}

.verticalcss
{
  align-items: center;
}
</style>