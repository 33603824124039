<template>
  <div class="row justify-content-center">
    <div class="col-12">
      <div class="fullscreen">
        <StreamBarcodeReader @decode="onDecode" class="text-center">
        </StreamBarcodeReader>
      </div>
    </div>
  </div>
</template>
<script>
import { StreamBarcodeReader } from "vue-barcode-reader";
import liff from '@line/liff';

export default {
  name: "barcodescanner",

  components: {
    StreamBarcodeReader,
  },

  data() {
    return {
      fullscreen: true,
      showScanConfirmation: false,
      value : ''
    };
  },
    mounted(){
     liff.init({ liffId: '1656519660-7DxdLbZY' });
  },

  methods: {
    onDecode(result) {
      console.log(result);
      // alert(result);
      if (result != "") {
        this.showScanConfirmation = "true";
        if (this.value != result) {
          liff.openWindow({url: 'https://liff.line.me/1656519660-7DxdLbZY?param='+result, external: false})
          this.value=result;
        }
        
        // window.location.href='https://liff.line.me/1656519660-7DxdLbZY?param='+result;
        console.log("success");
      }
    },
  },
  
};

</script>

<style scoped>
video {
  max-width: 100%;
  max-height: 100%;
}

.fullscreen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: black;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
}

video {
  width: 100% !important;
}
</style>