<template >
  <!-- <div class="row">
    <img
      class="img-ht"
      src="../../assets/Mask_Group_4.png"
      alt=""
      height="200"
    />
  </div> -->
<div class="row">
<div class="backgroubImage" >
    <div class="row hpaddingheader">
      <div class="col-md-12">
        <div class="text-center">
          <img class="pb-5" src="../../assets/n20171123155516_131196.png" height="90" />
          <h1>
            <b style="color: #fff">เข้าสู่ระบบ</b>
          </h1>
        </div>
      </div>
    </div>
    <div class="row mt-4 justify-content-center">
      <div class="col-10">
        <div class="form-group">
          <label style="color: #fff" for=""><b>รหัสตัวแทน</b> </label>
          <input
            type="text"
            name="agent_id"
            id="agent_id"
            class="form-control set-input-shadowslogin"
            placeholder="กรอกรหัสตัวแทน"
            v-model="agentId"
            @keyup="checkNumber"
            inputmode="numeric"
            maxlength="16"
          />
        </div>
      </div>
      <div class="col-10 mt-2">
        <div class="form-group">
          <label style="color: #fff" for=""><b>รหัสผ่าน</b> </label>
          <input
            type="password"
            v-model="password"
            class="form-control set-input-shadowslogin"
            placeholder="กรอกรหัสผ่าน"
            
          />
        </div>
      </div>
    </div>

    <div class="row justify-content-center text-center mt-5 hpaddingbottom">
      <div class="col-12">
        <input type="submit" class="btn-login" @click="sent_data" value="ยืนยัน" />
      </div>
    </div>
  </div>
</div>
  <!-- <div class="row">
  <img class="img-ft" src="../../assets/Mask_Group_5.png" alt="" />
  </div> -->

  <loading :show="loading"/>
  <warning :show="warning"/>

  <!-- <cookie /> -->
  <!-- <div v-if="profilLine">{{profilLine}}</div> -->
  <!-- <div v-for="(value, name) in profilLine">
    {{ name }}: {{ value }}
  </div> -->
  <!-- <div v-for="(value, key, index) in profilLine" :key="`${ key }-${ index }`"></div> -->
  <!-- <div v-if="profilLine">{{print(profilLine)}}</div> -->
  <!-- <ul id="example-1">
  <li v-for="item in profilLine" >
    {{ item.message }}
  </li>
</ul> -->

<!-- <ul id="v-for-object" class="demo">
  <li v-for="(value,key) in profilLine" :key="key">
    <span >{{ key }} </span>
    
  </li>
</ul> -->
<!-- JSON.parse('{"name":"John", "age":30, "city":"New York"}') -->
<!-- <div v-if="Object.keys(profilLine).length !== 0">{{profilLine}} **** {{token}}</div> -->
</template>

<script>
// import cookie from "../../components/global-com/Cookie.vue";
import {authLine} from "../js/setDefault";
import {check} from '../js/checkValidate'
import { mapActions } from 'vuex';
import Loading from '../../components/alert/LoadingComponent.vue';
import Warning from '../../components/alert/WarningComponent.vue';

export default {
  name: "pagelogin",
  components: {
    Loading,
    Warning
  },
  data(){
    return {
      profilLine: authLine.start().then(() => {}),
      ip : authLine.setIP(),
      agentId: '',
      password: '',
      token: '',
      loading: false,
      warning: {
        show: false,
        msg: '',
        icon: ''
      },
    };
  },
  mounted(){
    
  },
 
  methods : {
    ...mapActions(['register']),
    sent_data() { 
      //alert(localStorage.getItem('token'));
      if (this.agentId && this.password) {
        this.loading = true
        // auth 
        this.register({
          "agentId": this.agentId,
          "password": this.password,
        }).then(res => {
          this.$swal.close()
          if (res.Code == "OK") {
            // open line menu
            authLine.start().then(() => {
              authLine.changeRichMenu();
            })

          } else {
            this.warning = {
              show: !this.warning.show,
              msg: res.Message,
              icon: 'warning'
            }
          }
        }).catch(error => {
          console.log(error);

        })

      } else {
        console.log(999);
        this.warning = {
          show: !this.warning.show,
          msg: 'กรุณาระบุข้อมูล',
          icon: 'warning'
        }
      }
      

    },
    checkNumber : function(){
      var num = check.checkNumber(document.getElementById('agent_id').value);
      
      if (num.length > 16) {
        num = num.slice(0,16);
      }

      document.getElementById('agent_id').value=num;
    }
  }
  
};
</script>

<style lanf="scss" scoped >
.btn-login {
  background-color: #571d84;
  height: 40px;
  width: 250px;
  color: white;
  border-radius: 18px 18px 18px 18px;
  /* border: 1px solid #571d84; */
}
.set-size-footer {
  padding: 56px;
  background-color: #571d84;
  background-repeat: no-repeat;
  background-size: auto;
}
.row {
  --bs-gutter-x: 0 !important;
}
* {
  /* background-color: #f4f5fb; */
  background-color: transparent;
}

.backgroubImage{
  background-image: url(../../assets/richmenu1.jpg);
  min-height: 100vh;
  background-position: right;
  background-repeat: no-repeat;
  background-size: cover;
}
.set-input-shadowslogin {
  border: 0;
  background-color: #ffffff;
  box-shadow: 0px 6px 5px rgba(13, 78, 129, 0.1);
  height: 35px;
}

  .img-ft {
    height: 100%;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  
  .img-ht {
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
  }
  .hpaddingheader {
    padding-top: 20vh;
    vertical-align: middle;
  }

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .img-ft {
    height: 100%;
    left: 0;
    bottom: 0;
    width: 100%;
  }
  .img-ht {
    height: 100%;
    left: 0;
    top: 0;
    width: 100%;
  }

  .backgroubImage{
    left: 0;
    bottom: 0;
    min-height: 100vh;
  }

  .set-size-footer {
    display: none;
  }
  .hpaddingheader {
    vertical-align: middle;
    padding-top: 250px;
  }
  .hpaddingbottom {
    padding-bottom: 150px;
  }
}
</style>