import { createStore } from "vuex";
import axios from "axios";
import moment from "moment";

export default createStore({
  state: {
    url: window.location.origin + "/api",
    // url: 'https://iagent.rvp.co.th/api', //prod
    //url: 'https://ts2iagent.rvp.co.th/api', //qas
    // url: 'http://localhost:5000/api', //localhost,
    province: [],
    district: [],
    district_2: [],
    sub_district: [],
    sub_district_2: [],
    country: [],
    person_type: [],
    customer_card: [],
    prefix: [],
    nationality: [],
    nationality_type: [],
    car_type: [],
    car_band: [],
    license_type: [],
    report_sale: [],
    formData: {},
    agent: {
      agent_id: "",
      agent_status: "",
      role: "",
      ip: "",
      token: "",
    },
    policy_no: "",
  },
  getters: {
    checkAgentId: () => (agentid) => {
      return agentid.substr(13);
    },
    numberFormatToText: () => (number) => {
      return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    numberFormatNumber: () => (number) => {
      return number.toString().replace(",", "");
    },
    doneTodos: (state) => {
      return state.todos.filter((todo) => todo.done);
    },
    searchByPolicyNo: (state) => (id) => {
      return state.searchData.find((data) => data.PolicyNo === id);
    },
    getProvinceId: (state) => (province_name) => {
      const province = state.province.find(
        (data) => data.ChangwatName === province_name
      );
      return province.ProvinceId;
    },
    getCarNo: () => (car_no) => {
      return car_no.split(" ");
    },
    dateNow: () => () => {
      // return moment().format('YYYY-MM-DD')
      let date = moment()
        .format("DD-MM-YYYY")
        .split("-");
      return date[0] + "/" + date[1] + "/" + (date[2] * 1 + 543);
    },
    checkDate: () => (date) => {
      let date_array = date.split("/");

      const parseDate = moment(
        date_array[0] + "/" + date_array[1] + "/" + (date_array[2] * 1 - 543),
        "D/MM/YYYY"
      );
      if (moment().format("YYYY-MM-DD") < parseDate.format("YYYY-MM-DD")) {
        return true;
      } else {
        return false;
      }
    },
    getDay: () => (date) => {
      return moment(date, "MM-YYYY").daysInMonth();
    },
    getMonth: () => () => {
      return [
        { id: "01", name: "มกราคม" },
        { id: "02", name: "กุมภาพันธ์" },
        { id: "03", name: "มีนาคม" },
        { id: "04", name: "เมษายน" },
        { id: "05", name: "พฤษภาคม" },
        { id: "06", name: "มิถุนายน" },
        { id: "07", name: "กรกฎาคม" },
        { id: "08", name: "สิงหาคม" },
        { id: "09", name: "กันยายน" },
        { id: "10", name: "ตุลาคม" },
        { id: "11", name: "พฤศจิกายน" },
        { id: "12", name: "ธันวาคม" },
      ];
    },
    getYear: () => () => {
      return moment().format("YYYY") * 1 + 543;
    },
    getYearOfbirth: () => () => {
      return (
        moment()
          .subtract(17, "years")
          .format("YYYY") *
          1 +
        543
      );
    },
    getDate: () => (date, valDate) => {
      const dateNow = moment();
      if (date) {
        let date_array = date.split("/");
        let year = dateNow.format("YYYY") * 1 + 543;
        // const parseDate = moment((date_array[0]*1)+'/'+date_array[1]+'/'+(date_array[2]*1-543), 'D/MM/YYYY')
        // if (dateNow < parseDate) {
        // console.log('parseDate', parseDate);
        return {
          day: date_array[0] * 1,
          month: date_array[1],
          year: date_array[2] < year ? year : date_array[2],
        };
        // }
      }
      let checkVal =
        valDate == "start_date"
          ? moment().format("YYYY") * 1
          : moment()
              .add(1, "y")
              .format("YYYY") *
              1 +
            543;
      return {
        day: moment().format("D"),
        month: moment().format("MM"),
        year: checkVal,
      };
    },
    getDateOfBirth: () => (date) => {
      const dateNow = moment();
      if (date) {
        let date_array = date.split("/");
        return {
          day: date_array[0] * 1,
          month: date_array[1],
          year: date_array[2],
        };
      }
      return {
        day: dateNow.format("D"),
        month: dateNow.format("MM"),
        year: dateNow.subtract(17, "years").format("YYYY") * 1 + 543,
      };
    },
    getCalulateDate: () => (date) => {
      let dateNow = moment();
      if (date) {
        let date_array = date.split("/");
        let paseDate = moment(
          date_array[0] + "/" + date_array[1] + "/" + (date_array[2] * 1 - 543),
          "DD/MM/YYYY"
        );
        dateNow = paseDate;
      }
      return dateNow.format("YYYY-MM-DD");
    },
    getDateYMD: () => (date) => {
      let dateNow = moment();
      if (date) {
        let date_array = date.split("/");
        dateNow = moment(
          date_array[0] + "/" + date_array[1] + "/" + (date_array[2] * 1 - 543),
          "DD/MM/YYYY"
        );
      }
      return dateNow.format("YYYY-MM-DD");
    },
    setFormatDateMMM: () => (date) => {
      let dateNow = moment(date, "D MMM YYYY");
      return (
        dateNow.format("D") +
        "/" +
        dateNow.format("MM") +
        "/" +
        (dateNow.format("YYYY") * 1 + 543)
      );
    },
    setFormatCreatePolicyDate: () => (date) => {
      let dateNow = moment();
      if (date) {
        dateNow = moment(date, "YYYY-MM-DD");
      }
      return (
        dateNow.format("D") +
        "/" +
        dateNow.format("MM") +
        "/" +
        (dateNow.format("YYYY") * 1 + 543)
      );
    },
    setFormatDateCloseDate: () => (date) => {
      let dateNow = moment();
      if (date) {
        dateNow = moment(date, "D/M/YYYY");
      }
      return (
        dateNow.format("D") +
        "/" +
        dateNow.format("M") +
        "/" +
        (dateNow.format("YYYY") * 1 + 543)
      );
    },
    findIdProvince: (state) => async (province_name) => {
      return state.province.find((data) => data.ChangwatName == province_name);
    },
    findIdDistrict: (state) => (district_name) => {
      if (state.district.length) {
        return state.district.find((data) => data.AmphurName == district_name);
      }
      return { aa: 1 };
    },
    findIdSubDistrict: (state) => (sub_district_name) => {
      return state.sub_district.find(
        (data) => data.TumbolName == sub_district_name
      );
    },
    findCarBrand: (state) => (car_brand) => {
      return state.car_band.find((data) => data.CarName === car_brand);
    },
  },
  mutations: {
    setAgent(state, data) {
      state.agent.agent_id = data.AgentID;
      state.agent.agent_status = data.AgentStatus;
      state.agent.role = data.Role;
      state.agent.ip = data.IP;
      state.agent.token = data.Token;
    },
    setPolicyNo(state, data) {
      state.policy_no = data;
    },
    // Address
    setProvinde(state, data) {
      state.province = data;
    },
    setCountry(state, data) {
      state.country = data;
    },
    setDistrict(state, data) {
      state.district = data;
      state.sub_district = [];
    },
    setDistrict2(state, data) {
      state.district_2 = data;
      state.sub_district_2 = [];
    },
    setSubDistrict(state, data) {
      state.sub_district = data;
    },
    setSubDistrict2(state, data) {
      state.sub_district_2 = data;
    },

    // personal
    setPersonType(state, data) {
      state.person_type = data;
    },
    setCustomerCard(state, data) {
      state.customer_card = data;
    },
    setPrefix(state, data) {
      state.prefix = data;
    },
    setNationality(state, data) {
      state.nationality = data;
    },
    setNationalityType(state, data) {
      state.nationality_type = data;
    },
    // vehicle
    setCarType(state, data) {
      state.car_type = data;
    },
    setCarBand(state, data) {
      state.car_band = data;
    },
    setLicenseType(state, data) {
      state.license_type = data;
    },

    // report
    setReportSale(state, data) {
      state.report_sale = data;
    },

    //set form data
    setFormData(state, data) {
      state.formData = data;
    },
  },
  actions: {
    //register
    register({ state, commit }, data) {
      return new Promise((resolve, reject) => {
        axios
          .post(state.url + "/auth", {
            agent_id: data.agentId,
            token: localStorage.getItem("token"),
            password: data.password,
            ip: localStorage.getItem("ip"),
          })
          .then(function(response) {
            let res = response.data;
            if (res.Code == "OK") {
              localStorage.setItem("agent_id", res.Data.AgentID);
              localStorage.setItem("agent_status", res.Data.AgentStatus);
              localStorage.setItem("role", res.Data.Role);
              commit("setAgent", {
                AgentID: res.Data.AgentID,
                AgentStatus: res.Data.AgentStatus,
                Role: res.Data.Role,
                IP: localStorage.getItem("ip"),
                Token: localStorage.getItem("token"),
                // Token: "Uf10b30da267c93ca69b528ac05606ea7"
              });
              resolve(res);
            } else {
              resolve(res);
            }
          })
          .catch(function(error) {
            console.log(error);
            reject(error);
          });
      });
    },

    // auth agent
    authAgent({ commit, state }) {
      return new Promise((resolve, reject) => {
        const token = localStorage.getItem("token");
        // const token = "Uf10b30da267c93ca69b528ac05606ea7"
        // const token = "U33804db90ddc5343195ac771222c089"

        axios
          .post(state.url + "/auth/agent", {
            token: token,
          })
          .then(function(response) {
            let res = response.data;
            if (res.status) {
              localStorage.setItem("agent_id", res.data.AgentID);
              localStorage.setItem("agent_status", "");
              localStorage.setItem("role", "");
              commit("setAgent", {
                AgentID: res.data.AgentID,
                AgentStatus: "",
                Role: "",
                IP: localStorage.getItem("ip"),
                Token: localStorage.getItem("token"),
                // Token: "Uf10b30da267c93ca69b528ac05606ea7"
                // Token: "U33804db90ddc5343195ac771222c089"
              });
              resolve(res);
            } else {
              resolve(res);
            }
          })
          .catch(function(error) {
            console.log(error);
            reject(error);
          });
      });
    },
    // get agent
    getAgent({ commit, dispatch }) {
      if (localStorage.getItem("agent_id")) {
        commit("setAgent", {
          AgentID: localStorage.getItem("agent_id"),
          AgentStatus: localStorage.getItem("agent_status"),
          Role: localStorage.getItem("role"),
          IP: localStorage.getItem("ip"),
          Token: localStorage.getItem("token"),
          // Token: "Uf10b30da267c93ca69b528ac05606ea7"
          // Token: "U33804db90ddc5343195ac771222c089"
        });
      } else {
        dispatch("authAgent");
      }
    },
    setPolicyNo({ commit }, policy_no) {
      localStorage.setItem("policy_no", policy_no);
      commit("setPolicyNo", policy_no);
    },
    getPolicyNo({ commit }) {
      return new Promise((resolve) => {
        const policy_no = localStorage.policy_no;
        if (policy_no) {
          commit("setPolicyNo", policy_no);
          resolve(true);
        }
      });
    },
    // address
    getAddress({ state, commit }) {
      axios
        .get(state.url + "/master_data/address")
        .then(function(response) {
          if (response.data.status) {
            let data = response.data.data;
            commit("setProvinde", data.province);
            commit("setCountry", data.country);
          }
        })
        .catch(function(error) {
          console.log(error);
        })
        .then(function() {
          // always executed
        });
    },

    // district
    getDistrict({ commit }, req) {
      return new Promise((resolve, reject) => {
        axios
          .get("/master_data/district", {
            params: {
              province_id: req.province_id,
            },
          })
          .then(function(response) {
            if (response.data.status) {
              let data = response.data.data;
              commit("setDistrict", data);
              resolve(data);
            }
          })
          .catch(function(error) {
            console.log(error);
            reject(error);
          });
      });
    },

    // district house
    getDistrict2({ commit }, req) {
      axios
        .get("/master_data/district", {
          params: {
            province_id: req.province_id,
          },
        })
        .then(function(response) {
          if (response.data.status) {
            let data = response.data.data;
            commit("setDistrict2", data);
          }
        })
        .catch(function(error) {
          console.log(error);
        })
        .then(function() {
          // always executed
        });
    },

    // sub_district
    getSubDistrict({ commit }, req) {
      return new Promise((resolve, reject) => {
        axios
          .get("/master_data/sub_district", {
            params: {
              province_id: req.province_id,
              city_id: req.city_id,
            },
          })
          .then(function(response) {
            if (response.data.status) {
              let data = response.data.data;
              commit("setSubDistrict", data);
              resolve(data);
            }
          })
          .catch(function(error) {
            console.log(error);
            reject(error);
          })
          .then(function() {
            // always executed
          });
      });
    },

    // sub_district house
    getSubDistrict2({ commit }, req) {
      axios
        .get("/master_data/sub_district", {
          params: {
            province_id: req.province_id,
            city_id: req.city_id,
          },
        })
        .then(function(response) {
          if (response.data.status) {
            let data = response.data.data;
            commit("setSubDistrict2", data);
          }
        })
        .catch(function(error) {
          console.log(error);
        })
        .then(function() {
          // always executed
        });
    },

    // personal
    getPersonal({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/master_data/personal")
          .then(function(response) {
            if (response.data.status) {
              let data = response.data.data;
              commit("setPersonType", data.person_type);
              commit("setPrefix", data.prefix);
              commit("setNationality", data.nationality);
              commit("setNationalityType", data.nationality_type);
              resolve(response.data);
            } else {
              reject();
            }
          })
          .catch(function(error) {
            console.log(error);
          });
      });
    },

    getCustomerCard({ commit, state }, req) {
      return new Promise((resolve, reject) => {
        axios
          .get(state.url + "/master_data/customer_card", {
            params: {
              nation: req.nation,
            },
          })
          .then(function(response) {
            if (response.data.status) {
              commit("setCustomerCard", response.data.data);
              resolve(response.data.data);
            }
          })
          .catch(function(error) {
            console.log(error);
            reject(error);
          });
      });
    },

    // vehicle
    getVehicle({ commit }) {
      return new Promise((resolve, reject) => {
        axios
          .get("/master_data/vehicle")
          .then(function(response) {
            if (response.data.status) {
              let data = response.data.data;
              commit("setCarType", data.car_type);
              commit("setCarBand", data.car_band);
              commit("setLicenseType", data.license_type);
              resolve(true);
            }
            reject(false);
          })
          .catch(function(error) {
            console.log(error);
          });
      });
    },

    setFormData({ commit }, form_data) {
      commit("setFormData", form_data);
    },
  },
  modules: {},
});
