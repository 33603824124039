<template>
  <header
    v-if="!$route.meta.hideNavbar"
    :class="{ 'scrolled-nav': scrolledNav }"
  >
    <nav>
      <ul v-show="!mobile" class="navigation">
        <li>
          <router-link class="link" :to="{ name: '' }">HOME</router-link>
        </li>
        <li>
          <router-link class="link" :to="{ name: '' }">STYLE DEMO</router-link>
        </li>
        <li>
          <router-link class="link" :to="{ name: '' }">FULL WIDTH</router-link>
        </li>
        <li>
          <router-link class="link" :to="{ name: '' }">DROPDOWN</router-link>
        </li>
        <li>
          <router-link class="link" :to="{ name: '' }">PORTFOLIO</router-link>
        </li>
        <li>
          <router-link class="link" :to="{ name: '' }">GALLERY</router-link>
        </li>
      </ul>
      <div class="icon">
        <img
          @click="toggleMobileNav"
          v-show="mobile"
          class=""
          :class="{ 'icon-active': mobileNav }"
          src="../../assets/ico_navbar.png"
          alt=""
          width="30"
        />
      </div>
      <transition name="mobile-nav">
        <ul v-show="mobileNav" class="dropdown-nav">
          <div class="row justify-content-end">
            <div class="col-4">
              <li style="padding-left:40px">
                <i
                  @click="toggleMobileNav"
                  v-show="mobile"
                  class="fas fa-times"
                  :class="{ 'icon-active': mobileNav }"
                  style="color: #480c9a; padding: left; font-size: 20px"
                ></i>
              </li>
            </div>
          </div>
          <li style="padding-left: 10px; padding-top: 0px">
            <img
              style="margin-left: 16px"
              src="../../assets/n20171123155516_131196.png"
              alt=""
              width="150"
            />
          </li>
          <li v-if="status_show">
            <router-link
              class="link"
              @click="resetPolicy"
              :to="{ name: 'detailsBuynew'}"
              ><i class="fas fa-cog mt-4" style="padding-right: 20px"></i
              ><b>ซื้อกรมธรรม์ใหม่</b>
            </router-link>
          </li>
          <li v-if="status_show">
            <router-link
              class="link"
              @click="mobileNav = !mobileNav"
              :to="{ name: 'renewHome' }"
              ><i class="fas fa-cog" style="padding-right: 20px"></i
              ><b>ต่ออายุกรมธรรม์</b>
            </router-link>
          </li>
          <li>
            <router-link
              style="border: 0"
              v-on:click="(show = !show), seticon(), (count = count + 1)"
              class="link"
              :to="{ name: '' }"
            >
              <i
                class="fas fa-cog"
                id="show-menunav"
                style="padding-right: 20px"
              ></i
              ><b>บริหารการขาย</b>
              <i
                class="fas fa-chevron-right"
                id="set-icon-nav"
                style="font-size: 15px; padding-left: 80px"
              ></i>
            </router-link>
          </li>
          <transition name="slide-fade">
            <div v-if="show">
              <div class="show-nav pt-0 mt-0" style="padding-left: 35px">
                <li>
                  <router-link class="link"  @click="mobileNav = !mobileNav" :to="{ name: 'reportsales' }">
                    <i
                      class="fas fa-circle"
                      style="padding-right: 10px; font-size: 8px"
                    ></i
                    ><b>รายงานยอดขาย รายตัวแทน</b>
                  </router-link>
                </li>
                <li>
                  <router-link class="link"  @click="mobileNav = !mobileNav" :to="{ name: 'detailpayment' }">
                    <i
                      class="fas fa-circle"
                      style="padding-right: 10px; font-size: 8px"
                    ></i
                    ><b>วงเงินเครดิต</b>
                  </router-link>
                </li>
                <li v-if="checkAgentId(agent.agent_id) == '000'">
                  <router-link class="link"  @click="mobileNav = !mobileNav" :to="{ name: 'reportperiod' }">
                    <i
                      class="fas fa-circle"
                      style="padding-right: 10px; font-size: 8px"
                    ></i
                    ><b>สรุปปิดยอดขาย</b>
                  </router-link>
                </li>
              </div>
            </div>
          </transition>
          <!-- <li>
            <router-link class="link" :to="{ name: '' }"
              ><i class="fas fa-cog" style="padding-right: 20px"></i
              ><b>คำขอใช้บริการ</b>
            </router-link>
          </li> -->
          <li>
            <router-link class="link" :to="{ name: 'storyList' }" @click="mobileNav = !mobileNav"
              ><i class="fas fa-cog" style="padding-right: 20px"></i
              ><b>เรื่องดีๆ กับ RVP</b>
            </router-link>
          </li>
          <!-- <li>
            <router-link class="link" :to="{ name: '' }"
              ><i class="fas fa-cog" style="padding-right: 20px"></i
              ><b>ติดต่อปัญหา ปรึกษาที่นี่</b>
            </router-link>
          </li> -->
          <li>
            <router-link class="link" :to="{ name: 'servayhead' }" @click="mobileNav = !mobileNav">
              <i class="fas fa-cog" style="padding-right: 20px"></i>
              <b>แบบสำรวจความพึงพอใจ</b>
            </router-link>
          </li>
        </ul>
      </transition>
    </nav>
  </header>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import {authLine} from '../../views/js/setDefault'
export default {
  name: "navigation",
  data() {
    return {
      scrolledNav: null,
      mobile: null,
      mobileNav: null,
      windowWidth: null,
      count: 1,
      show: null,
      status_show: true,
    };
  },
  computed: {
    ...mapState(['agent']),
    ...mapGetters(['checkAgentId'])
  },
  created() {
    window.addEventListener("resize", this.checkSceen);
    this.checkSceen();
    if (authLine.getAgentStatus() == 2) {
      this.status_show=false;
    }
    this.getAgent();
  },
  mounted() {
    window.addEventListener("scroll", this.updateScroll);
  },
  methods: {
    ...mapActions(['setPolicyNo'], ['getAgent']),
    resetPolicy() {
      this.setPolicyNo("")
      this.mobileNav = !this.mobileNav
    },
    toggleMobileNav() {
      this.mobileNav = !this.mobileNav;
    },
    updateScroll() {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 50) {
        this.scrolledNav = true;
        return;
      }
      this.scrolledNav = false;
      return;
    },
    checkSceen() {
      this.windowWidth = window.innerWidth;
      console.log(this.windowWidth);
      if (this.windowWidth <= 1920) {
        this.mobile = true;
        return;
      }
      this.mobile = false;
      this.mobileNav = false;
      return;
    },
    seticon() {
      if (this.count % 2 == 0) {
        document.getElementById("set-icon-nav").className =
          "fas fa-chevron-right";
      } else {
        document.getElementById("set-icon-nav").className =
          "fas fa-chevron-down";
      }
      console.log(this.count);
    },
  },
};
</script>

<style lanf="scss" scoped >
header {
  background-image: linear-gradient(
    to left,
    rgba(43, 165, 235, 1),
    rgba(72, 79, 229, 1)
  );
  z-index: 99;
  width: 100%;
  position: fixed;
  transition: 0.5s ease all;
  color: #fff;
}
nav {
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 12px 0;
  transition: 0.1s ease all;
  width: 90%;
  margin: 0 auto;
  @media (min-width: 1140px) {
    max-width: 1140px;
  }
}
.nav-bran {
  margin-top: 20px;
  margin-bottom: 20px;
}

.nav-bran-item:nth-child(1) {
  font-size: 30px;
}
.nav-bran-item p {
  font-size: 10px;
}

ul,
.link {
  font-weight: 500;
  color: #fff;
  list-style: none;
  text-decoration: none;
}

li {
  text-transform: uppercase;
  padding: 16px;
  /* margin-left: 16px; */
}
.link {
  font-size: 14px;
  transition: 0.5s ease all;
  padding-bottom: 4px;
  border-bottom: 1px solid transparent;
}
.link:hover {
  color: #00afea;
  border-color: #00afea;
}

.navigation {
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: end;
}

.dropdown-nav {
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100%;
  max-width: 300px;
  height: 100%;
  background-color: white;
  top: 0;
  left: 0;
  box-shadow: 5px 0 5px -2px #bebebe;
}

.dropdown-nav:li {
  margin-left: 0;
}
.dropdown-nav li .link {
  /* color: #000; */
  color: #480c9a;
}

.mobile-nav-enter-active,
.mobile-nav-leave-active {
  transition: 0.5s ease all;
}

.mobile-nav-enter-from,
.mobile-nav-leave-to {
  transform: translateX(-250px);
}

.mobile-nav-enter-to {
  transform: translateX(0);
}
.scrolled-nav {
  background-color: black;
  box-shadow: 0 4px 6px -1px #bebebe, 0 2px 4px -1px #bebebe;
}

/* .slide-fade-enter-active {
  transition: all 0.2s ease;
}
.slide-fade-leave-active {
  transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
} */
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateY(-10px);
  opacity: 0;
}
.show-nav {
  font-size: 10px;
}

.show-nav li {
  padding: 10px;
}
@media only screen and (max-width: 600px) {
  header {
    height: 50px;
  }
  .nav-bran-item:nth-child(1) {
    font-size: 16px;
  }

  .branding p {
    font-size: 12px;
  }
}
</style>

