<template>
  <div>
    <div :class="{ fullscreen: fullscreen }" ref="wrapper" >

      <div class="closeIcon">
        <i @click="close" class="far fa-times-circle"></i>
      </div>
      
      <div class="rqCamera">
        <qrcode-stream @decode="decode" >
                <div v-show="showScanConfirmation" class="scan-confirmation">
                  <div class="alert alert-success text-center" role="alert">
                    Success {{codeQr}}
                  </div>
                </div>
        </qrcode-stream>
      </div>
      

      
     
    </div>
  </div>
  <!-- <Modal @close="toggleModal" :modalActive="modalActive">
    <div class="modal-content" style="position: revert;">dsfsdfsd</div>

  </Modal>
  <button @click="toggleModal" style="display: none" :id="name" type="button">Open Modal</button> -->
</template>
<script>
import { QrcodeStream } from "vue3-qrcode-reader";
// import Modal from "../Photo/BasicComponent.vue";
// import {watch } from "vue";

export default {
  name: "qrcodescanner",

  components: {
    QrcodeStream,
    // Modal
  },


  data() {
    return {
      fullscreen: true,
      showScanConfirmation: false,
      codeQr : ''
    };
  },

  methods: {
    decode(decodeString) {
      if (decodeString != "") {
        this.showScanConfirmation = "true";
        this.codeQr = decodeString;
        const qr = decodeString.split(',')
        if (qr.length) {
          this.$router.push({ name: 'searchByPolicyNo', params: {policy: qr[0]}})
        }
      }
    },
    close(){
      //window.location.href = 'https://liff.line.me/1656705954-JYQADnnA'; //Line Channel test
      window.location.href = 'https://liff.line.me/1656965070-VDBmObzm'; //Line Channel prod
    }
  },
};
</script>

<style scoped>
.fullscreen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
  background-color: black;
  display: flex;
  flex-flow: column nowrap;
  /* justify-content: center; */
}

.rqCamera{
      width: 20rem;
      height: 18rem;
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

.closeIcon{
    color: #ffff;
    text-align: right;
    font-size: 16px;
    padding: 0.5rem;
}
</style>