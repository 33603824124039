<template>
  <div class="container set-padding-menu mb-4">
    <Barcodescanner />
  </div>
</template>

<script>
import Barcodescanner from "../../../components/renew-com/BARcodescanner.vue";

export default {
  name: "barcodescanner",
  components: { Barcodescanner: Barcodescanner },
  data() {},
};
</script>