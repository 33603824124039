<template>
  <div v-if="index < showList">
    <div class="row" style="background-color: #bce0fd; --bs-gutter-x: 0rem">
      <div class="col-8">
        <h6 for="" class="datepay">{{ reportStament.LastPayDate2 }}</h6>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h6 for="" class="amount">THB {{ reportStament.LastPayAmount }}</h6>
        <h6 for="" class="detaillastpay">
          ชำระโดย {{ reportStament.AgentID }}
        </h6>
        <h6 for="" class="detaillastpay" style="line-height: 1.6px">
          ช่องทางการชำระ {{ reportStament.LastPayDate }}
        </h6>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
export default {
  name: "reportlastpay",
  props: ["reportStament", "index", 'showList'],
  data() {
    return {};
  },
  computed: {
    ...mapState(['agent'])
  },
  created() {
    this.getAgent()
  },
  methods: {
    ...mapActions(['getAgent'])
  }
};
</script>

<style lanf="scss" scoped>
.datepay {
  font-size: 12px;
  padding-top: 5px;
  font-weight: bold;
}
.amount {
  font-size: 16px;
  padding-top: 5px;
  font-weight: bold;
}
.detaillastpay {
  font-size: 12px;
  padding-top: 3px;
}
</style>
