import liff from "@line/liff";
import axios from "axios";

var authLine = {
  //defaultUrlApi : "https://ts2iagent.rvp.co.th",
  defaultUrlApi: window.location.origin,

  setProfileLine: function() {
    return new Promise((resolve, reject) => {
      if (!liff.isLoggedIn()) {
        authLine.changeRichMenu();
        liff.login({ redirectUri: "https://liff.line.me/1656705954-0owbgBBb" });
        //liff.login({ redirectUri: "https://liff.line.me/1656965070-K2XPq4jP" }); // prod
        resolve("not install line app");
      } else {
        liff
          .getProfile()
          .then((profile) => {
            localStorage.setItem("profileLine", JSON.stringify(profile));
            localStorage.setItem("token", profile.userId);
            localStorage.setItem("os", liff.getOS());
            authLine
              .setIP()
              .then(() => {
                //resolve(res);
                axios
                  .post(authLine.defaultUrlApi + "/api/auth/agent", {
                    token: profile.userId,
                  })
                  .then((res) => {
                    if (res.data.status == true) {
                      localStorage.setItem("agent_id", res.data.data.AgentID);
                      localStorage.setItem("status_id", res.data.data.StatusID);
                      resolve(JSON.stringify(res.data));
                    } else {
                      //reject(res.data);
                      //reject("not install line app");
                      //alert(authLine.getTextLine());
                      //reject("Check API :" + authLine.getTextLine());
                      //authLine.setDefaultMenu(profile.userId);
                      //localStorage.setItem("agent_id", res.data.data.AgentID);
                      localStorage.setItem("status_id", 3);
                      resolve();
                    }
                  })
                  .catch((error) => {
                    // reject(error);
                    console.log(error);
                    reject("Check API :" + authLine.getTextLine());
                  });
              })
              .catch((err) => {
                reject(err);
              });
          })
          .catch((err) => {
            console.log("error", err);
            reject(err);
          });
      }
    });
  },
  getAgent: function() {
    return localStorage.getItem("agent_id") === undefined
      ? null
      : localStorage.getItem("agent_id");
  },
  getAgentStatus: function() {
    return localStorage.getItem("status_id") === undefined
      ? null
      : localStorage.getItem("status_id");
  },
  getProfileLine: function() {
    return localStorage.getItem("profileLine") === undefined ||
      localStorage.getItem("profileLine") === null
      ? {}
      : JSON.parse(localStorage.getItem("profileLine"));
  },
  getToken: function() {
    return localStorage.getItem("token") === undefined
      ? null
      : localStorage.getItem("token");
  },
  closeWindow: function() {
    liff.closeWindow();
  },
  getAll: function() {
    return JSON.parse({
      profileLine: authLine.getProfileLine(),
      user: [],
      token: authLine.getProfileLine().userId,
      ip: authLine.getIP(),
      // os : ''
    });
  },
  getTextLine: function() {
    // return 'user Id : '+authLine.getProfileLine().userId;
    return JSON.stringify(authLine.getProfileLine());
  },
  setIP: function() {
    return new Promise((resolve, reject) => {
      axios
        .get("https://api.ipify.org?format=json")
        .then((res) => {
          // alert(JSON.stringify(res.data));
          localStorage.setItem("ip", res.ip);
          resolve(res);
        })
        .catch((error) => {
          reject(error);
        });
    });
  },
  getIP: function() {
    return localStorage.getItem("ip") === undefined
      ? null
      : localStorage.getItem("ip");
  },
  changeRichMenu: async function() {
    await axios
      .post(authLine.defaultUrlApi + "/api/lineApi/webhook", {
        //richmenu-91ca17fa78e75c16c2e1edafe11fdfa6?richMenuId=richmenu-c53ba5654f47aae17d11589a708a7e19
        id: authLine.getProfileLine().userId,
        menu:
          // liff.isLoggedIn() == true && authLine.getAgentStatus() != 3
          //   ? "richmenu-c76a6b0c04ec639f1a0cf55620e0e815"
          //   : "richmenu-91ca17fa78e75c16c2e1edafe11fdfa6", // Line Channel prod เปิด 5 เมนู
          // liff.isLoggedIn() == true && authLine.getAgentStatus() != 3
          //   ? "richmenu-79c7e811f6f2268e01a5246910b54eac"
          //   : "richmenu-91ca17fa78e75c16c2e1edafe11fdfa6", // Line Channel prod เปิด 2 เมนู
          liff.isLoggedIn() == true && authLine.getAgentStatus() != 3
            ? "richmenu-628a189180416899f2de1c6d69b611e0"
            : "richmenu-d29e014315e8d89f2d6e83a7618b88f0", // Line Channel test
        // menu : ((liff.isLoggedIn() == true && authLine.getAgentStatus() != 3) ? "richmenu-b65e8aba659870c22553106fb76bf057" : "richmenu-57622ca4b7a766ee387eb1394ef49058")
      })
      .then((res) => {
        console.log(res);
        liff.closeWindow();
      })
      .catch((error) => {
        // console.log(error)
        //alert(error);
        if (error.response) {
          // Request made and server responded
          // console.log(error.response.data);
          // alert(error.response.data);
          // console.log(error.response.status);
          // console.log(error.response.headers);
        } else if (error.request) {
          // The request was made but no response was received
          // console.log(error.request);
          // alert(error.request);
        } else {
          // Something happened in setting up the request that triggered an Error
          // alert(error.message);
          // console.log('Error', error.message);
        }
      });
  },
  scanQr: async function() {
    // alert('111');
    // try{
    return new Promise((resolve, reject) => {
      liff
        .scanCodeV2()
        .then((result) => {
          var data = JSON.parse(JSON.stringify(result));
          if (data.value != null) {
            resolve({ status: true, result: data.value });
          }
        })
        .catch(() => {
          reject("not connect Line");
        });
    });

    // }catch{
    //     console.log('not connect Line');
    // }
  },

  setStartLineApi: function(liff_Id) {
    liff
      .init({
        liffId: liff_Id, // Use own liffId
      })
      .then(() => {
        // Start to use liff's api

        authLine.setProfileLine();
        authLine.setIP();
      })
      .catch((err) => {
        // Error happens during initialization
        // alert(err.message);
        // liff.login({ redirectUri: "https://liff.line.me/1656705954-0owbgBBb" });
        console.log(err.code, err.message);
      });
  },

  start: function() {
    return new Promise((resolve, reject) => {
      liff
        .init({
          liffId: "1656705954-0owbgBBb", // Use own liffId => Line channel test
          //liffId: "1656965070-K2XPq4jP", // => Line channel prod
        })
        .then(() => {
          // Start to use liff's api
          authLine
            .setProfileLine()
            .then((res) => {
              ///alert(JSON.stringify(res))
              // authLine.getIP();
              resolve(res);
            })
            .catch((err) => {
              reject(err);
            });
          // resolve('eeee');
        })
        .catch((err) => {
          reject(err.message);
        });
    });
  },
};

export { authLine };
