<template>
  <div class="container set-padding-menu mb-4">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="text-center">
            <h4 style="font-size: 15px"><b>ค้นหาเพื่อออกกรมธรรม์</b></h4>
          </div>
        </div>
        <div class="row">
          <div class="text-center">
            <i
              class="fas fa-exclamation-triangle"
              style="margin-right: 3px; color: #da3432"
              aria-hidden="true"
            ></i>
            <h6
              style="
                display: inline-block;
                margin-right: 20px;
                line-height: normal;
                margin: 0;
              "
            >
              กรอกข้อมูลเพื่อค้นหา เพื่อดึงกรมธรรม์เดิมมาแสดงผล
            </h6>
            <h6
              style="
                display: inline-block;
                margin-right: 20px;
                paddint-top: -10px;
              "
            >
              ลดขั้นตอนการกรอกข้อมูลใหม่
            </h6>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="col-11" style="border-top: 1px solid #445be6"></div>
        </div>
        <div class="row-mt-3 justify-content-center">
          <div class="row mt-3">
            <div class="col-12">
              <div class="form-group">
                <label for="">กรมธรรม์</label>
                <input
                  type="number"
                  class="form-control"
                  placeholder=""
                  pattern="[0-9]*"
                  v-model.number="policyNo"
                />
              </div>
            </div>
          </div>
          <div class="row justify-content-center mt-3">
            <div class="col-6">
              <div class="d-grid gap-2">
                <button
                  class="btn btn-block btn-primary"
                  style="background-color: #484fe5 !important"
                  @click="searchData"
                >
                  <i class="fas fa-search" aria-hidden="true"> </i>
                  ค้นหา
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card mt-3" v-show="searchShow">
      <div
        class="card-header"
        style="background-color: #484fe5; color: #ffffff"
      >
        <h6 style="display: inline-block; margin-right: 20px">
          <b>ผลการค้นหา</b>
        </h6>
      </div>
      
      <!-- loop data -->
      <contentSearch v-for="value in searchByPolicyNo" :key="value.PolicyNo" :data="value" />

      <div class="card-body" v-if="!searchByPolicyNo.length">
        <div class="row">
          <div class="col-12 text-center">
            <h5>ไม่พบข้อมูล</h5>
            <h6>กรรมธรรม์ : {{ policyNo }}</h6>
          </div>
        </div>
      </div>

    </div>

    <loading :show="loading"/>
    <requrie :show="requrie"/>

  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import contentSearch from "../../../components/renew-com/content-Search.vue";
import EventService from '../../../services/EventService';
import Loading from '../../../components/alert/LoadingComponent.vue'
import Requrie from '../../../components/alert/RequrieComponent.vue'

export default {
  name: "PolicyNumber",
  components: { contentSearch, Loading, Requrie },
  data() {
    return {
      searchByPolicyNo: [],
      // policyNo: '8110064200002624',
      policyNo: '',
      searchShow: false,
      loading: false,
      requrie: false,
      age: ''
    };
  },
  watch: {
    policyNo(val, oldVal) {
      if (!this.searchByPolicyNo.length) {
        this.searchShow = false
      }
      let textLength = val+'';
      if (textLength.length > 16) {
        this.policyNo = oldVal
      }
    }
  },
  computed: {
    ...mapState(['agent'])
  },
  created() {
    this.getAgent()
  },
  methods: {
    ...mapActions(['getAgent']),
    searchData() {
      let policyNoText = this.policyNo+'';
      if (policyNoText.length) {
        this.loading = true
        this.searchShow = true
        this.searchByPolicyNo = []

        EventService.post('/search/by_policy_no', {
          "agent_id": this.agent.agent_id,
          "user_token_id": this.agent.token,
          "policy_no": policyNoText
        }).then(res => {
          // console.log(res.data);
          if (res.status) {
            this.searchByPolicyNo = res.data
          }
          this.loading = false

        }).catch(error =>{
          console.log(error);
          this.loading = false
        })
      }else {
        this.requrie = !this.requrie
      }
      
    }
  },
  mounted() {
    window.scrollTo(0, 0);
  },
};
</script>

<style lanf="scss" scoped >
h6 {
  font-size: 14px;
}

.ref-title-men {
  text-align: center;
}
.img-ocr {
  height: auto;
  /* width: 300px; */
  border-left: 1px dashed #999999;
  border-top: 1px dashed #999999;
  border-right: 1px dashed #999999;
  margin: auto;
  border: dashed 4px hsla(0, 0%, 50%, 2);
}
</style>
