<template>
  <div class="app">
    <navigation />
    <router-view />
  </div>
</template>

<script>
import Navigation from "./components/global-com/Navigation.vue";

export default {
  name: "App",
  components: {
    Navigation,
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "SukhumvitSet";
  src: local("SukhumvitSet"),
    url("../src/assets/fonts/SukhumvitSet-Text.woff2") format("woff2");
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "SukhumvitSet", sans-serif;
}

h6 {
  font-size: 14px;
}
label {
  font-size: 14px;
}

.container {
  max-width: 960px;
  margin: 0 auto;
}

.set-padding-menu {
  padding-top: 80px;
}
.form-select {
  font-size: 14px;
}
::placeholder {
  font-size: 14px;
}

.form-control {
  font-size: 14px;
}

.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}
</style>
