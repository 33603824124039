<template>
  <section :style="styling" v-if="isValid" class="photo-capture">
    <!-- <video v-show="showVideo" ref="player" class="camera" autoplay playsinline />
    <canvas v-show="!showVideo" class="preview" ref="canvas" /> -->
    
    <Modal @close="ModalAction" :modalActive="modalActive">
      <div class="modal-content" style="position: revert;">
        <video v-show="showVideo" ref="player" class="camera" autoplay playsinline />
        <canvas v-show="!showVideo" class="preview" ref="canvas" />
         
        <div v-if="!hideBtns" class="center photo-capture-actions" >
          <div class="controls" v-if="showVideo">
            <div class="row text-center">
              <div class="col-4">
                <button
                  :class="'btn flex-center rotaleIcon'  + buttonsClasses"
                  @click.prevent="swith"
                
                >
                 <span class='fa-stack fa-sm'>
                      <i class='fas fa-sync fa-stack-2x iconwhite' style="color: #fff;"></i>
                      <i class='fas fa-circle fa-stack-1x fa-inverse iconwhite'></i>
                  </span>
                </button>
              </div>
              <div class="col-4">
                <button
                  :class="'btn flex-center '  + buttonsClasses"
                  @click.prevent="capture"
              
                >
                  <span class='fa-stack fa-lg'>
                      <i class='fas fa-circle fa-stack-2x iconwhite' style="color: #fff;"></i>
                      <i class='fas fa-camera fa-stack-1x fa-inverse iconback'></i>
                  </span>
                </button>
              </div>
              <div class="col-4">
                <!-- <i class="fas fa-image"></i> -->
                <button
                  :class="'btn flex-center '  + buttonsClasses"
                  @click="openSelect"
              
                >
                 <span class='fa-stack fa-lg'>
                      <i class='fas fa-image fa-stack-3x iconwhite imgIconimgIcon'></i>
                      <!-- <i class='fas fa-image  fa-stack-1x fa-inverse  iconwhite'></i> -->
                  </span>
                </button>
                <input style="display: none" id="input-file-idCard" type="file" @change="selectFile" name="file"/>
              </div>
            </div>
            
            
            
          </div>
          <div class="controls" v-else>
            <!-- <button :class="'btn '+ buttonsClasses" @click.prevent="cancel">{{cancelBtnContent}}</button>
            <button :class="'btn '+ buttonsClasses" @click.prevent="done">{{doneBtnContent}}</button> -->
            <div class="row text-center">
              <div class="col-6">
                <button :class="'btn '+ buttonsClasses" @click.prevent="cancel">
                   <span class='fa-stack fa-lg'>
                      <i class='fas fa-times fa-stack-3x iconwhite imgIconimgIcon'></i>
                  </span>

                </button>
               
              </div>
              <div class="col-6">
                <button :class="'btn '+ buttonsClasses" @click.prevent="done">
                  <span class='fa-stack fa-lg'>
                      <i class='fas fa-check fa-stack-3x iconwhite imgIconimgIcon'></i>
                </span>

                </button>
                
              </div>
            </div>
          </div>
          <!-- {{this.facingMode}} -->
      </div>
        
          
        </div>
    </Modal>
    <!-- sdf {{name}} -->
    <button @click="ModalAction" style="display: none" :id="name" type="button">Open Modal</button>
  </section>
</template>

<script>
import Modal from "./BasicComponent.vue";
import { ref } from "vue";

import {b64toBlob} from '../../views/js/b64toBlob'
export default {
  name: "PhotoCapture",
  props: {
    hideBtns:{
      type:Boolean,
      isRequired: false,
      default:false
    },
    styling: {
      type: Object,
      isRequired: false
    },
    value: {
      default: null
    },
    hideButtons: {
      type: Boolean,
      default: false
    },
    buttonsClasses: {
      type: String,
      default: ""
    },
    captureBtnContent: {
      default: "Capture"
    },
    cancelBtnContent: {
      default: "Cancel"
    },
    doneBtnContent: {
      default: "OK"
    },
    swithBtnContent: {
      default: "Swith"
    },
    openModal : {
      type: Boolean,
      default: false
    },
    name : {
      type : String
    }
  },
  data() {
    return {
      showVideo: true,
      picture: null,
      isValid: true,
      fileImg : null,
      facingMode : "environment" ,

    };
  },
  mounted() {
    this.videoPlayer = this.$refs.player;
    this.canvasElement = this.$refs.canvas;
    // this.streamUserMediaVideo();
    // console.log(modalActive,'props');
    // alert(this.isValid);
  
  },
  components: {
    Modal,
  },
  setup() {
  //  console.log(this.props.openModal,'openModal');
  // alert(props.hideBtns);
    const modalActive = ref();
    const toggleModal = () => {
      modalActive.value = !modalActive.value;
    };
  
    return { modalActive, toggleModal };
  },

  created(){
    // alert(this.modalActive);
    if (this.modalActive == undefined) {
      this.showVideo=false;
    }
  },

  methods: {

    streamUserMediaVideo() {
      if (!navigator.mediaDevices || !navigator.mediaDevices.getUserMedia) {
        return;
      }
      navigator.mediaDevices
        .getUserMedia({ video: { facingMode: this.facingMode } })
        .then(stream => (this.videoPlayer.srcObject = stream))
        .catch(() => {
          this.isValid = false;
        });

      //  this.facingMode = (this.facingMode != "user") ? "user" : "environment";
    },
    capture() {
      this.showVideo = false;
      this.canvasElement.width = this.videoPlayer.videoWidth;
      this.canvasElement.height = this.videoPlayer.videoHeight;
      // console.log(this.videoPlayer,this.videoPlayer.videoWidth,this.videoPlayer.videoHeight);

      this.$refs.canvas.height  = this.videoPlayer.videoHeight;
      this.$refs.canvas.width  = this.videoPlayer.videoWidth;
      this.$refs.canvas.style.width  = ((this.videoPlayer.videoWidth*65)/100)+'px';
      this.$refs.canvas.style.height = ((this.videoPlayer.videoHeight*65)/100)+'px';

      // alert("H : "+this.videoPlayer.videoHeight+" W : "+this.videoPlayer.videoWidth);

      var context = this.canvasElement.getContext("2d");
      // console.log(this.canvasElement.getContext("2d"));
      if (this.facingMode == 'user') {
        context.translate(this.canvasElement.width, 0);
        context.scale(-1, 1);
      }
      

      context.drawImage(this.$refs.player, 0, 0);
      // console.log(context,this.$refs.player);
      this.stopVideoStream();
      this.picture = this.$refs.canvas.toDataURL();

      // b64toBlob
      var ImageURL = this.picture;
      // Split the base64 string in data and contentType
      var block = ImageURL.split(";");
      // Get the content type of the image
      var contentType = block[0].split(":")[1];// In this case "image/gif"
      // get the real base64 content of the file
      var realData = block[1].split(",")[1];// In this case "R0lGODlhPQBEAPeoAJosM...."

      // Convert it to a blob to upload
      this.fileImg = b64toBlob(realData, contentType);
      // alert(realData);
      // alert(contentType);
      // console.log(this.fileImg);
      // console.log('blob',blob);
      // this.fileImg = blob;
    },
    done() {
      this.$emit("input", this.fileImg);
      // this.$emit("openModal", false);
      this.showVideo = true;
      this.streamUserMediaVideo();
      this.toggleModal();
    },
    cancel() {
      this.showVideo = true;
      this.streamUserMediaVideo();
    },
    stopVideoStream() {
      if (!(this.$refs.player && this.$refs.player.srcObject)) return;
      this.$refs.player.srcObject.getVideoTracks().forEach(track => {
        track.stop();
      });
    },
    swith(){
      if (this.facingMode != "user") {
        this.facingMode='user';
      } else {
        this.facingMode='environment';
      }
             this.capture();
             this.cancel();
      
    },
    openSelect() {
        document.getElementById('input-file-idCard').click()
    },

    async selectFile(element){
      this.fileImg=element.target.files[0];
      this.done();
  
    },
    ModalAction(){
      // alert(this.modalActive);
      this.toggleModal();
      this.cancel();
    }
    
  }
};
</script>
<style >
  .iconwhite{
    color : #fff
  }
  .iconback{
    color : #000000
  }
  .modal-content{
      background-color: #000000;
  }

  .rotaleIcon{
        padding-top: 1rem;
  }

  .imgIcon{
    font-size: 2rem;
    padding-top: 0.5rem;
  }

  .preview{
    /* height: auto;
    width: auto; */
    /* height: 20rem; */
  }

  
</style>


