<template>
  <div class="container set-padding-menu">
    <div class="card">
      <div class="card-body mt-3">
        <h1 class="h1" for="">ชำระเงินค่าเบี้ย</h1>
        <div class="row mt-3">
          <div class="col-8">
            <label for="" class="h1">เบี้ยนำส่งรวมสุทธิ</label><span class="text-bath ps-2">(บาท)</span>
          </div>
          <div class="col-4">
            <div class="setright">
              <p>{{ this.numberFormatToText(cashRou) }}</p>
            </div>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-12">
            <div class="bt-grid">
              <div class="bt-grid-item">
                <button :class="classObject" @click="isActive = true">จำนวนเต็ม</button>
              </div>
              <div class="bt-grid-item">
                <button :class="classObject1" @click="isActive = !isActive">ระบุจำนวน</button>
              </div>
            </div>
          </div>
        </div>
        <div class="input-group mt-2 mb-2">
          <input type="text" class="form-control set-text-payment-list" inputmode="decimal" v-model="payment"
            :readonly="isActive" @click="inputFocus" @blur="inputBlur" />
          <span class="input-group-text "><strong>บาท</strong></span>
        </div>
      </div>
    </div>
    <section class="mt-5">
      <div class="row ps-3">
        <div class="col-12" style="border-left: 3px solid rgb(255, 178, 70)">
          <h6 class="set-text-payment-list">เลือกช่องทางการชำระเงิน</h6>
        </div>
      </div>
      <paymentlist v-for="value in listpayment" :key="value" :Rlistpayment="value" :cash="cashRou"
        :payment="paymentNumber" />

      <loading :show="loading" />

    </section>
  </div>
</template>

<script>
import EventService from "../../services/EventService";
import paymentlist from "../../components/SalesManagement/paymentlist.vue";
import { mapActions, mapGetters, mapState } from 'vuex';
import Loading from '../../components/alert/LoadingComponent.vue'

export default {
  name: "testPayment",
  components: {
    paymentlist,
    Loading
  },
  data() {
    return {
      listpayment: [],
      cashRou: this.$route.params.totalcash,
      payment: this.$route.params.totalcash,
      paymentNumber: this.$route.params.totalcash,
      show: false,
      isActive: true,
      loading: false,
    };
  },
  watch: {
    // payment(val) {
    //   if (val) {
    //     console.log(val);
    //     if (val * 1 >= this.cashRou * 1) {
    //       this.payment = this.cashRou
    //     }
    //   } else {
    //     this.payment = 1;
    //   }
    // },
    isActive(val) {
      if (val) {
        this.payment =  this.numberFormatToText(this.$route.params.totalcash)
      }
    },
    
  },
  computed: {
    ...mapState(['agent']),
    ...mapGetters(['numberFormatToText', 'numberFormatToNumber']),
    classObject() {
      return {
        'button1': this.isActive,
        'button2': !this.isActive,
      }
    },
    classObject1() {
      return {
        'button1': !this.isActive,
        'button2': this.isActive,
      }
    }
  },
  created() {
    this.payment = this.numberFormatToText(this.payment)
    this.getAgent();
    this.loading = true
    EventService.post("/management/get_payment_channel_list", {
      // agent_id: "2081991403049000",
      agent_id: this.agent.agent_id,
    })
      .then((res) => {
        this.loading = false
        console.log(res.data);
        this.listpayment = res.data;
      })
      .catch((error) => {
        console.log(error);
      });
  },
  methods: {
    ...mapActions(['getAgent']),
    inputBlur() {
      console.log(this.payment * 1);
      if (this.payment*1) {
        this.paymentNumber = this.payment
        this.payment = this.payment.replace(/\B(?=(\d{3})+(?!\d))/g, ",")

      }else {
        this.payment = this.numberFormatToText(this.$route.params.totalcash)
        this.paymentNumber = this.numberFormatToText(this.$route.params.totalcash)
      }
    },
    inputFocus() {
      if (this.payment.replace(/,/g, '') * 1) {
        this.payment = this.payment.replace(/,/g, '')
        
      } else {
        this.payment = this.numberFormatToText(this.$route.params.totalcash)
      }
    }
  }

};
</script>

<style lanf="scss" scoped >
.h1 {
  font-size: 16px;
  text-align: center;
  font-weight: bold;
}
.h2 {
  font-size: 14px;
  font-weight: bold;
}
.setright {
  float: right;
  color: #484fe5;
  font-weight: bold;
}
.h4 {
  font-size: 16px;
  font-weight: bold;
}
.bt-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
}
.button1 {
  font-size: 16px;
  width: 100%;
  background-color: #095391;
  height: 40px;
  font-weight: bold;
  color: #e8eff4;
  border: 0;
}
.button2 {
  font-size: 16px;
  width: 100%;
  background-color: #e8eff4;
  height: 40px;
  font-weight: bold;
  color: #095391;
  border: 0;
}
.set-text-payment-list {
  font-size: 16px;
  font-weight: bold;
}
.text-bath {
  font-size: 12px;
  font-weight: bold;
}
</style>
